import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import { SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";
import PuzzleBase, { PuzzleStatePayload } from "./PuzzleBase";
import PopupBackground from "../../ui/PopupBackground";

export default class UnlockScreen extends PuzzleBase<PuzzleStatePayload> {
  protected incrementProgress: boolean = false;
  private readonly textObject: Phaser.GameObjects.Text;
  private readonly assetImage: Phaser.GameObjects.Image;

  constructor(
    scene: UIScene,
    imageType: string,
    text: string,
    sourceObject: BaseRoomObject,
    actionId: string,
    callback: () => void = () => {}
  ) {
    super(scene, sourceObject, actionId, callback, "");

    this.closeButton.setVisible(false);

    this.scene.hud.setHintButtonVisible(false);

    this.assetImage = new Phaser.GameObjects.Image(
      scene,
      (SCREEN_CENTER_X * 5) / 8,
      SCREEN_CENTER_Y,
      imageType
    ).setOrigin(0.5);

    this.add(this.assetImage);

    this.textObject = new Phaser.GameObjects.Text(
      scene,
      this.assetImage.getTopCenter().x!,
      this.assetImage.getTopCenter().y! - 20,
      text,
      {
        fontFamily: "Arial",
        fontSize: "36px",
        color: "#ffffff",
        align: "center",
      }
    )
      .setLineSpacing(8)
      .setOrigin(0.5, 1);

    this.add(this.textObject);

    const animationKey = "unlockAnimation";

    this.scene.game.anims.create({
      key: animationKey,
      frames: this.scene.game.anims.generateFrameNumbers("unlock"),
      frameRate: 6,
      repeat: 0,
    });

    const unlockSprite = new Phaser.GameObjects.Sprite(
      scene,
      (SCREEN_CENTER_X * 11) / 8,
      SCREEN_CENTER_Y,
      "unlock"
    );
    this.add(unlockSprite);

    const timerEvent = this.scene.time.delayedCall(300, () => {
      unlockSprite.anims.play(animationKey);
    });

    unlockSprite.on(
      Phaser.Animations.Events.ANIMATION_COMPLETE,
      (animation: Phaser.Animations.Animation) => {
        if (animation.key === animationKey) {
          this.completePuzzle();
        }
      }
    );

    this.timerEvents.push(timerEvent);

    this.scene.add.existing(this);
  }

  protected initializeBackground(): void {
    const popupBackground = new PopupBackground(this.scene, 1);
    this.add(popupBackground);
  }

  protected updatePuzzle(currentState: PuzzleStatePayload): void {}

  protected isPuzzleStatePayloadValid(payload): boolean {
    return false;
  }

  protected removeEventHandlers(): void {
    this.scene.game.anims.remove("unlockAnimation");
  }
}
