import GameScene from "@/phaser/scenes/GameScene";
import EventBridge from "@/utils/EventBridge";
import GameRoomObject from "../RoomObject/GameRoomObject";
import { TextModalPayload } from "../modals/ModalPayloads";

export class DisplayMessageTooltipGameActionHandler {
    private gameScene: GameScene;
    private message: string;
    private sourceObject: GameRoomObject;
    
    constructor(gameScene: GameScene, sourceObject: GameRoomObject, message: string) {
        this.gameScene = gameScene;
        this.sourceObject = sourceObject;
        this.message = message;
    }

    public execute(): void {
        const textTooltipPayload = {
            text: this.message,
            description: "",
            sourceObject: this.sourceObject,
            callback: () => {},
        } satisfies TextModalPayload;
        
        EventBridge.emit("ui.openMessageTooltip", textTooltipPayload);

        this.gameScene.game.socketEmit("handleMessage", {
            sessionId: this.gameScene.game.session._id,
            type: "openMessageTooltip",
            sourcePlayerId: this.gameScene.user.id,
            sourceObjectRef: this.sourceObject.ref,
            message: this.message,
        });
    }
}