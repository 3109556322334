import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import Clock from "../controls/Clock";
import { SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";
import PuzzleBase, { PuzzleStatePayload } from "./PuzzleBase";

const KEY_EXPECTED_HOUR = "hour";

interface ClocksGamePayload extends PuzzleStatePayload {
  london: [number, number];
  paris: [number, number];
  newYork: [number, number];
}

export default class Clocks extends PuzzleBase<ClocksGamePayload> {
  private static readonly HINT_MESSAGE: string =
    "Can you use the notes you'll find across the rooms to set the correct time?";

  private readonly clocks: Clock[] = [];

  private readonly clockLondon: Clock;
  private readonly clockParis: Clock;
  private readonly clockNewYork: Clock;

  constructor(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    actionId: string,
    callback: () => void = () => {}
  ) {
    super(scene, sourceObject, actionId, callback, Clocks.HINT_MESSAGE);

    const clocksPadding = 400;

    this.clockLondon = new Clock(
      scene,
      SCREEN_CENTER_X - clocksPadding,
      SCREEN_CENTER_Y,
      "LONDON",
      sourceObject,
      this.onClockTimeChanged
    );
    this.clockLondon.setData(KEY_EXPECTED_HOUR, 1);
    this.add(this.clockLondon);

    this.clocks.push(this.clockLondon);

    this.clockParis = new Clock(
      scene,
      SCREEN_CENTER_X,
      SCREEN_CENTER_Y,
      "PARIS",
      sourceObject,
      this.onClockTimeChanged
    );
    this.clockParis.setData(KEY_EXPECTED_HOUR, 4);
    this.add(this.clockParis);
    this.clocks.push(this.clockParis);

    this.clockNewYork = new Clock(
      scene,
      SCREEN_CENTER_X + clocksPadding,
      SCREEN_CENTER_Y,
      "NEW YORK",
      sourceObject,
      this.onClockTimeChanged
    );
    this.clockNewYork.setData(KEY_EXPECTED_HOUR, 2);
    this.add(this.clockNewYork);
    this.clocks.push(this.clockNewYork);

    this.scene.add.existing(this);
  }

  protected isPuzzleStatePayloadValid(payload): boolean {
    if (typeof payload !== "object" || payload === null) {
      return false;
    }

    if (
      !("london" in payload) ||
      !("paris" in payload) ||
      !("newYork" in payload)
    ) {
      return false;
    }

    if (
      !Array.isArray(payload.london) ||
      !Array.isArray(payload.paris) ||
      !Array.isArray(payload.newYork)
    ) {
      return false;
    }

    if (
      payload.london.length !== 2 ||
      payload.paris.length !== 2 ||
      payload.newYork.length !== 2
    ) {
      return false;
    }

    return true;
  }

  protected removeEventHandlers(): void {
  }

  protected updatePuzzle(payload: ClocksGamePayload): void {
    this.clockLondon.setSelectedTime(payload.london[0], payload.london[1]);
    this.clockParis.setSelectedTime(payload.paris[0], payload.paris[1]);
    this.clockNewYork.setSelectedTime(payload.newYork[0], payload.newYork[1]);

    this.checkIfComplete();
  }

  private onClockTimeChanged = () => {
    const payload: ClocksGamePayload = {
      london: [
        this.clockLondon.getSelectedHour(),
        this.clockLondon.getSelectedMinute(),
      ],
      paris: [
        this.clockParis.getSelectedHour(),
        this.clockParis.getSelectedMinute(),
      ],
      newYork: [
        this.clockNewYork.getSelectedHour(),
        this.clockNewYork.getSelectedMinute(),
      ],
    };

    this.emitPuzzleStateChanged(payload);
  };

  private checkIfComplete = () => {
    let isDone = true;

    this.clocks.forEach((clock: Clock) => {
      const selectedHour = clock.getSelectedHour();
      const selectedMinute = clock.getSelectedMinute();
      const expectedHour = clock.getData(KEY_EXPECTED_HOUR);

      if (selectedMinute !== 0 || selectedHour !== expectedHour) {
        isDone = false;
        return;
      }
    });

    if (isDone) {
      this.clocks.forEach((clock: Clock) => {
        clock.markAsDone();
      });

      this.completePuzzle();
    }
  };
}
