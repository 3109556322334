import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../../RoomObject/BaseRoomObject";
import { Colors } from "@/constants";

enum QuestionnaireColors {
  CorrectAnswerBackground = 0x20522a,
  CorrectAnswerBorder = 0x4eb523,
  IncorrectAnswerBackground = 0x822720,
  IncorrectAnswerBorder = 0xe0301e,
  HoverBackground = 0x191919,
}

export default class AnswerChoice extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly onAnswerSelectedCallback: (answerNumber: number) => void;
  private readonly answerBackground: Phaser.GameObjects.Graphics;
  private readonly answerText: Phaser.GameObjects.Text;
  private readonly answerImage: Phaser.GameObjects.Image;

  private readonly isCorrect: boolean;
  private readonly answerIndex: number;

  private readonly ANSWER_PADDING_X = 19;
  private readonly ANSWER_PADDING_Y = 19;
  private readonly ANSWER_RADIUS = 4;
  private readonly BORDER_WIDTH = 2;
  private readonly ICON_X;

  private isSelected: boolean = false;

  constructor(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    text: string,
    width: number,
    answerIndex: number,
    isCorrect: boolean,
    onAnswerSelectedCallback: (answerNumber: number) => void
  ) {
    super(scene, 0, 0);

    this.scene = scene;
    this.onAnswerSelectedCallback = onAnswerSelectedCallback;
    this.sourceObject = sourceObject;
    this.isCorrect = isCorrect;
    this.answerIndex = answerIndex;
    this.width = width;

    this.answerText = new Phaser.GameObjects.Text(
      this.scene,
      this.ANSWER_PADDING_X,
      this.ANSWER_PADDING_Y,
      text,
      {
        fontFamily: "Arial",
        fontSize: "16px",
        color: "#ffffff",
        align: "left",
      }
    );

    this.answerText.setLineSpacing(10).setWordWrapWidth(400).setOrigin(0);

    this.height = this.answerText.height + 2 * this.ANSWER_PADDING_Y + 2;

    this.answerBackground = new Phaser.GameObjects.Graphics(this.scene);
    this.changeStyle(Colors.White, Colors.Black);
    this.answerBackground.setInteractive(
      new Phaser.Geom.Rectangle(0, 0, this.width, this.height),
      Phaser.Geom.Rectangle.Contains
    );

    this.add(this.answerBackground);
    this.add(this.answerText);

    this.ICON_X = this.width - 18 - 12;

    const imageTextureName = this.isCorrect
      ? "choice-correct"
      : "choice-incorrect";
    this.answerImage = new Phaser.GameObjects.Image(
      this.scene,
      this.x + this.ICON_X,
      29,
      imageTextureName
    );
    this.answerImage.setVisible(false);
    this.add(this.answerImage);

    this.answerBackground.on(Phaser.Input.Events.POINTER_OVER, () => {
      this.changeStyle(Colors.White, QuestionnaireColors.HoverBackground);
    });

    this.answerBackground.on(Phaser.Input.Events.POINTER_OUT, () => {
      this.changeStyle(Colors.White, Colors.Black);
    });

    this.answerBackground.on(Phaser.Input.Events.POINTER_DOWN, this.onSelect);
  }

  onSelect = () => {
    if (this.isCorrect) {
      this.changeStyle(
        QuestionnaireColors.CorrectAnswerBorder,
        QuestionnaireColors.CorrectAnswerBackground
      );
    } else {
      this.changeStyle(
        QuestionnaireColors.IncorrectAnswerBorder,
        QuestionnaireColors.IncorrectAnswerBackground
      );
    }

    this.answerImage.visible = true;
    this.isSelected = true;
    this.onAnswerSelectedCallback(this.answerIndex);
  };

  disableInteractive(): this {
    this.answerBackground.disableInteractive();
    this.answerText.disableInteractive();
    return this;
  }

  getTextBottomY(): number {
    return this.answerText.getBottomCenter().y!;
  }

  setDisplaySize(width: number, height: number): this {
    this.width = width;
    this.height = height;

    this.changeStyle(Colors.White, Colors.Black);

    return super.setDisplaySize(width, height);
  }

  private changeStyle(lineColor: number, backgroundColor: number) {
    if (!this.isSelected) {
      this.answerBackground.clear();
      this.answerBackground.lineStyle(this.BORDER_WIDTH, lineColor);
      this.answerBackground.fillStyle(backgroundColor, 1);
      this.answerBackground.fillRoundedRect(
        0,
        0,
        this.width,
        this.height,
        this.ANSWER_RADIUS
      );
      this.answerBackground.strokeRoundedRect(
        0,
        0,
        this.width,
        this.height,
        this.ANSWER_RADIUS
      );
    }
  }
}
