import {
  Avatar,
  Button,
  Column,
  Modal,
  Notification,
  Pagination,
  PaginationRef,
  Search,
  Select,
  Table,
  toaster,
  Tooltip,
} from "@appkit4/react-components";
import styles from "./SessionManager.module.scss";
import { IGame } from "escape-rooms-types/types/game";
import { IGameSession } from "escape-rooms-types/types/gameSession";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  createGameSession,
  deleteGameSession,
  getGameSessions,
  updateGameSession,
} from "@/api/session";
import { getGames } from "@/api/game";
import { FormattedMessage, useIntl } from 'react-intl'

const SessionManager = () => {
  const paginationRef = useRef<PaginationRef>(null);
  const navigate = useNavigate();
  const intl = useIntl();
  const [sessions, setSessions] = useState<IGameSession[]>([]);
  const [games, setGames] = useState<Array<IGame>>([]);
  const [gameId, setGameId] = useState<string>();

  const [filteredSessions, setFilteredSessions] = useState<IGameSession[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [gameFilter, setGameFilter] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [sortBy, setSortBy] = useState<string>("_id");

  const [client, setClient] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");

  const [totalPages, setTotalPages] = useState<number>(1);
  
  const [showAddSessionModal, setShowAddSessionModal] =
  useState<boolean>(false);
  
  const [showDeleteGameSessionModal, setShowDeleteGameSessionModal] = useState<boolean>(false);
  const [sessionIdToDelete, setSessionIdToDelete] = useState<string | null>(null);

  const resetFilters = useCallback(() => {
    setSearchString("");
    setStatus("");
  }, []);

  const getGameSessionsQuery = useQuery({
    queryKey: ["getGameSessions", searchString],
    queryFn: () =>
      getGameSessions(page, limit, sortBy, sortOrder, searchString, status),
    onSuccess: (res) => {
      setSessions(res.data.data);
      setFilteredSessions(res.data.data);
      updateFilteredSessions();

      const totalPages = Math.max(Math.ceil(res.data.count / limit), 1);
      setTotalPages(totalPages);
      setPage(Math.min(page, totalPages));
      if (paginationRef.current) {
        paginationRef.current.setPageNumber(Math.min(page, totalPages));
      }
    },
  });

  // Games filter needs to be added to the query
  const getGamesQuery = useQuery({
    queryKey: ["getGames"],
    queryFn: () => getGames(page, limit, sortBy, sortOrder, searchString, status, client, industry),
    onSuccess: (res) => {
      setGames(res.data.data);
    },
  });

  const updateFilteredSessions = () => {
    if (gameFilter !== "") {
      setFilteredSessions(
        filteredSessions.filter((session) => {
          return session.gameId === gameFilter;
        })
      )
    }
  }

  const openGameSession = (session: IGameSession) => {
    return navigate(`/sessions/${session._id}`);
  };

  const deleteGameSessionQuery = useQuery({
    queryKey: ["deleteGameSessions"],
    queryFn: () => deleteGameSession(sessionIdToDelete!),
    onSuccess: (res) => {
      getGameSessionsQuery.refetch();
    },
    enabled: false,
  });

  const handleDeleteGameSession = () => {
    if (!sessionIdToDelete) {
      return;
    }

    setTimeout(() => {
      deleteGameSessionQuery.refetch();
    }, 500);
    setShowDeleteGameSessionModal(false);

    toaster.notify(
      <Notification
        title="Session deleted"
        />,
        {
          duration: 3000
    });
  };

  useEffect(() => {
    getGamesQuery.refetch();
    getGameSessionsQuery.refetch();
  }, [page, limit, sortOrder, sortBy, searchString, status, gameFilter]);

  useEffect(() => {
    setFilteredSessions(sessions);
  }, [gameFilter]);

  const addSession = () => {
    // createGameSessionQuery.refetch();
    // setGameId(undefined);
    return navigate(`/sessions/create`);
  };

  const gamesFilterData = useMemo(() => {
    const arr = [{
      value: "",
      label: "All"
    }];
    games.map((game) => (arr.push({ value: game._id, label: game.name })));

    return arr;
  }, [games]);

  const renderDate = (row: any, field: string) => {
    if (!row[field]) return "";

    const date = new Date(row[field]);
    return (
      <div className="ap-font-weight-2">
        {date.toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      </div>
    );
  };

  const renderClient = (row: any, field: string) => {
    return "PWC";
  };

  const renderAction = (row: any, field: string) => {
    return (
      <>
        {row.status === 'pending' ? (
          <div className="flex gap-1">
            <Tooltip content="Edit" position="top">
              <Button
                className={`Appkit4-icon icon-edit-outline ${styles.editButton}`}
                kind=""
                onClick={() => {
                  openGameSession(row);
                }}
              />
            </Tooltip>
            <Tooltip content="Delete" position="top">
              <Button
                className="Appkit4-icon icon-delete-outline ap-p-spacing-4"
                kind=""
                onClick={() => {
                  setSessionIdToDelete(row._id);
                  setShowDeleteGameSessionModal(true);
                }}
              />
            </Tooltip>
          </div>
        ) : (
          <Tooltip content="View" position="top">
            <Button
              className="Appkit4-icon icon-view-outline"
              kind=""
              onClick={() => {
                openGameSession(row);
              }}
            />
          </Tooltip>
        )}
      </>
    );
  };

  const renderGameName = (row: any, field: string) => {
    return row.game.name;
  };

  const renderStatus = (row: any, field: string) => {
    if (row.status === "pending") {
      return (
        <div className="flex align-center gap-1">
          <div className="circle ap-bg-color-background-warning"></div>
          <div>Not Started</div>
        </div>
      );
    }
    if (row.status === "started") {
      return (
        <div className="flex align-center gap-1">
          <div className="circle bg-blue"></div>
          <div>Started</div>
        </div>
      );
    }
    if (row.status === "finished") {
      return (
        <div className="flex align-center gap-1">
          <div className="circle ap-bg-color-background-success"></div>
          <div>Completed</div>
        </div>
      );
    }
  };

  const renderName = (row: any, field: string) => {
    if (row.name == "") {
      return "N/A"
    }
    return row.name;
  };

  const renderCreator = (row: any, field: string) => {
    const creator = row["creator"].trim() || "N/A";
    const label =
      creator === "N/A"
        ? "NA"
        : creator
            .split(" ")
            .map((word: string) => word[0])
            .join("")
            .toUpperCase();
    return (
      <div className="flex gap-1 align-center">
        <Avatar className={styles.avatarText} size={25} label={label} />
        <div>{creator}</div>
      </div>
    );
  };

  const renderPlannedDateTimeFormatted = (row: any) => {
    if (row.plannedDateTimeFormatted == "") {
      return "N/A";
    } else if (row.plannedDateTimeFormatted == row.plannedDate) {
      return new Date(row.plannedDate).toLocaleDateString();
    } else if (row.plannedDateTimeFormatted == row.plannedTime) {
      return new Date(`2050-01-01 ${row.plannedTime}`).toLocaleTimeString() + ` ${row.timeZone}`;
    }
    return new Date(`${row.plannedDateTimeFormatted}:00`).toLocaleString() + ` ${row.timeZone}`;
  };

  const onSort = (sortKey: string, sortingPhase: number) => {
    setSortBy(sortKey);

    if (sortingPhase == 0) {
      setSortOrder(1);
      return;
    }
    setSortOrder(sortingPhase === 1 ? 1 : -1);
  };

  const renderNumberOfPlayers = (row: any, field: string) => {
    return row.numberOfPlayers;
  }

  return (
    <div className="ap-container ap-mt-spacing-7">
      <div className="row">
        <div className={`${styles.allSessionsHeader} col-12 flex align-center`}>
          <div className="flex gap-1 align-center ap-my-spacing-3">
            <div
              className="Appkit4-icon icon-calendar-outline ap-font-24 ap-my-spacing-4"
            ></div>
            <h2 className={styles.sessionsHeading}>
              Sessions list
            </h2>
          </div>
          <Button className={styles.addSessionBtn} onClick={() => addSession()} icon="icon-plus-outline">
            New session
          </Button>
        </div>
      </div>
      <div className="row">
        <div className={`${styles.filterContainer} col-12 flex gap-1 align-center ap-my-spacing-5`}>
          <Search
            placeholder="Search by name or creator"
            className={styles.searchInput}
            searchValue={searchString}
            onChange={(e) => {
              setSearchString(e);
            }}
          />
          <div className="flex gap-1">
            <Select
              data={gamesFilterData}
              value={gameFilter}
              onSelect={(e) => setGameFilter(e)}
              placeholder="Game"
            />
            <Select
              data={[
                { value: "", label: "All" },
                { value: "pending", label: "Upcoming" },
                { value: "started", label: "Active" },
                { value: "finished", label: "Completed" },
              ]}
              value={status}
              onSelect={(e) => setStatus(e)}
              placeholder="Status"
            />
          </div>
          {/* <Button kind="secondary" className="ml-auto" onClick={resetFilters}>
            Reset filters
          </Button> */}
        </div>
        <div className="col-12">
          <Table
            originalData={filteredSessions}
            hasTitle
            className="data-table"
            onSort={onSort}
          >
            <Column field="name" sortKey="name" renderCell={renderName}>
              Session name
            </Column>
            <Column field="_id" sortKey="game.name" renderCell={renderGameName}>
              Game
            </Column>
            <Column field="status" sortKey="status" renderCell={renderStatus}>
              Status
            </Column>
            <Column field="creator" sortKey="creator" renderCell={renderCreator}>
              Creator
            </Column>
            <Column field="numberOfPlayers" sortKey="numberOfPlayers" renderCell={renderNumberOfPlayers}>
              Players
            </Column>
            <Column field="plannedDateTimeFormatted" sortKey="plannedDateTimeFormatted" renderCell={renderPlannedDateTimeFormatted}>
              Planned date
            </Column>
            <Column field="_id" renderCell={renderAction}>
              Actions
            </Column>
          </Table>
        </div>

        <div className="col-12 flex justify-center ap-my-spacing-6">
          <Pagination
            ref={paginationRef}
            current={page}
            onPageChange={(page) => {
              setPage(page);
            }}
            total={totalPages}
          />
        </div>
      </div>
      <Modal
        visible={showAddSessionModal}
        title={"Add a new session?"}
        ariaLabel={"Add a new session?"}
        onCancel={() => {
          setShowAddSessionModal(false);
          setGameId(undefined);
        }}
        modalStyle={{ width: "33.75rem", overflow: "visible" }}
        bodyStyle={{ overflow: "visible" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowAddSessionModal(false);
                setGameId(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setShowAddSessionModal(false);
                addSession();
              }}
            >
              Create session
            </Button>
          </div>
        }
      >
        <div className="ap-py-spacing-8">
          <Select
            data={games.map((game) => ({ value: game._id, label: game.name }))}
            placeholder="Game"
            value={gameId}
            onSelect={(e) => setGameId(e)}
            noResultFound="No published games found."
          />
        </div>
      </Modal>
      <Modal
        visible={showDeleteGameSessionModal}
        title={intl.formatMessage({id: "session.delete.modal.title"})}
        ariaLabel={intl.formatMessage({id: "session.delete.modal.title"})}
        onCancel={() => {
          setSessionIdToDelete(null);
          setShowDeleteGameSessionModal(false);
        }}
        modalStyle={{width: "33.75rem"}}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => handleDeleteGameSession()}
            >
              <FormattedMessage id="session.action.delete.modal.confirm"/>
            </Button>
            <Button onClick={() => {
              setSessionIdToDelete(null);
              setShowDeleteGameSessionModal(false)
            }}>
              <FormattedMessage id="session.action.delete.modal.cancel"/>
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="session.delete.modal.description"/>
        </p>
      </Modal>
    </div>
  );
};

export default SessionManager;
