import { getGameSession } from "@/api/session";
import { avatarColors } from "@/constants";
import {
  IGameSession,
  IPlayer,
  ICursor,
  IGame,
  IRoomObject,
  IActiveQuestionnaire,
  IRoom,
} from "escape-rooms-types/types/game";
import Player from "./Player";
import { createRandomString } from "@/utils/phaser-helpers";
import GameScene from "@/phaser/scenes/GameScene";
import UIScene from "@/phaser/scenes/UIScene";
import EventBridge from "@/utils/EventBridge";

interface Props extends IGameSession {
  gameScene: GameScene;
}

export default class Session {
  gameScene: GameScene;
  uiScene?: UIScene;
  id: string;
  name?: string;
  rawPlayerObjects?: IPlayer[];
  players: Player[];
  pickedUpItems?: string[];
  teamCursors?: ICursor[];
  teamScore?: number;
  teamName: string;
  activeRoomId?: string;
  gameId?: string;
  game?: IGame;
  inventory?: IRoomObject[];
  lockedObjects?: { [key: string]: { playerId: string; roomObjectId: string } };
  timer?: number;
  activeQuestionnaire?: IActiveQuestionnaire;
  status?: string;
  gameStarted?: Date;
  gameCompleted?: Date;
  description?: string;
  callLink?: string;
  myPlayer: Player;
  currentNavigatorPlayerId: string;
  completedQuestionnaires?: string[];

  constructor(gameScene: GameScene, gameSessionData: any) {
    this.id = gameSessionData._id;
    this.gameScene = gameScene;
    this.players = [];
    this.init(gameSessionData);
  }

  init({
    name,
    players,
    pickedUpItems,
    teamCursors,
    teamScore,
    teamName,
    activeRoomId,
    gameId,
    game,
    inventory,
    lockedObjects,
    timer,
    gameStarted,
    gameCompleted,
    activeQuestionnaire,
    status,
    description,
    callLink,
    currentNavigatorPlayerId
  }: IGameSession) {
    const myPlayerIndex = players.findIndex(
      (player:IPlayer) => player.playerId == this.gameScene.user.id
    );
    const myPlayer = players[myPlayerIndex];
    if (myPlayer) {
      this.myPlayer = new Player({
        id: myPlayer.playerId,
        score: myPlayer.score,
        clientId: myPlayer.clientId,
        firstName: myPlayer.firstName,
        lastName: myPlayer.lastName,
        color: avatarColors[myPlayerIndex],
        inactive: false,
      });
    }

    this.name = name;
    this.rawPlayerObjects = players;
    this.pickedUpItems = pickedUpItems;
    this.teamCursors = teamCursors;
    this.teamScore = teamScore;
    this.teamName = teamName;
    this.activeRoomId = activeRoomId;
    this.gameId = gameId;
    this.game = game;
    this.inventory = inventory;
    this.lockedObjects = lockedObjects;
    this.timer = timer;
    this.activeQuestionnaire = activeQuestionnaire;
    this.status = status;
    this.gameStarted = gameStarted;
    this.gameCompleted = gameCompleted;
    this.description = description;
    this.callLink = callLink;    
    this.players = [];
    this.loadPlayers(players);
    this.currentNavigatorPlayerId = currentNavigatorPlayerId;
  }

  loadPlayers(playerJSONArray: IPlayer[]) {
    playerJSONArray.forEach((player: IPlayer, index: number) => {
      if (player.playerId === this.gameScene.user.id) {
        this.gameScene.clientId = player.clientId;
        this.myPlayer.clientId = player.clientId;
        this.myPlayer.score = player.playerScore;
      }
      this.addNewPlayer(
        player.playerId,
        player.clientId,
        player.playerScore,
        player.firstName,
        player.lastName,
        avatarColors[index],
        player.inactive,
      );
    });
  }

  // // TODO: make this work
  // refreshPlayers(playerJSONArray: any) {
  //   this.players = [];
  //   this.loadPlayers(playerJSONArray);
  // }

  addUiScene(uiScene: UIScene) {
    this.uiScene = uiScene;
    this.refreshScore();
  }

  refreshMyPlayer(player: IPlayer) {
    this.myPlayer.clientId = player.clientId;
    this.myPlayer.score = player.playerScore;
  }

  refreshScore() {
    const teamScore = this.teamScore;
    EventBridge.emit("ui.setScore", teamScore);
  }

  refetchSession = async () => {
    getGameSession(this.id).then((res) => {
      const { players, teamScore, teamName, activeQuestionnaire, activeRoomId, currentNavigatorPlayerId, gameStarted } =
        res.data.data;
      this.activeRoomId = activeRoomId;
      this.refreshPlayers(players);
      this.teamScore = teamScore;
      this.teamName = teamName;
      this.refreshScore();
      this.activeQuestionnaire = activeQuestionnaire;
      this.currentNavigatorPlayerId = currentNavigatorPlayerId;
      this.gameStarted = gameStarted;
      EventBridge.emit("session.refetched");
    });
  };

  refreshPlayers(players: IPlayer[]) {

    this.players.forEach((player) => {
      const newPlayerObject = players.find(
        (newPlayer) => newPlayer.playerId === player.id
      );

      if (newPlayerObject) {
        player.score = newPlayerObject.playerScore;
      }
    });

    const oldPlayerIds = this.players.map((player) => player.id);
    const newPlayerIds = players.map((player) => {
      if (player.playerId === this.myPlayer.id) {
        this.refreshMyPlayer(player);
      }
      return player.playerId;
    });

    const playersToRemove = oldPlayerIds.filter(
      (player) => !newPlayerIds.includes(player)
    );

    const playersToAdd = newPlayerIds.filter(
      (player) => !oldPlayerIds.includes(player)
    );

    if (playersToRemove.length > 0) {
      playersToRemove.forEach((playerId) => this.removePlayer(playerId));
    }

    if (playersToAdd.length > 0) {
      playersToAdd.forEach((playerId) => {
        const player = players.find((player) => player.playerId === playerId);
        if (player == null) {
          return console.error(`Player with id ${playerId} not found`);
        }

        this.addNewPlayer(
          player.playerId,
          player.clientId,
          player.playerScore,
          player.firstName,
          player.lastName
        );
      });
    }

    // this.uiScene?.hud?.refreshPlayerAvatars(this.uiScene.hud);
  }

  getPlayerFromId(playerId: string): Player | undefined {
    const player = this.players.find((player) => player.id === playerId);
    
    if (player == null) {
      console.error(`Player with id ${playerId} not found`);
      return undefined;
    }
    
    return player;
  }

  getPlayerFromClientId(clientId: string): Player | undefined {
    const player = this.players.find((player) => player.clientId === clientId);
    if (player == null) {
      return undefined;
    }
    return player;
  }

  addNewPlayer(
    id: string,
    clientId: string,
    score: number,
    firstName: string,
    lastName: string,
    color: number,
    inactive: boolean,
  ) {
    if (this.players.map((player) => player.id).includes(id)) {
      return;
    }

    const newPlayer = new Player({
      id,
      score,
      clientId,
      firstName,
      lastName,
      color,
      inactive,
    });

    this.players.push(newPlayer);
  }

  removePlayer(playerId: string) {
    this.players = this.players.filter((player) => player.id !== playerId);
  }

  markPlayerInactive(playerId: string) {
    const targetPlayer = this.players.find((player) => player.id == playerId);
    targetPlayer?.setInactive(true)
    // this.uiScene?.hud?.refreshPlayerAvatars(this.uiScene.hud);
  }

  markPlayerActive(playerId: string) {
    const targetPlayer = this.players.find((player) => player.id == playerId);
    targetPlayer?.setInactive(false)
    // this.uiScene?.hud?.refreshPlayerAvatars(this.uiScene.hud);
  }
}
