import { HEADER_HEIGHT } from "@/constants";
import GameScene from "@/phaser/scenes/GameScene";
import UIScene from "@/phaser/scenes/UIScene";
import EventBridge from "@/utils/EventBridge";

export default class Clock extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly minutesText: Phaser.GameObjects.Text;
  private readonly secondsText: Phaser.GameObjects.Text;
  private readonly countdownImage: Phaser.GameObjects.Image;
  private started: boolean = false;
  private currentTimerEvent: Phaser.Time.TimerEvent;

  constructor(scene: UIScene) {
    super(scene);

    this.scene = scene;

    this.countdownImage = new Phaser.GameObjects.Image(
      scene,
      -5,
      HEADER_HEIGHT / 2 - 8,
      "countdown"
    )
      .setOrigin(0, 0.5)
      .setInteractive();
    this.add(this.countdownImage);

    const letterSpacing = 9;

    this.minutesText = new Phaser.GameObjects.Text(this.scene, 0, 0, "", {
      font: "500 32px Arial",
      color: "#ffffff",
      align: "center",
    }).setLetterSpacing(letterSpacing);
    this.add(this.minutesText);

    this.secondsText = new Phaser.GameObjects.Text(this.scene, 72, 0, "", {
      font: "500 32px Arial",
      color: "#ffffff",
      align: "center",
    }).setLetterSpacing(letterSpacing);
    this.add(this.secondsText);
  }

  private formatTime(seconds: number): [string, string] {
    const minutes = Math.floor(seconds / 60);
    const partInSeconds = seconds % 60;

    return [
      this.getFormattedTimePart(minutes),
      this.getFormattedTimePart(partInSeconds),
    ];
  }

  private getFormattedTimePart(value: number): string {
    return value.toString().padStart(2, "0");
  }

  public start(): void {
    if (this.started) {
      return;
    }

    this.started = true;
    this.currentTimerEvent = this.scene.time.addEvent({
      delay: 1000,
      callback: this.onEvent,
      callbackScope: this,
      loop: true,
    });
  }

  public stop(): void {
    if (this.currentTimerEvent) {
      this.scene.time.removeEvent(this.currentTimerEvent);
    }

    this.started = false;
  }

  private onEvent() {
    if (!this.started) {
      return;
    }

    let timeLeft = 30 * 60;
    let timeLeftInSeconds = 0;
    let timeOverInSeconds = 0;

    if (this.scene.sourceScene instanceof GameScene) {
      const gameScene = this.scene.sourceScene as GameScene;
      const now = new Date();

      const gameStartedAt = new Date(gameScene.session!.gameStarted!);

      if (gameStartedAt) {
        const diff = now.getTime() - gameStartedAt.getTime();
        timeLeft = timeLeft - Math.floor(diff / 1000);

        if (timeLeft < 0) {
          timeLeftInSeconds = 0;
          timeOverInSeconds = Math.abs(timeLeft);
          this.scene.makeGameSceneUninteractive();
          this.stop();
          EventBridge.emit("game.timeOut", gameScene.session?.activeRoomId);
        } else {
          timeLeftInSeconds = timeLeft;
          timeOverInSeconds = 0;
        }
      }
    }

    let displayedTime;

    if (timeLeftInSeconds == 0) {
      const color = "#c52a1a";
      this.minutesText.setColor(color);
      this.secondsText.setColor(color);
      this.countdownImage.setTexture("countdown-over");
    }

    if (timeLeftInSeconds > 0) {
      displayedTime = timeLeftInSeconds;
    } else {
      displayedTime = timeOverInSeconds;
    }

    this.updateDisplayedTime(displayedTime);
  }

  private updateDisplayedTime(timeInSeconds: number) {
    const [minutes, seconds] = this.formatTime(timeInSeconds);

    this.minutesText.setText(minutes);
    this.secondsText.setText(seconds);
  }
}
