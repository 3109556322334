export abstract class Status {
  constructor() {}

  abstract getHint(): string;
  abstract getMessage(): string;
  abstract keyFound(): Status;
  abstract keyUsed(): Status;
  abstract codeApproved(): Status;
}

export class StatusCodeNotApprovedKeyNotFound extends Status {
  getHint(): string {
    return "Find the key fob or the code";
  }

  getMessage(): string {
    return "Input code and tap fob";
  }

  keyFound(): Status {
    return new StatusCodeNotApprovedKeyFoundNotUsed();
  }

  keyUsed(): Status {
    return new StatusCodeNotApprovedKeyUsed();
  }

  codeApproved(): Status {
    return new StatusCodeApprovedKeyNotFound();
  }
}

export class StatusCodeNotApprovedKeyFoundNotUsed extends Status {
  getHint(): string {
    return "Find the code or use the key fob you found";
  }

  getMessage(): string {
    return "Input code and tap fob";
  }

  keyFound(): Status {
    return this;
  }

  keyUsed(): Status {
    return new StatusCodeNotApprovedKeyUsed();
  }

  codeApproved(): Status {
    return new StatusCodeApprovedKeyFoundNotUsed();
  }
}

export class StatusCodeNotApprovedKeyUsed extends Status {
  getHint(): string {
    return "Find the code";
  }

  getMessage(): string {
    return "Now input code to open";
  }

  keyFound(): Status {
    return this;
  }

  keyUsed(): Status {
    return this;
  }

  codeApproved(): Status {
    return new StatusCodeApprovedKeyUsed();
  }
}

export class StatusCodeApprovedKeyNotFound extends Status {
  getHint(): string {
    return "Find the key fob";
  }

  getMessage(): string {
    return "Now use fob to open";
  }

  keyFound(): Status {
    return new StatusCodeApprovedKeyFoundNotUsed();
  }

  keyUsed(): Status {
    return this;
  }

  codeApproved(): Status {
    return this;
  }
}

export class StatusCodeApprovedKeyFoundNotUsed extends Status {
  getHint(): string {
    return "Use the key fob you found";
  }

  getMessage(): string {
    return "Now use fob to open ";
  }

  keyFound(): Status {
    return this;
  }

  keyUsed(): Status {
    return new StatusCodeApprovedKeyUsed();
  }

  codeApproved(): Status {
    return this;
  }
}

export class StatusCodeApprovedKeyUsed extends Status {
  getHint(): string {
    return "";
  }

  getMessage(): string {
    return "Vault unlocked!";
  }

  keyFound(): Status {
    return this;
  }

  keyUsed(): Status {
    return this;
  }

  codeApproved(): Status {
    return this;
  }
}
