import React, { useEffect, useRef, useState } from "react";
import styles from "./GameMission.module.scss";
import { Button, Notification, toaster } from "@appkit4/react-components";
import { useGameStore } from "@/store/game-store";
import { useModal } from '../game-customisation-template-pages/UnsavedChangesContext';
import { useMutation } from "react-query";
import { updateGame } from "@/api/game";
import { renderNotification } from "../notification/renderFunctions";

const GameMission = () => {
  const modalContext = useModal();
  const gameMission = useGameStore((state) => state.game.gameMission);
  const game = useGameStore((state) => state.game);
  const gameMissionReplacementStrings = useGameStore(
    (state) => state.game.gameMissionReplacementStrings
  );
  const [gameSaved, setGameSaved] = useState(true);
  const [inputs, setInputs] = useState<string[]>(
    gameMissionReplacementStrings || []
  );

  const initialInputsRef = useRef(inputs);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    setInputs(gameMissionReplacementStrings || []);
    initialInputsRef.current = gameMissionReplacementStrings || [];
  }, [gameMissionReplacementStrings]);

  useEffect(() => {
    if (inputRefs.current) {
      inputRefs.current.forEach((ref, index) => {
        ref.size =
          inputs[index] && inputs[index].length > 1 ? inputs[index].length : 1;
      });
    }
  }, [inputs]);

  const handleInputChangeWrapper = (e, index: number) => {
    const newInputs = [...inputs];
    newInputs[index] = e.target ? e.target.value : "";
    setInputs(newInputs);
    console.log(JSON.stringify(initialInputsRef.current))
    console.log(JSON.stringify(inputs))
    initialInputsRef.current = newInputs;
    modalContext?.setIsChanged(true);
  };

  useEffect(() => {
    if (
      JSON.stringify(initialInputsRef.current) === JSON.stringify(gameMissionReplacementStrings)
    ) {
      modalContext?.setIsChanged(false);
    } else {
      modalContext?.setIsChanged(true);
    }
  }, [initialInputsRef.current, gameMissionReplacementStrings]);

  const renderMission = (mission: string, inputValues: string[]) => {
    console.log(mission)
    console.log(inputValues)
    return mission.split(/(\{\d+\})/g).map((part, idx) => {
      const match = part.match(/\{(\d+)\}/);
      console.log(match)
      if (match) {
        const inputIndex = parseInt(match[1], 10);
        return (
          <input
            key={idx}
            className={styles.missionTextInput}
            value={inputValues[inputIndex] || ""}
            onChange={(e) => handleInputChangeWrapper(e, inputIndex)}
            ref={(el) => {
              if (el) {
                inputRefs.current[inputIndex] = el;
              }
            }}
          />
        );
      }
      return part.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < part.split("\n").length - 1 && <br />}
        </React.Fragment>
      ));
    });
  };

  const getFinalisedText = (mission: string) => {
    return mission.replace(/\{(\d+)\}/g, (_, index) => inputs[index] || "");
  };

  const saveGameMutation = useMutation({
    mutationKey: ["saveGame", game._id],
    mutationFn: () => updateGame(game._id, game),
    onSuccess: (res) => {
      useGameStore.getState().setGame(res.data.data);
      renderNotification("Your Game mission has been saved into the game", "success");
    },
    onError: (err) => {
      renderNotification(err.response.data.message, "error");
    },
  });

  const handleSave = () => {
    const updatedGame = JSON.parse(JSON.stringify(game));
    updatedGame.gameMissionReplacementStrings = inputs;
    useGameStore.getState().setGame(updatedGame);
    setGameSaved(false);
  }

  useEffect(() => {
    if (!gameSaved) {
      saveGameMutation.mutate();
      setGameSaved(true);
    }
  }, [gameSaved]);

  const renderTextWithLineBreaks = (text: string) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <h1 className="ap-font-weight-2">Game mission</h1>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.saveButton}
              kind="primary"
              onClick={handleSave}
              disabled={
                JSON.stringify(gameMissionReplacementStrings) === JSON.stringify(initialInputsRef.current)
              }
            >
              Save changes
            </Button>
          </div>
        </div>
        <p className={`${styles.introText} ap-typography-body`}>
          In this section, we present the mission of the game to get the players
          excited. Feel free to edit the details to make the mission more
          tailored to you!
        </p>
      </div>
      <div className={styles.divider} />
      <div className={styles.container}>
        <h3 className="ap-typography-body ap-font-weight-2">
          Customise the game mission
        </h3>
        <p className="ap-typography-body ap-mb-spacing-6">
          You can amend the highlighted text with the copy of your choice
        </p>
        <div className={styles.missionTextContainer}>
          <p className="ap-typography-body ap-font-weight-2">Game mission:</p>
          <p className="ap-typography-body">
            {renderMission(gameMission || "", initialInputsRef.current)}
          </p>
        </div>
      </div>
      <div className={styles.divider}></div>
      <h3 className="ap-typography-body ap-font-weight-2">Preview the page</h3>
      <p className="ap-typography-body">
        Preview reflects the last saved version.
        <br />
      </p>
      <div className={styles.previewContainer}>
        <h1 className="ap-font-weight-2">Your mission:</h1>
        <p className="ap-typography-body">{renderTextWithLineBreaks(getFinalisedText(gameMission))}</p>
        <button className={styles.continueButton}>Start game</button>
      </div>
    </>
  );
};

export default GameMission;