import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import PuzzleBase, { PuzzleStatePayload } from "./PuzzleBase";
import TVScreen from "../../ui/TVScreen";

interface JigsawGamePayload extends PuzzleStatePayload {
  cursorOverImageName: string | undefined;
  frameNames: string[] | undefined;
  swappedFrames: [string, string] | undefined;
}

export default class Jigsaw extends PuzzleBase<JigsawGamePayload> {
  private static readonly HINT_MESSAGE: string =
    "Could it be the dark blue numbers?";

  private readonly cards: Array<Phaser.GameObjects.Plane> = [];
  private readonly images: Array<Phaser.GameObjects.Image> = [];
  private readonly textObject: Phaser.GameObjects.Text;
  private readonly initialFrameNames: Array<string> = [];
  private readonly arrowUp: Phaser.GameObjects.Sprite;
  private readonly arrowDown: Phaser.GameObjects.Sprite;
  private readonly arrowRight: Phaser.GameObjects.Sprite;
  private readonly arrowLeft: Phaser.GameObjects.Sprite;
  private readonly arrows: Phaser.GameObjects.Sprite[] = [];
  private frameNames: Array<string> = [];

  private framesOrderUpdated: boolean = false;

  private readonly gridConfiguration = {
    x: 0,
    y: 0,
    paddingX: 0,
    paddingY: 0,
    cardWidth: 159,
    cardHeight: 200,
    numberOfItems: 8,
  };

  constructor(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    actionId: string,
    callback: () => void = () => {}
  ) {
    super(scene, sourceObject, actionId, callback, Jigsaw.HINT_MESSAGE);

    this.textObject = this.createTextObject(scene);
    this.add(this.textObject);

    this.gridConfiguration.x =
      this.scene.scale.width / 2 - this.gridConfiguration.cardWidth * 1.5;
    this.gridConfiguration.y =
      this.scene.scale.height / 2 - this.gridConfiguration.cardHeight / 2;
    this.initialFrameNames = scene.textures
      .get("jigsawAtlas")
      .getFrameNames()
      .sort();
    this.frameNames = this.getFramesInRandomOrder([...this.initialFrameNames]);

    this.arrowDown = this.createArrow(scene, 0, "down");
    this.arrowUp = this.createArrow(scene, 180, "up");
    this.arrowRight = this.createArrow(scene, -90, "right");
    this.arrowLeft = this.createArrow(scene, 90, "left");

    this.arrows.push(
      this.arrowDown,
      this.arrowUp,
      this.arrowRight,
      this.arrowLeft
    );

    this.initializeImages();
    this.initializeArrows();

    this.scene.add.existing(this);
  }

  private createTextObject(scene: UIScene): Phaser.GameObjects.Text {
    return new Phaser.GameObjects.Text(
      scene,
      scene.scale.width / 2,
      150,
      "What's the code?",
      {
        fontFamily: "Arial",
        fontSize: "26px",
        color: "#ffffff",
        align: "center",
      }
    )
      .setLineSpacing(8)
      .setOrigin(0.5);
  }

  private createArrow(
    scene: UIScene,
    rotation: number,
    direction: string
  ): Phaser.GameObjects.Sprite {
    return new Phaser.GameObjects.Sprite(scene, 0, 0, "arrow")
      .setRotation(Phaser.Math.DegToRad(rotation))
      .setData("direction", direction);
  }

  protected initializeBackground(): void {
    const tvScreen = new TVScreen(this.scene);
    this.add(tvScreen);
  }

  protected removeEventHandlers(): void {
    this.cards.forEach((card) => card.destroy());
  }

  protected isPuzzleStatePayloadValid(payload): boolean {
    if (payload === null || typeof payload !== "object") {
      return false;
    }

    const { cursorOverImageName, frameNames, swappedFrames } = payload;

    if (
      cursorOverImageName !== undefined &&
      typeof cursorOverImageName !== "string"
    ) {
      return false;
    }

    if (frameNames !== undefined && !Array.isArray(frameNames)) {
      return false;
    }

    if (
      swappedFrames !== undefined &&
      (!Array.isArray(swappedFrames) || swappedFrames.length !== 2)
    ) {
      return false;
    }

    return true;
  }

  protected updatePuzzle(currentState: JigsawGamePayload): void {
    if (!this.framesOrderUpdated && currentState.frameNames) {
      this.frameNames = currentState.frameNames;
      this.refreshGrid();
      this.framesOrderUpdated = true;
    }

    if (currentState.cursorOverImageName) {
      this.displayArrows(currentState.cursorOverImageName);
    }

    if (currentState.swappedFrames) {
      const [currentFrameName, swappedFrameName] = currentState.swappedFrames;
      const currentIndex = this.frameNames.indexOf(currentFrameName);
      const newIndex = this.frameNames.indexOf(swappedFrameName);

      this.moveItems(currentIndex, newIndex);
    }
  }

  private initializeImages() {
    for (let i = 0; i < this.gridConfiguration.numberOfItems; i++) {
      const x =
        this.gridConfiguration.x +
        (this.gridConfiguration.cardWidth + this.gridConfiguration.paddingX) *
          (i % 4);
      const y =
        this.gridConfiguration.y +
        (this.gridConfiguration.cardHeight + this.gridConfiguration.paddingY) *
          Math.floor(i / 4);

      const frameName = this.frameNames[i];

      const image = new Phaser.GameObjects.Image(
        this.scene,
        x,
        y,
        "jigsawAtlas",
        frameName
      )
        .setName(frameName)
        .setInteractive();

      this.add(image);
      this.images.push(image);

      image.on(Phaser.Input.Events.POINTER_OVER, () => {
        this.onImagePointerOver(image);
      });
    }
  }

  private onImagePointerOver(image: Phaser.GameObjects.Image) {
    const payload = {
      cursorOverImageName: image.name,
      frameNames: this.frameNames,
      swappedFrames: undefined,
    };
    this.emitPuzzleStateChanged(payload);
  }

  private displayArrows(imageName: string) {
    const image = this.images.find((img) => img.name === imageName);

    if (!image) {
      return;
    }

    this.arrowDown.setPosition(image.x, image.y + image.height / 3);
    this.arrowUp.setPosition(image.x, image.y - image.height / 3);
    this.arrowRight.setPosition(image.x + image.width / 3, image.y);
    this.arrowLeft.setPosition(image.x - image.width / 3, image.y);

    this.arrows.forEach((arrow) => {
      arrow.setVisible(true).setName(image.name);
    });

    const index = this.frameNames.indexOf(image.name);

    if (index < 4) {
      this.arrowUp.setVisible(false);
    } else {
      this.arrowDown.setVisible(false);
    }

    if (index === 0 || index === 4) {
      this.arrowLeft.setVisible(false);
    }

    if (index === 3 || index === 7) {
      this.arrowRight.setVisible(false);
    }
  }

  private initializeArrows() {
    this.arrows.forEach((arrow) => {
      const arrowDirection = arrow.getData("direction") as
        | "up"
        | "down"
        | "left"
        | "right";
      arrow.setVisible(false).setInteractive();
      arrow.on(Phaser.Input.Events.POINTER_DOWN, () =>
        this.handleArrowClick(arrowDirection)
      );
      this.add(arrow);
    });
  }

  private handleArrowClick(direction: "up" | "down" | "left" | "right") {
    const currentIndex = this.frameNames.indexOf(this.arrowUp.name);

    let newIndex: number;

    switch (direction) {
      case "up":
      case "down":
        newIndex = (currentIndex + 4) % this.gridConfiguration.numberOfItems;
        break;
      case "left":
        newIndex = currentIndex - 1;
        break;
      case "right":
        newIndex = currentIndex + 1;
        break;
    }

    const currentFrameName = this.frameNames[currentIndex];
    const swappedFrameName = this.frameNames[newIndex];

    this.swapFrames(currentFrameName, swappedFrameName);
  }

  private swapFrames(currentFrameName: string, swappedFrameName: string) {
    const payload = {
      swappedFrames: [currentFrameName, swappedFrameName] as [string, string],
      frameNames: undefined,
      cursorOverImageName: undefined,
    };
    this.emitPuzzleStateChanged(payload);
  }

  private moveItems(currentIndex: number, newIndex: number) {
    const temp = this.frameNames[currentIndex];
    this.frameNames[currentIndex] = this.frameNames[newIndex];
    this.frameNames[newIndex] = temp;

    this.refreshGrid();
  }

  private refreshGrid() {
    for (let i = 0; i < this.gridConfiguration.numberOfItems; i++) {
      const frameName = this.frameNames[i];
      const image = this.images.find((img) => img.name === frameName);
      const x =
        this.gridConfiguration.x +
        (this.gridConfiguration.cardWidth + this.gridConfiguration.paddingX) *
          (i % 4);
      const y =
        this.gridConfiguration.y +
        (this.gridConfiguration.cardHeight + this.gridConfiguration.paddingY) *
          Math.floor(i / 4);
      image?.setPosition(x, y);
    }

    this.arrows.forEach((arrow) => {
      arrow.setVisible(false);
    });

    this.checkIfComplete();
  }

  private getFramesInRandomOrder(frames: string[]): string[] {
    return Phaser.Utils.Array.Shuffle(frames);
  }

  private checkIfComplete() {
    for (let i = 0; i < this.initialFrameNames.length; i++) {
      if (this.initialFrameNames[i] !== this.frameNames[i]) {
        return;
      }
    }

    this.textObject.setText("You've got it!");

    for (let i = 0; i < this.images.length; i++) {
      this.images[i].removeInteractive();
    }

    this.arrows.forEach((arrow) => {
      arrow.destroy();
    });

    this.completePuzzle();
  }
}
