import { useState, useEffect } from "react";
import {
  Button, Tooltip, Switch, Input, TextArea, Upload
} from "@appkit4/react-components";
import { useModal } from "./UnsavedChangesContext";
import { useGameStore } from "@/store/game-store";
import { useMutation } from "react-query";
import { updateGame } from "@/api/game";
import { renderNotification } from "../notification/renderFunctions";
import styles from "./Intro.module.scss";
import { deleteAssetFile, uploadAssetFile } from "@/api/assets";
import { v4 as uuidv4 } from "uuid";
import { UploadFileDescriptor } from "@/models/Files/UploadFileDescriptor";

const Intro = () => {
  const baseUrl = window.location.origin;
  const game = useGameStore((state) => state.game);
  const [headerValue, setHeaderValue] = useState("");
  const [introValue, setIntroValue] = useState("");
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isIntroActive, setIsIntroActive] = useState(true);
  const modalContext = useModal();
  //  const isModalOpen = modalContext?.isModalOpen || false;
  // const showModal = modalContext?.showModal || (() => {});
  // const hideModal = modalContext?.hideModal || (() => {});

  //const [isIntroActive, setIsIntroActive] = useState(game.displayIntroduction);

  const [gameSaved, setGameSaved] = useState(true);

  const saveGameMutation = useMutation({
    mutationKey: ["saveGame", game._id],
    mutationFn: () => updateGame(game._id, game),
    onSuccess: (res) => {
      useGameStore.getState().setGame(res.data.data);
    },
    onError: (err) => {
      renderNotification(err.response.data.message, "error");
    },
  });

  useEffect(() => {
    if (!gameSaved) {
      saveGameMutation.mutate();
      setGameSaved(true);
    }
  }, [game]);

  const uploadFileMutation = useMutation({
    mutationFn: async (file: UploadFileDescriptor) => {
      const fileExtension = file.name.split('.').pop();
      const guidFilename = `${uuidv4()}.${fileExtension}`;
      const filePath = "game-editor";
      await uploadAssetFile({ ...file, name: guidFilename }, filePath);
      const mediaId = `${filePath}/${guidFilename}`;
      const updatedGame = JSON.parse(JSON.stringify(game));
      updatedGame.introductionMediaId = mediaId;
      useGameStore.getState().setGame(updatedGame);
      setGameSaved(false);
    },
    onSuccess: () => {
      renderNotification("File uploaded successfully!", "success");
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const deleteFileMutation = useMutation({
    mutationFn: async () => {
      if (!game.introductionMediaId) {
        throw new Error("No file to delete.");
      }
      return deleteAssetFile(game.introductionMediaId);
    },
    onSuccess: () => {
      const updatedGame = JSON.parse(JSON.stringify(game));
      updatedGame.introductionMediaId = "";
      useGameStore.getState().setGame(updatedGame);
      setGameSaved(false);
      renderNotification("File deleted.", "success");
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const handleSaveClick = () => {
    if (isIntroActive) {
      renderNotification("Your introduction has been added into the game", "success");
    } else {
      renderNotification("Your introduction has been removed from the game", "success");
    }

    const updatedGame = JSON.parse(JSON.stringify(game));
    updatedGame.introductionHeader = headerValue;
    updatedGame.introduction = introValue;
    updatedGame.displayIntroduction = isIntroActive;
    useGameStore.getState().setGame(updatedGame);

    setGameSaved(false);
  };

  const onHeaderChange = (value) => {
    setHeaderValue(value);
    modalContext?.setIsChanged(true);
  };

  const onIntroChange = (value) => {
    setIntroValue(value);
    modalContext?.setIsChanged(true);
  };

  const handleUploadChange = (
    fileOrFiles: UploadFileDescriptor | UploadFileDescriptor[]
  ) => {
    const files = Array.isArray(fileOrFiles) ? fileOrFiles : [fileOrFiles];

    files.forEach((file) => {
      if (file.status === "deleted") {
        deleteFileMutation.mutate();
      } else if (file.status === "selected" || file.status === "ready") {
        uploadFileMutation.mutate(file);
        setFileUploaded(true);
      }
    });

    modalContext?.setIsChanged(true);
  };

  useEffect(() => {
    if (
      headerValue !== game.introductionHeader ||
      introValue !== game.introduction ||
      isIntroActive !== game.displayIntroduction ||
      fileUploaded
    ) {
      modalContext?.setIsChanged(true);
    } else {
      modalContext?.setIsChanged(false);
    }
  }, [headerValue,
    introValue,
    isIntroActive,
    fileUploaded,
    game.introductionHeader,
    game.introduction,
    game.displayIntroduction]);

  useEffect(() => {
  }, [modalContext?.isChanged]);

  useEffect(() => {
    setHeaderValue(game.introductionHeader);
    setIntroValue(game.introduction);
    setIsIntroActive(game.displayIntroduction);
  }, ([game]));

  const introductionMediaId = game.introductionMediaId;
  const isVideo = introductionMediaId?.toLowerCase().endsWith(".mp4");
  const mediaUrl = introductionMediaId ? `${baseUrl}/${introductionMediaId}` : null;

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h1 className="ap-font-weight-2">Introduction (optional)</h1>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.saveButton}
            onClick={handleSaveClick}
            kind="primary"
            disabled={!modalContext?.isChanged}
          >
            Save changes
          </Button>
        </div>
      </div>
      <Tooltip
        trigger="hover"
        position="top-left"
        distance={4}
        id="tooltipDesc"
        appendAfterTarget={true}
        content="Toggle ON to include an intro for the context; OFF for a direct game start. Intro content is retained, simply hidden from view."
      >
        <Switch
          defaultChecked={isIntroActive}
          checked={isIntroActive}
          onChange={() => {
            setIsIntroActive((prevState) => {
              modalContext?.setIsChanged(true);
              if (isIntroActive === false) {
                modalContext?.setIsIntroActive(true);
              }
              else {
                modalContext?.setIsIntroActive(false);
              }
              return !prevState;
            });
          }}
          style={{ paddingBottom: "10px" }}
        >
          {isIntroActive ? "Active" : "Inactive"}
        </Switch>
      </Tooltip>
      <h3 className="ap-typography-body">
        Craft a welcoming message or introduction for participants, explaining why they're playing.
        <br />
        This section is optional and can be toggled on or off.
      </h3>
      {isIntroActive && (
        <>
          <div className={styles.divider}></div>
          <h3 className="ap-typography-body ap-font-weight-2">Customise your introduction</h3>
          <Input
            className={`${styles.introInput} ap-typography-body`}
            type="text"
            title="Header"
            value={headerValue}
            onChange={onHeaderChange}
          />
          <TextArea
            className={`${styles.introInput} ap-typography-body`}
            title="Your introductory text"
            maxLength={420}
            value={introValue}
            autosize={true}
            onChange={onIntroChange}
          />
          <div className={styles.divider}></div>
          <h3 className="ap-typography-body ap-font-weight-2">Add an image or video to your introduction (optional)</h3>
          <p className="ap-typography-body">
            You can upload JPG, PNG or MP4 files. The max file size is 10mb.
            <br />
          </p>
          <p className="ap-typography-body ap-font-weight-2">Videos extend question completion time. Adjust your game plan accordingly.</p>
          <Upload
            className="upload"
            onChange={handleUploadChange}
            multiple={false}
            autoUpload={false}
            style={{ width: "940px" }}
            acceptFileType=".JPG,.PNG,.MP4"
            maxFileSize={10 * 1024 * 1024}
            config={{ trigger: false, type: "inline", size: true }}
          />
        </>
      )}
      <div className={styles.divider}></div>
      <h3 className="ap-typography-body ap-font-weight-2">Preview the page</h3>
      <p className="ap-typography-body">
        Preview reflects the last saved version.
        <br />
      </p>
      <div className={styles.previewContainer}>
        <div className="row" style={{ alignItems: "flex-start" }}>
          <div className="col-6">
            <h1 className="ap-font-weight-2">{headerValue}</h1>
            <p className="ap-typography-body">{introValue}</p>
            <button
              className={styles.continueButton}
              style={{ marginTop: "1rem" }}
            >
              Continue
            </button>
          </div>

          <div className="col-6">
            {mediaUrl && (
              isVideo ? (
                <video
                  className={styles.introMedia}
                  src={mediaUrl}
                  controls
                  style={{ maxWidth: "100%", margin: "6rem auto" }}
                />
              ) : (
                <img
                  className={styles.introMedia}
                  src={mediaUrl}
                  alt="Introduction Media"
                  style={{ maxWidth: "100%", margin: "6rem auto" }}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
