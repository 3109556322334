import { IRoomObject } from "escape-rooms-types/types/game";
import EventBridge from "@/utils/EventBridge";
import BaseRoomObject from "./BaseRoomObject";
import { TextModalPayload } from "../modals/ModalPayloads";
import GameScene from "@/phaser/scenes/GameScene";
import GameAction from "../actions/GameAction";
import Player from "../session/Player";
import { areArraysEqual } from "@/utils/helpers";
import { HEADER_HEIGHT } from "@/constants";

export default class GameRoomObject extends BaseRoomObject {
  public scene: GameScene;
  public actions: GameAction[];
  public lockedToPlayer?: Player;
  public id: string;

  constructor(
    scene: GameScene,
    xPos: number,
    yPos: number,
    gameObject: IRoomObject
  ) {
    yPos = yPos + HEADER_HEIGHT;

    super(scene, xPos, yPos, gameObject);
    this.scene = scene;
    this.actions = [];
    this.id = gameObject._id!;
    this.loadActions();
    this.addEventListeners();
  }

  public addToScene(x: number, y: number) {
    const xOffset =
      this.gameObject.asset.states[this.currentState].xOffset -
      Object.values(this.gameObject.asset.states)[0].xOffset;
    const yOffset =
      this.gameObject.asset.states[this.currentState].yOffset -
      Object.values(this.gameObject.asset.states)[0].yOffset;
    this.sprite = this.scene.add.sprite(
      x + xOffset,
      y + yOffset,
      this.getTextureFromState(this.currentState)
    );
    this.sprite.setName(this.gameObject.ref);
    this.setVisible(this.gameObject.isVisible);
    return this.sprite;
  }

  public getTextureFromState(state: string) {
    if (
      areArraysEqual(
        this.gameObject.colorPalette,
        this.gameObject.asset.defaultColorPalette
      )
    ) {
      return `${this.gameObject.asset.states[state].textureUrl}_scene`;
    }

    const textureKey =
      `${this.ref}_${this.gameObject.asset.states[state].name}_scene`.toLowerCase();
    return textureKey;
  }

  public getThumbnailFromState(state: string) {
    if (
      areArraysEqual(
        this.gameObject.colorPalette,
        this.gameObject.asset.defaultColorPalette
      )
    ) {
      return `${this.gameObject.asset.states[state].textureUrl}_thumbnail`;
    }

    const textureKey =
      `${this.ref}_${this.gameObject.asset.states[state].name}_thumbnail`.toLowerCase();
    return textureKey;
  }

  public executeAction() {
    // this.removeGlow();
    this.actions[this.currentAction].executeAction();
  }

  public setVisible(isVisible: boolean) {
    this.isVisible = isVisible;
    this.gameObject.isVisible = isVisible;
    if (isVisible) {
      this.sprite.alpha = 1;
    }
    if (!isVisible) {
      this.sprite.alpha = 0;
    }
  }

  public setIsDisabled(isDisabled: boolean) {
    if (!this.sprite.active) {
      return;
    }
    this.isDisabled = isDisabled;
    this.gameObject.isDisabled = isDisabled;
    if (isDisabled) {
      this.sprite.disableInteractive();
    } else {
      this.sprite.setInteractive();
    }
  }

  public updateObject(object: IRoomObject) {
    if (object.isVisible != null) {
      this.setVisible(object.isVisible);
    }
    if (object.isDisabled != null) {
      this.setIsDisabled(object.isDisabled);
    }
    if (object.currentState != null) {
      this.changeState(object.currentState, this.currentState);
    }
    EventBridge.emit("minimap.redraw");
  }

  public changeState(newState: string, oldState?: string) {
    if (
      newState == null ||
      this.scene.sys.textures === null ||
      !this.sprite.active
    ) {
      return;
    }
    this.sprite.setTexture(this.getTextureFromState(newState));
    if (oldState) {
      const xMovement =
        this.gameObject.asset.states[newState].xOffset -
        this.gameObject.asset.states[oldState].xOffset;
      const yMovement =
        this.gameObject.asset.states[newState].yOffset -
        this.gameObject.asset.states[oldState].yOffset;
      this.sprite.setX(this.sprite.x + xMovement);
      this.sprite.setY(this.sprite.y + yMovement);
    }
    this.currentState = newState;
    this.gameObject.currentState = newState;
  }

  public setCurrentAction(newCurrentAction: number, sourcePlayerId: string) {
    this.removeGlow();
    this.currentAction = newCurrentAction;
    this.runNextActionIfNeeded(sourcePlayerId);
  }

  public completeAction(sourcePlayerId: string, executeNextAction: boolean = true) {
    // this.removeGlow();
    this.currentAction += 1;

    if (this.actions[this.currentAction] == null) {
      this.sprite.disableInteractive();
      return;
    }

    if (executeNextAction) {
      this.runNextActionIfNeeded(sourcePlayerId);
    }
  }

  public runNextActionIfNeeded(sourcePlayerId: string) {
    if (this.actions[this.currentAction] == null) {
      this.sprite.disableInteractive();
      return;
    }

    // This stops the next action from running if the action was triggered by
    // another user. Do we want this? Do we want all users to see messages?
    if (sourcePlayerId != this.scene.user.id) {
      return;
    }

    if (
      this.scene.isNavigatorScene() == false &&
      this.actions[this.currentAction].type !== "displayUnlockScreen"
    ) {
      return;
    }

    if (
      this.scene.isNavigatorScene() == false &&
      (this.actions[this.currentAction].type === "displayImage" ||
        this.actions[this.currentAction].type === "displayImageByState")
    ) {
      return;
    }

    const actionIsAutomatic =
      this.actions[this.currentAction].trigger === "afterPrevious";

    // only execute next action if afterPrevious triggered
    if (!actionIsAutomatic) {
      return;
    }

    const actionIsStateChanging = [
      "changeOther",
      "changeSelf",
      "addToInventory",
      "endRoom",
      "endSession",
    ].includes(this.actions[this.currentAction].type);

    // only execute state change action if you YOU are the player that started
    // the action execution chain. Otherwise we get ALL players emitting the next
    // updateObject event which can cascade into a mess.
    if (actionIsStateChanging && sourcePlayerId != this.scene.game.user.id) {
      return;
    }

    this.executeAction();
  }

  // public incrementProgress() {
  //   EventBridge.emit("game.incrementActionsDone");
  // }

  public setUsingItem() {
    EventBridge.emit("game.setUsingItem", this);
  }

  public focus() {
    // when selected
  }

  public blur() {
    // when deselected
  }

  private loadActions() {
    if (
      this.gameObject.actions == null ||
      this.gameObject.actions.length === 0
    ) {
      return;
    }

    this.sprite.setInteractive();
    this.gameObject.actions.forEach((action) => {
      this.actions.push(
        new GameAction(
          this.scene,
          this,
          0,
          action._id!,
          action.ref,
          action.trigger!,
          action.type!,
          action.changeObjectPayload,
          action.modalPayload,
          action.questionnaire,
          action.triggerItemRef,
          action.mediaPayload,
          action.repeatable,
          action.stateBasedPayload
        )
      );
    });
  }

  private addEventListeners() {
    this.sprite.on("pointerover", () => {
      if (this.lockedToPlayer != null) {
        return;
      }
      this.glowIfInteractive();
    });
    this.sprite.on("pointerout", () => {
      if (this.lockedToPlayer != null) {
        return;
      }
      this.removeGlow();
    });

    this.sprite.on("pointerdown", () => {
      if (!this.scene.isNavigatorScene()) {
        return;
      }

      this.removeGlow();
      const action = this.actions[this.currentAction];

      if (
        this.scene.itemBeingUsed != null &&
        this.scene.itemBeingUsed.gameObject.ref != action?.triggerItemRef
      ) {
        EventBridge.emit("ui.openMessagePopup", {
          text: "This doesn't seem to do anything",
          sourceObject: this,
        } satisfies TextModalPayload);

        this.scene.itemBeingUsed = undefined;
        EventBridge.emit("ui.stopUsingItem");
        return;
      }

      if (action == null) {
        console.log(`No actions remaining on ${this.ref}`);
        return;
      }

      if (
        action.trigger === "onItemUse" &&
        this.scene.itemBeingUsed != null &&
        this.scene.itemBeingUsed.ref === action.triggerItemRef
      ) {
        EventBridge.emit("game.useItem", {
          object: this.gameObject,
          itemRef: action.triggerItemRef,
        });
        return;
      }

      if (
        action.trigger == "onClick" &&
        this.lockedToPlayer != null &&
        this.lockedToPlayer.id != this.scene.user.id
      ) {
        EventBridge.emit("ui.openMessagePopup", {
          text: `${this.lockedToPlayer.firstName} is currently using this!`,
          description: "",
          sourceObject: this,
        } satisfies TextModalPayload);
        return;
      }

      if (action.trigger === "onClick") {
        this.executeAction();
        return;
      }

      // This code makes sure that the an object's action chain doesn't get stuck if
      // a player exits a modal.
      if (action.trigger === "afterPrevious") {
        this.executeAction();
        return;
      }
    });
  }

  public hintUsed() {
    const glowEffect = setInterval(() => {
      setTimeout(() => {
        this.addGlow();
      }, 200);
      this.removeGlow();
    }, 1000);

    setTimeout(() => {
      clearInterval(glowEffect);

      setTimeout(() => {
        this.removeGlow();
      }, 1000);
    }, 3000);
  }

  private removeEventListeners() {}

  public destroy(): void {
    super.destroy();
    this.removeEventListeners();
  }

  public setLockedToPlayer(player: Player) {
    if (this.lockedToPlayer != null) {
      return;
    }
    this.lockedToPlayer = player;
    this.addGlow(player.color, 5, 2);
  }

  public unlockFromPlayer() {
    this.lockedToPlayer = undefined;
    this.removeGlow();
  }
}
