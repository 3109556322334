import React, { useState } from "react";
import { Button, Modal, Panel, Steppers, Stepper, Input, Select, Notification, toaster } from "@appkit4/react-components";
import { useNavigate, useLocation } from "react-router-dom";
import GameSaved from "@/components/game-saved/GameSaved";
import styles from "./GameInfo.module.scss";
import { industries } from "@/constants";
import { createGameFromTemplate } from "@/api/game";
import { IGame } from "escape-rooms-types/types/game";

const GameInfo = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { stepperActiveIndex, selectedTheme, selectedQs, selectedDs, selectedRooms, selectedPuzzles, name, industry, client } = location.state;
    const [showGameCreationModal, setShowGameCreationModal] = useState(false);
    const [showAdvancedModeModal, setShowAdvancedModeModal] = useState(false);
    const [showGameSavedModal, setShowGameSavedModal] = useState(false);
    const [nameValue, setNameValue] = useState(name ?? "");
    const [clientValue, setClientValue] = useState(client ?? "");
    const [dropdownValue, setDropdownValue] = useState(industry ?? "");
    const [nameError, setNameError] = useState(false);
    const [clientError, setClientError] = useState(false);
    const [dropdownError, setDropdownError] = useState(false);
    const [createdGame, setCreatedGame] = useState({} as Partial<IGame>);

    const templateDetails = [
        { label: "Theme", value: selectedTheme },
        { label: "Length", value: selectedDs },
        { label: "Training Questions", value: selectedQs },
        { label: "Rooms", value: selectedRooms },
        { label: "Puzzles", value: selectedPuzzles },
    ];

    const handleClose = () => {
        console.log('Closed!')
    }

    const handleBack = () => {
        console.log(selectedDs, selectedQs)
        navigate(`/games/templatesetup`, {
            state: {
                stepperActiveIndex: 1,
                selectedTheme: selectedTheme,
                selectedQs: selectedQs,
                selectedDs: selectedDs,
                showDurationPanel: true,
                firstPanelButtonsVisible: false,
                name: nameValue,
                industry: dropdownValue,
                client: clientValue,
            }
        });
    };

    const emptyError = (
        <Notification
            title='Error: Missing Information'
            message={(
                <div>
                    Some information is missing. <br />
                    Please fill out all fields.
                </div>
            )}
            status='error'
        />

    );

    const onNameChange = (value: any, event: any) => {
        event.preventDefault();
        setNameValue(value);
        setNameError(false);
    };

    const onClientChange = (value: any, event: any) => {
        event.preventDefault();
        setClientValue(value);
        setClientError(false);
    };

    const onSaveGame = async () => {
        const errors = {
            nameError: nameValue.length <= 0,
            clientError: clientValue.length <= 0,
            dropdownError: !dropdownValue
        };

        const hasErrors = Object.values(errors).some(error => error);

        if (hasErrors) {
            Object.entries(errors).forEach(([errorType, isError]) => {
                if (isError) {
                    switch (errorType) {
                        case 'nameError':
                            setNameError(true);
                            break;
                        case 'clientError':
                            setClientError(true);
                            break;
                        case 'dropdownError':
                            setDropdownError(true);
                            break;
                        default:
                            break;
                    }
                }
            });

            toaster.notify(emptyError, { position: 'center', onClose: handleClose });
        } else {
            await createGameFromTemplate({
                name: nameValue,
                industry: dropdownValue,
                client: clientValue,
                theme: selectedTheme,
                duration: selectedDs,
                trainingQuestions: selectedQs,
                rooms: selectedRooms,
                puzzles: selectedPuzzles
            })
                .then((res) => {
                    setCreatedGame(res.data);
                    setShowGameSavedModal(true);

                    console.log("Game saved!", {
                        name: nameValue,
                        industry: dropdownValue,
                        client: clientValue,
                        theme: selectedTheme,
                        duration: selectedDs,
                        trainingQuestions: selectedQs,
                        rooms: selectedRooms,
                        puzzles: selectedPuzzles
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    return (
        <div className="ap-container">
            <div className="row">
                <h1 className="ap-typography-4 ap-font-weight-1 ap-mt-spacing-6 ap-mb-spacing-6">
                    Create a new game
                </h1>
                <div className={`col-2 ${styles.colorContainer}`}>
                    <Steppers
                        orientation="vertical"
                        readonly
                        space={40}
                        activeIndex={stepperActiveIndex}
                    >
                        <Stepper label="Theme" status="normal"></Stepper>
                        <Stepper label="Template set up" status="normal"></Stepper>
                        <Stepper label="Basic Information" status="normal"></Stepper>
                    </Steppers>
                </div>
                <div className="col-6">
                    <Panel className="ap-pt-spacing-3 ap-mb-spacing-6">
                        <div className="row ap-my-spacing-6">
                            <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
                                Basic information
                            </h1>
                            <h2 className="ap-typography ap-font-weight-1 ap-mt-spacing-4 ap-mb-spacing-6">
                                You can edit these details later in the settings
                            </h2>
                            <div className="col-6">
                                <Input
                                    type={"text"}
                                    title={"Name of Game"}
                                    required={true}
                                    style={{ marginBottom: "20px" }}
                                    value={nameValue}
                                    onChange={onNameChange}
                                    error={nameError}
                                    errorNode={(<div id="errormessage" aria-live="polite" className="ap-field-email-validation-error">Please enter a game name</div>)}
                                />
                                <Select
                                    data={industries.map(({ label, value }) => ({ label, value }))}
                                    value={dropdownValue}
                                    searchable={false}
                                    placeholder={"Industry"}
                                    defaultValue={dropdownValue}
                                    required={true}
                                    style={{ marginBottom: "20px" }}
                                    onSelect={(dropdownValue) => {
                                        setDropdownValue(dropdownValue);
                                        setDropdownError(false);
                                    }}
                                    error={dropdownError}
                                />
                                <Input
                                    type={"text"}
                                    title={"Client"}
                                    required={true}
                                    style={{ marginBottom: "20px" }}
                                    value={clientValue}
                                    onChange={onClientChange}
                                    error={clientError}
                                    errorNode={(<div id="errormessage" aria-live="polite" className="ap-field-email-validation-error">Please enter a client name</div>)}
                                />
                            </div>
                            <h2 className="ap-typography ap-font-weight-1 ap-mt-spacing-4 ap-mb-spacing-6">
                                After this step, the game will be saved as a draft in the games list
                            </h2>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button
                                kind="secondary"
                                style={{ width: "100px" }}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Button
                                kind="primary"
                                style={{ width: "100px" }}
                                className="col-6"
                                onClick={onSaveGame}
                            >
                                Save game
                            </Button>
                            <GameSaved visible={showGameSavedModal} setVisible={setShowGameSavedModal} createdGameId={createdGame._id} />
                        </div>
                    </Panel>
                </div>
                <div className="col-4">
                    <Panel className="ap-pt-spacing-3 ap-mb-spacing-6">
                        <div className="row ap-my-spacing-6">
                            <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
                                Template Overview
                            </h1>
                            {templateDetails.map((detail, index) => (
                                <React.Fragment key={index}>
                                    <div className="row detail-line">
                                        <div
                                            className={`${styles.templateCard} col-6 ap-typography ap-font-weight-1 ap-mt-spacing-4 ap-mb-spacing-4`}
                                        >
                                            {detail.label}
                                        </div>
                                        <div className="col-6 ap-font-weight-2 ap-typography ap-mt-spacing-4 ap-mb-spacing-4">
                                            {detail.value}
                                        </div>
                                    </div>
                                    {index !== templateDetails.length - 1 && (
                                        <div className="divider-container">
                                            <div className={styles.divider}></div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </Panel>
                </div>
            </div>
            <Modal
                visible={showAdvancedModeModal}
                title={"Switch to Advanced Mode?"}
                ariaLabel={"Switch to Advanced Mode?"}
                onCancel={() => setShowAdvancedModeModal(false)}
                modalStyle={{ width: "33.75rem" }}
                footerStyle={{
                    paddingTop: "8px",
                    marginTop: "-8px",
                    minHeight: "64px",
                }}
                footer={
                    <>
                        <Button
                            onClick={() => {
                                setShowAdvancedModeModal(false);
                            }}
                            kind="secondary"
                        >
                            Go to Advanced Mode
                        </Button>
                        <Button
                            onClick={() => {
                                setShowAdvancedModeModal(false);
                            }}
                        >
                            Continue with template
                        </Button>
                    </>
                }
                bodyStyle={{ minHeight: "92px" }}
            >
                <p>
                    You are about to switch to Advanced Mode. This action is irreversible.
                    <br />
                    <br />
                    In Advanced Mode, you'll be responsible for creating and configuring each room and puzzle manually.
                    <br />
                    <br />
                    Please be aware, you cannot return to the templated version once you proceed. Ensure you are ready for this level of customisation and control.
                    <br />
                </p>
            </Modal>
            <Modal
                visible={showGameCreationModal}
                title={"Are you sure you want to leave?"}
                ariaLabel={"Are you sure you want to leave?"}
                onCancel={() => setShowGameCreationModal(false)}
                modalStyle={{ width: "33.75rem" }}
                footerStyle={{
                    paddingTop: "8px",
                    marginTop: "-8px",
                    minHeight: "64px",
                }}
                footer={
                    <>
                        <Button
                            onClick={() => {
                                navigate("/");
                                setShowGameCreationModal(false);
                            }}
                            kind="secondary"
                        >
                            Leave page
                        </Button>
                        <Button onClick={() => setShowGameCreationModal(false)}>
                            Stay and continue
                        </Button>
                    </>
                }
                bodyStyle={{ minHeight: "92px" }}
            >
                <p>
                    If you leave this page now, any selections you've made will be lost. Would you like to stay on this page and continue your progress?
                </p>
            </Modal>
        </div>
    );
};

export default GameInfo;
