import {createGame, deleteGame, duplicateGame, getClientList, getGame, getGames} from "@/api/game";
import {
  Avatar,
  Button,
  Column,
  Input, Modal,
  Pagination,
  PaginationRef,
  Search,
  Select,
  Table,
} from "@appkit4/react-components";
import {IGame} from "escape-rooms-types/types/game";
import {useCallback, useEffect, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useMutation, useQuery} from "react-query";
import {useNavigate} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import styles from "./GameCreator.module.scss";
import {industries} from "@/constants";
import {capitalizeFirstLetter} from "@/utils/helpers";
import {getGameSessionCountByGameId} from "@/api/session";


const GameCreator = () => {
  const intl = useIntl();
  const paginationRef = useRef<PaginationRef>(null);
  const duplicateGameRef = useRef(null);
  const navigate = useNavigate();
  const [games, setGames] = useState<IGame[]>([]);

  const [searchString, setSearchString] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [client, setClient] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string>("_id");
  const [sortOrder, setSortOrder] = useState<number>(1);

  const [totalPages, setTotalPages] = useState<number>(1);

  const [clientList, setClientList] = useState([]);


  const [showDeleteGameModal, setShowDeleteGameModal] =
    useState<boolean>(false);
  const [showDeleteGameConfirmationModal, setShowDeleteGameConfirmationModal] =
    useState<boolean>(false);
  const [showDuplicateGameModal, setShowDuplicateGameModal] =
    useState<boolean>(false);
  const [targetGame, setTargetGame] = useState<IGame | undefined>(
    undefined
  );
  const [targetGameSessionCount, setTargetGameSessionCount] = useState(0);
  const [duplicateGameName, setduplicateGameName] = useState("");


  const duplicateGameMutation = useMutation({
    mutationKey: "duplicateGame",
    mutationFn: async (name: string) => {
      const response = await getGame(targetGame._id)
      return await duplicateGame({
        ...response.data.data,
        name: name,
      })
    },
    onSuccess: (res) => {
      console.log(res)
      navigate(`/games/${res.data._id}/edit`);
      getGamesQuery.refetch();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const deletePreConfirmationGameMutation = useMutation({
    mutationKey: "deletePreConfirmationGame",
    mutationFn: () => getGameSessionCountByGameId(targetGame._id),
    onSuccess: (res) => {
      setTargetGameSessionCount(res);
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const deleteGameMutation = useMutation({
    mutationKey: "deleteGame",
    mutationFn: () => deleteGame(targetGame._id),
    onSuccess: (res) => {
      getGamesQuery.refetch();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const resetFilters = useCallback(() => {
    setSearchString("");
    setStatus("");
    setClient("");
    setIndustry("");
  }, []);

  const getGamesQuery = useQuery({
    queryKey: ["getGames", searchString],
    queryFn: () =>
      getGames(page, limit, sortBy, sortOrder, searchString, status, client, industry),
    onSuccess: (res) => {
      setGames(res.data.data);
      const totalPages = Math.max(Math.ceil(res.data.count / limit), 1);
      setTotalPages(totalPages);
      setPage(Math.min(page, totalPages));
      if (paginationRef.current) {
        paginationRef.current.setPageNumber(Math.min(page, totalPages));
      }
    },
  });

  const getClients = useQuery({
    queryKey: ["getClients"],
    queryFn: getClientList,
    onSuccess: (res) => {
      const clients = res.data.gameClients.map((client) => {
        return {value: client, label: client};
      });
      setClientList(clients)
    },
  });

  const createGameQuery = useQuery({
    queryKey: ["createGame"],
    queryFn: () =>
      createGame({
        name: "Untitled Game",
        rooms: [
          {
            name: "New Room",
            ref: uuidv4(),
            objects: [],
            items: [],
            isActive: true,
            length: 1,
            order: 0,
          },
        ],
      }),
    onSuccess: (res) => {
      navigate(`/games/${res.data._id}`);
      getGamesQuery.refetch();
    },
    enabled: false,
  });

  const openGame = (game: IGame) => {
    return navigate(`/games/${game._id}/edit`);
  };

  useEffect(() => {
    getGamesQuery.refetch();
  }, [page, limit, sortOrder, sortBy, searchString, status, client, industry]);

  const renderDate = (row: any, field: string) => {
    if (!row[field]) return "";

    let date = new Date(row[field]);
    return (
      <div className="ap-font-weight-2">
        {date.toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      </div>
    );
  };

  const renderClient = (row: any, field: string) => {
    if (row["client"]) {
      return row["client"];
    }
    return "N/A";
  };

  const renderIndustry = (row: any, field: string) => {
    if (row["industry"]) {
      return row["industry"];
    }
    return "N/A";
  };

  const renderCreator = (row: any, field: string) => {
    const creator = row["creator"].trim() || "N/A";
    const label =
      creator === "N/A"
        ? "NA"
        : creator
          .split(" ")
          .map((word: string) => word[0])
          .join("")
          .toUpperCase();
    return (
      <div className="flex gap-1 align-items-center">
        <Avatar className={styles.avatarText} size={25} label={label}/>
        <div>{creator}</div>
      </div>
    );
  };

  const renderAction = (row: any, field: string) => {
    return (
      <div className="flex gap-1">
        <i
          title="Duplicate Game"
          className="Appkit4-icon icon-duplicate-outline btn"
          onClick={() => {
            setduplicateGameName(`(copy) ${row.name}`)
            setShowDuplicateGameModal(true)
            setTargetGame(row);
          }}
        ></i>
        <i
          title="Edit Game"
          className="Appkit4-icon icon-edit-outline btn"
          onClick={() => {
            openGame(row);
          }}
        ></i>
        <i
          title="Delete game"
          className="Appkit4-icon icon-delete-outline btn"
          onClick={() => {
            setShowDeleteGameModal(true);
            setTargetGame(row);
          }}
        ></i>
      </div>
    );
  };

  const renderStatus = (row: any, field: string) => {
    if (!row[field]) return "";

    return (
      <div className="flex align-center gap-1">
        <div
          className={`circle ap-bg-color-background-${
            row[field] === "published" ? "success" : "warning"
          }`}
        ></div>
        <div>{capitalizeFirstLetter(row[field])}</div>
      </div>
    );
  };

  const onSort = (sortKey: string, sortingPhase: number) => {
    setSortBy(sortKey);

    if (sortingPhase == 0) {
      setSortOrder(1);
      return;
    }

    setSortOrder(sortingPhase === 1 ? 1 : -1);
  };

  const showResetButton = () => {
    return searchString || status || client || industry;
  }

  return (
    <div className="ap-container ap-mt-spacing-7">
      <Modal
        visible={showDeleteGameModal}
        title={intl.formatMessage({id: "game.action.delete.modal.title"})}
        ariaLabel={intl.formatMessage({id: "game.action.delete.modal.title"})}
        onCancel={() => {
          setShowDeleteGameModal(false);
        }}
        modalStyle={{width: "33.75rem"}}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                deletePreConfirmationGameMutation.mutate();
                setShowDeleteGameModal(false);
                setShowDeleteGameConfirmationModal(true);
              }}
            >
              <FormattedMessage id="game.action.delete.modal.confirm"/>
            </Button>
            <Button
              onClick={() => {
                setShowDeleteGameModal(false);
              }}
            >
              <FormattedMessage id="button.cancel"/>
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="game.action.delete.modal.description"/>
        </p>
      </Modal>

      <Modal
        visible={showDeleteGameConfirmationModal}
        title={intl.formatMessage({id: "game.action.delete.confirmation.modal.title"})}
        ariaLabel={intl.formatMessage({id: "game.action.delete.confirmation.modal.title"})}
        onCancel={() => {
          setShowDeleteGameConfirmationModal(false);
        }}
        modalStyle={{width: "33.75rem"}}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                deleteGameMutation.mutate();
                setShowDeleteGameConfirmationModal(false);
              }}
            >
              <FormattedMessage id="game.action.delete.confirmation.modal.confirm"/>
            </Button>
            <Button
              onClick={() => {
                setShowDeleteGameConfirmationModal(false);
              }}
            >
              <FormattedMessage id="button.cancel"/>
            </Button>
          </div>
        }
      >
        <p style={{whiteSpace: "pre-wrap"}}>
          {(() => {
            if(targetGameSessionCount > 1) {
              return intl.formatMessage({
                id: "game.action.delete.confirmation.modal.description"
              }).replace("{{numberOfSessions}}", targetGameSessionCount.toString())
            } else if (targetGameSessionCount === 1){
              return intl.formatMessage({
                id: "game.action.delete.confirmation.modal.description.singleSession"
              })
            } else {
              return intl.formatMessage({
                id: "game.action.delete.confirmation.modal.description.noSessions"
              })
            }
          })()}
        </p>
      </Modal>

      <Modal
        visible={showDuplicateGameModal}
        title={intl.formatMessage({id: "game.action.duplicate.modal.title"})}
        ariaLabel={intl.formatMessage({id: "game.action.duplicate.modal.title"})}
        onCancel={() => {
          setShowDuplicateGameModal(false);
        }}
        modalStyle={{width: "33.75rem"}}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowDuplicateGameModal(false);
              }}
            >
              <FormattedMessage id="button.cancel"/>
            </Button>
            <Button
              kind="primary"
              onClick={() => {
                if (duplicateGameName.length > 0) {
                  duplicateGameMutation.mutate(duplicateGameName);
                  setShowDuplicateGameModal(false);
                } else if(showDuplicateGameModal) {
                  duplicateGameRef.current.focus()
                }
              }}
            >
              <FormattedMessage id="game.action.duplicate.modal.confirm"/>
            </Button>
          </div>
        }
      >
        <div>
          <p className="ap-mb-spacing-2 ap-text-color-text-light ap-font-16">
            <FormattedMessage id="game.action.duplicate.modal.text"/>
          </p>
          <div>
            <Input
              inputRef={duplicateGameRef}
              className="ap-level-1"
              type={"text"}
              title={"Name of Game"}
              required={true}
              value={duplicateGameName}
              onChange={(e: string) => {
                setduplicateGameName(e);
              }}
            ></Input>
          </div>
        </div>
      </Modal>

      <div className="row">
        <div className="col-12 flex align-center">
          <div className="flex gap-1 align-center ap-my-spacing-3">
            <div
              className="Appkit4-icon icon-door-opened-outline ap-font-24 ap-my-spacing-4"
            ></div>
            <h2 className={styles.sessionsHeading}>
              {intl.formatMessage({id: "game.list.title"})}
            </h2>
          </div>
          <Button
            className="ml-auto"
            icon="icon-plus-outline"
            onClick={() => {
              navigate(`/games/create`);
            }}
          >
            New game
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 flex gap-1 align-center ap-my-spacing-5">
          <Search
            className={styles.searchInput}
            searchValue={searchString}
            onChange={(e) => {
              setSearchString(e);
            }}
            placeholder="Search by name or creator"
          />
          <Button
            kind="text"
            className={`ml-auto${showResetButton() ? '' : ' hidden'}`}
            onClick={resetFilters}
          >
            Reset filters
          </Button>
          <Select
            data={[
              {value: "", label: "All"},
              {value: "published", label: "Published"},
              {value: "draft", label: "Draft"},
            ]}
            value={status}
            onSelect={(e) => {
              setStatus(e)
            }}
            placeholder="Status"
          />
          <Select
            data={[{value: "", label: "All"}, ...clientList]}
            value={client}
            onSelect={(e) => setClient(e)}
            placeholder="Client"
          />
          <Select
            data={[{value: "", label: "All"}, ...industries]}
            placeholder="Industry"
            value={industry}
            onSelect={(e) => setIndustry(e)}
          />
        </div>
        <div className="col-12">
          <Table
            originalData={games}
            hasTitle
            className="data-table"
            onSort={onSort}
          >
            <Column field="name" sortKey="name">Name</Column>
            <Column field="status" sortKey="status" renderCell={renderStatus}>
              Status
            </Column>
            <Column field="client" sortKey="client" renderCell={renderClient}>
              Client
            </Column>
            <Column field="industry" sortKey="industry" renderCell={renderIndustry}>
              Industry
            </Column>
            <Column field="creator" sortKey="creator" renderCell={renderCreator}>
              Creator
            </Column>
            <Column field="createdAt" sortKey="createdAt" renderCell={renderDate}>
              Date created
            </Column>
            <Column field="createdAt" renderCell={renderAction}>
              Actions
            </Column>
          </Table>
        </div>
        <div className="col-12 flex justify-center ap-my-spacing-6">
          <Pagination
            ref={paginationRef}
            current={page}
            onPageChange={(page) => {
              setPage(page);
            }}
            total={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default GameCreator;
