import GameScene from "@/phaser/scenes/GameScene";
import EventBridge from "@/utils/EventBridge";
import { ImagePopupPayload } from "../modals/ImagePopupPayload";

export class DisplayImagePopupGameActionHandler {
    private gameScene: GameScene;
    private imagePopupPayload: ImagePopupPayload;
    
    constructor(gameScene: GameScene, imagePopupPayload: ImagePopupPayload) {
        this.gameScene = gameScene;
        this.imagePopupPayload = imagePopupPayload;
    }

    public execute(): void {
        EventBridge.emit("ui.openImagePopup", this.imagePopupPayload);

        this.gameScene.game.socketEmit("handleMessage",  {
            sessionId: this.gameScene.game.session._id,
            type: "openImagePopup",
            sourcePlayerId: this.gameScene.user.id,
            ...this.imagePopupPayload
          });
    }
}