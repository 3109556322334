import {
  createGameSession,
  deleteGameSession,
  getGameSessions,
} from "@/api/session";
import { Avatar, Button, Column, Pagination, PaginationRef, Search, Select, Table } from "@appkit4/react-components";
import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./GameSessions.module.scss";
import GameHeader from '../game-header/GameHeader'

const GameSessions = () => {
  const navigate = useNavigate();
  const { gameId } = useParams();

  const paginationRef = useRef<PaginationRef>(null);

  const [searchString, setSearchString] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [sortBy, setSortBy] = useState<string>("_id");

  const [totalPages, setTotalPages] = useState<number>(1);

  const resetFilters = useCallback(() => {
    setSearchString("");
    setStatus("");
  }, []);

  const [sessions, setSessions] = useState<
    Array<{ name: string; _id: string }>
  >([]);

  const [sessionIdToDelete, setSessionIdToDelete] = useState<string | null>(
    null
  );

  const getGameSessionsQuery = useQuery({
    queryKey: ["getGameSessions"],
    queryFn: () => getGameSessions(page, limit, sortBy, sortOrder, searchString, status),
    onSuccess: (res) => {
      setSessions(
        res.data.data.filter((session: any) => session.gameId === gameId)
      );
    },
  });

  const deleteGameSessionQuery = useQuery({
    queryKey: ["deleteGameSessions"],
    queryFn: () => deleteGameSession(sessionIdToDelete!),
    onSuccess: (res) => {
      getGameSessionsQuery.refetch();
    },
    enabled: false,
  });

  const addGameSessionQuery = useQuery({
    queryKey: ["createGameSessions"],
    queryFn: () => createGameSession({ gameId: gameId! }),
    onSuccess: (res) => {
      getGameSessionsQuery.refetch();
      openGameSession(res.data.data._id);
    },
    enabled: false,
  });

  const openGameSession = (sessionId: string) => {
    return navigate(`/games/${gameId}/sessions/${sessionId}`);
  };

  const deleteSession = (sessionId: string | null) => {
    if (!sessionId) {
      return;
    }

    setSessionIdToDelete(sessionId);
    setTimeout(() => {
      deleteGameSessionQuery.refetch();
    }, 500);
  };

  const addSession = () => {
    navigate(`/games/${gameId}/sessions/create`);
  };

  const renderAction = (row: any, field: string) => {
    return (
      <Button
        className="Appkit4-icon icon-view-outline"
        kind=""
        onClick={() => {
          openGameSession(row._id);
        }}
      />
    );
  };

  const renderStatus = (row: any, field: string) => {
    if (row.status === "pending") {
      return (
        <div className="flex align-center gap-1">
          <div className="circle ap-bg-color-background-warning"></div>
          <div>Not Started</div>
        </div>
      );
    }
    if (row.status === "started") {
      return (
        <div className="flex align-center gap-1">
          <div className="circle bg-blue"></div>
          <div>Started</div>
        </div>
      );
    }
    if (row.status === "finished") {
      return (
        <div className="flex align-center gap-1">
          <div className="circle ap-bg-color-background-success"></div>
          <div>Completed</div>
        </div>
      );
    }
  };

  const renderName = (row: any, field: string) => {
    if (row.name == "") {
      return "N/A"
    }
    return row.name;
  };

  const renderCreator = (row: any, field: string) => {
    const creator = row["creator"].trim() || "N/A";
    const label =
      creator === "N/A"
        ? "NA"
        : creator
            .split(" ")
            .map((word: string) => word[0])
            .join("")
            .toUpperCase();
    return (
      <div className="flex gap-1 align-center">
        <Avatar className={styles.avatar} size={25} label={label} />
        <div>{creator}</div>
      </div>
    );
  };

  const renderPlannedDate = (row: any) => {
    if (row.plannedDate == null || row.plannedTime == null || row.timeZone == null) {
      return "N/A";
    }
    return `${row.plannedDate} ${row.plannedTime} ${row.timeZone}`;
  };

  const onSort = (sortKey: string, sortingPhase: number) => {
    setSortBy(sortKey);

    if (sortingPhase == 0) {
      setSortOrder(1);
      return;
    }
    setSortOrder(sortingPhase === 1 ? 1 : -1);
  };

  const renderNumberOfPlayers = (row: any, field: string) => {
    return row.numberOfPlayers;
  }

  useEffect(() => {
    getGameSessionsQuery.refetch();
  }, [page, limit, sortOrder, sortBy, searchString, status]);

  return (
    <div className="ap-container">
      <div className={styles.gameHeaderContainer}>
        <GameHeader />
        <Button className={`${styles.addSessionBtn} ap-my-spacing-5`} onClick={() => addSession()} icon="icon-plus-outline">
          New session
        </Button>
      </div>
      <div className="row">
        <div className="col-12 flex gap-1 align-center ap-my-spacing-5">
          <Search
            placeholder="Search by name or creator"
            className={styles.searchInput}
            searchValue={searchString}
            onChange={(e) => {
              setSearchString(e);
            }}
          />
          <Select
            data={[
              { value: "", label: "All" },
              { value: "pending", label: "Not started" },
              { value: "started", label: "Active" },
              { value: "finished", label: "Completed" },
            ]}
            value={status}
            onSelect={(e) => setStatus(e)}
            placeholder="Status"
          />
          <Button kind="text" onClick={resetFilters}>
            Reset filters
          </Button>
        </div>
        <div className="col-12">
          <Table
            originalData={sessions}
            hasTitle
            className="data-table"
            onSort={onSort}
          >
            <Column field="name" sortKey="name" renderCell={renderName}>
              Session name
            </Column>
            <Column field="status" sortKey="status" renderCell={renderStatus}>
              Status
            </Column>
            <Column field="creator" sortKey="creator" renderCell={renderCreator}>
              Creator
            </Column>
            <Column field="numberOfPlayers" sortKey="numberOfPlayers" renderCell={renderNumberOfPlayers}>
              Players
            </Column>
            <Column field="plannedDate" sortKey="plannedDate" renderCell={renderPlannedDate}>
              Planned date
            </Column>
            <Column field="_id" renderCell={renderAction}>
              Actions
            </Column>
          </Table>
          {(!sessions || !(sessions.length > 0)) && (
            <div className={`${styles.noSessionContainer}`}>
              <img src="/no-content.png" alt="" />
              <div>No sessions found</div>
            </div>
          )}
        </div>

        {(sessions && sessions.length > 0) && (
          <div className="col-12 flex justify-center ap-my-spacing-6">
            <Pagination
              ref={paginationRef}
              current={page}
              onPageChange={(page) => {
                setPage(page);
              }}
              total={totalPages}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GameSessions;
