import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useGameStore } from "@/store/game-store";
import { useQuery } from "react-query";
import { getGame } from "@/api/game";
import styles from "./Outro.module.scss";
import { durations, OutroPlaceHolderContent } from "@/constants";
import React from "react";

const Outro = () => {
  const navigate = useNavigate();
  const name = useGameStore((state) => state.game.name);
  const status = useGameStore((state) => state.game.status);
  const rooms = useGameStore((state) => state.game.rooms);
    const game = useGameStore((state) => state.game);
  const { setGame } = useGameStore((state) => state);

  const activeRooms = rooms.filter((room) => room.isActive && room.isVisible);

  const loadGame = (gameData: any) => {
    if (gameData == null) {
      return;
    }
    setGame(gameData);
  };
  const gameId = location.pathname.split("/").at(-2);
  const getGameQuery = useQuery({
    queryKey: ["getGame"],
    queryFn: () => getGame(gameId!),
    onSuccess: (res) => {
      loadGame(res.data.data);
    },
  });

  useEffect(() => {
    getGameQuery.refetch();
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSubIndex, setSelectedSubIndex] = useState(0);

  const getGameDuration = (numberOfRooms: number) => {
    const duration = durations.find(
      (d) => numberOfRooms == parseInt(d.rooms.split("")[0])
    );
    if (duration) {
      return duration.name;
    }
    return "";
  };

  const getNumberOfPuzzle = (numberOfRooms: number) => {
    const duration = durations.find(
      (d) => numberOfRooms == parseInt(d.rooms.split("")[0])
    );
    if (duration) {
      const { puzzles } = duration;
      return puzzles;
    }
    return "";
  };

  const renderTextWithLineBreaks = (text: string) => {
      return text.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    };

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={styles.headerContainer}>
          <h1 className="ap-typography ap-font-weight-2 ap-mb-spacing-4">
            Outro
          </h1>
        </div>
        <div className={styles.inlineSpans}></div>
        <p className={`ap-typography-body`} style={{ marginBottom: "20px" }}>
          {OutroPlaceHolderContent.OutroDescription}
        </p>
        <div className={styles.divider}></div>
      </div>
      <h3 className="ap-typography-body ap-font-weight-2">Preview</h3>
      <div className={styles.previewContainer}>
        <h1 className="ap-font-weight-2">{renderTextWithLineBreaks(game.outroSuccessfulMessage)}</h1>
      </div>
    </>
  );
};

export default Outro;
