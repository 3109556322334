import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../RoomObject/BaseRoomObject";
import { SCREEN_WIDTH } from "@/constants";

const WORD_WRAP = 550;
const BACKGROUND_PADDING_Y = 30;

export default class MessageTooltip extends Phaser.GameObjects.Container {
  public readonly  scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly textObject: Phaser.GameObjects.Text;
  private readonly callback: () => void;
  
  constructor(
    scene: UIScene,
    text: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {}
  ) {
    super(scene, sourceObject.x, sourceObject.y);
    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    this.textObject = new Phaser.GameObjects.Text(
      scene, 
      0, 
      0, 
      text, 
      {
        font: "400 24px Arial",
        color: "#000000",
        wordWrap: { width: WORD_WRAP, useAdvancedWrap: true },
        align: "center",
      })
      .setLineSpacing(8)
      .setOrigin(0.5);

    const textWidth = this.textObject.width;
    const textY = -this.sourceObject.sprite.height / 2 - 40;
    
    let textX = 0;

    if (sourceObject.x - textWidth < 0) {
      const moveRightDistance = sourceObject.x - textWidth / 2 - BACKGROUND_PADDING_Y;
      textX -= moveRightDistance;
    }
    else if (sourceObject.x + textWidth > SCREEN_WIDTH) {
      const moveLeftDistance = SCREEN_WIDTH - sourceObject.x - textWidth / 2 - BACKGROUND_PADDING_Y;
      textX += moveLeftDistance;
    }

    this.textObject.setOrigin(0.5);
    this.textObject.setPosition(textX, textY);

    const graphics = new Phaser.GameObjects.Graphics(scene);
    graphics.fillStyle(0xC1C6D4, 0.9);
    graphics.fillRoundedRect(
      this.textObject.getTopLeft().x! - BACKGROUND_PADDING_Y / 2, 
      this.textObject.getTopLeft().y! - BACKGROUND_PADDING_Y / 2, 
      this.textObject.width + BACKGROUND_PADDING_Y, 
      this.textObject.height + BACKGROUND_PADDING_Y, 
      8);

    this.add(graphics);
    this.add(this.textObject);

    scene.add.existing(this);

    this.scene.time.delayedCall(2000, () => {
      this.continue();
    });
  }

  private continue() {
    this.closeModal();
    this.callback();
  }

  private closeModal() {
    this.scene.makeGameSceneInteractive();
    this.destroy();
  }
}
