import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import TVScreen from "../../ui/TVScreen";
import PuzzleBase, { StringStatePayload } from "./PuzzleBase";
import { SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";

export default class TVCode extends PuzzleBase<StringStatePayload> {
  private static readonly HINT_MESSAGE: string =
    "Check 5th of April in the calendar";

  private readonly descriptionTextObject: Phaser.GameObjects.Text;
  private readonly answerIconCorrect: Phaser.GameObjects.Image;
  private readonly answerIconWrong: Phaser.GameObjects.Image;

  private readonly ICON_PADDING: number = 30;
  private readonly VALID_ANSWER: string = "645";
  private readonly INITIAL_TEXT: string = "Enter 3 number password";

  private underlineCodeTextObject!: Phaser.GameObjects.Text;
  private codeTextObject!: Phaser.GameObjects.Text;

  constructor(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    actionId: string,
    callback: () => void = () => {}
  ) {
    super(scene, sourceObject, actionId, callback, TVCode.HINT_MESSAGE);
    
    const lineHeight = 40;

    this.descriptionTextObject = new Phaser.GameObjects.Text(
      scene,
      SCREEN_CENTER_X,
      SCREEN_CENTER_Y - lineHeight * 2,
      this.INITIAL_TEXT,
      this.getCodeFontStyle()
    )
      .setOrigin(0.5)
      .setLineSpacing(8);

    this.add(this.descriptionTextObject);

    this.answerIconCorrect = new Phaser.GameObjects.Image(
      this.scene,
      this.descriptionTextObject.x +
        this.descriptionTextObject.width / 2 +
        this.ICON_PADDING,
      this.descriptionTextObject.y,
      "answer-correct"
    );
    this.answerIconCorrect.visible = false;
    this.add(this.answerIconCorrect);

    this.answerIconWrong = new Phaser.GameObjects.Image(
      this.scene,
      this.descriptionTextObject.x +
        this.descriptionTextObject.width / 2 +
        this.ICON_PADDING,
      this.descriptionTextObject.y,
      "answer-wrong"
    );
    this.answerIconWrong.visible = false;
    this.add(this.answerIconWrong);

    this.initializeCodeTextArea(lineHeight);
    this.addKeyboardInputListener();
    this.scene.add.existing(this);
  }

  protected initializeBackground(): void {
    const tvScreen = new TVScreen(this.scene);
    this.add(tvScreen);
  }

  protected updatePuzzle(currentState: StringStatePayload): void {
    this.updateCodeText(currentState.value);
  }

  protected isPuzzleStatePayloadValid(payload): boolean {
    return (
      typeof payload === "object" &&
      payload !== null &&
      "value" in payload &&
      typeof payload.value === "string"
    );
  }

  private addKeyboardInputListener() {
    this.scene.input.keyboard?.on(Phaser.Input.Keyboard.Events.ANY_KEY_DOWN, this.keydownCallback);
  }

  private updateCodeText(code: string) {
    this.codeTextObject.setText(code);
    this.checkIfComplete();
  }

  private initializeCodeTextArea(lineHeight: number) {
    this.codeTextObject = new Phaser.GameObjects.Text(
      this.scene,
      0,
      SCREEN_CENTER_Y,
      this.VALID_ANSWER,
      this.getCodeFontStyle()
    )
      .setLetterSpacing(30)
      .setLineSpacing(8);

    this.codeTextObject.setX(
      SCREEN_CENTER_X - this.codeTextObject.width / 2
    );
    this.codeTextObject.setText("");
    this.add(this.codeTextObject);

    this.underlineCodeTextObject = new Phaser.GameObjects.Text(
      this.scene,
      SCREEN_CENTER_X,
      SCREEN_CENTER_Y + lineHeight / 3,
      "_".repeat(this.VALID_ANSWER.length),
      this.getCodeFontStyle()
    )
      .setLineSpacing(8)
      .setLetterSpacing(30);

    this.underlineCodeTextObject.setX(
      SCREEN_CENTER_X - this.underlineCodeTextObject.width / 2
    );
    this.add(this.underlineCodeTextObject);
  }

  private getCodeFontStyle(): Phaser.Types.GameObjects.Text.TextStyle {
    return {
      fontFamily: "Arial",
      fontSize: "58px",
      color: "#ffffff",
      align: "center",
    };
  }

  private checkIfComplete() {
    const currentCode = this.codeTextObject.text;

    if (currentCode === this.VALID_ANSWER) {
      this.removeEventHandlers();
      this.updateAnswerIcon(this.answerIconCorrect, "Correct", "#21812D");

      this.completePuzzle();
    } else if (currentCode.length == this.VALID_ANSWER.length) {
      this.removeEventHandlers();
      this.updateAnswerIcon(this.answerIconWrong, "Incorrect", "#C52A1A");

      const timerEvent = this.scene.time.delayedCall(2000, () => {
        this.descriptionTextObject.setText(this.INITIAL_TEXT);
        this.answerIconWrong.visible = false;
        this.codeTextObject.setText("");
        this.underlineCodeTextObject.setColor("white");
        this.addKeyboardInputListener();
        this.timerEvents.pop();
      });

      this.timerEvents.push(timerEvent);
    }
  }

  private updateAnswerIcon(
    icon: Phaser.GameObjects.Image,
    text: string,
    textColor: string
  ) {
    this.descriptionTextObject.setText(text);
    icon.setX(
      this.descriptionTextObject.x +
        this.descriptionTextObject.width / 2 +
        this.ICON_PADDING
    );
    icon.visible = true;
    this.underlineCodeTextObject.setColor(textColor);
  }

  private keydownCallback = (e: KeyboardEvent) => {
    const keyValue = parseInt(e.key);

    if (!isNaN(keyValue)) {
      const currentText = this.codeTextObject.text;

      if (currentText.length >= this.VALID_ANSWER.length) {
        return;
      }

      const code = currentText + keyValue.toString();
      const puzzleStatePayload = { value: code };
      this.emitPuzzleStateChanged(puzzleStatePayload);
    }
  };

  protected removeEventHandlers(): void {
    this.scene.input.keyboard?.removeListener(Phaser.Input.Keyboard.Events.ANY_KEY_DOWN, this.keydownCallback);
  }
}
