import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../../RoomObject/BaseRoomObject";
import { Colors, SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";
import { Status, StatusCodeNotApprovedKeyNotFound } from "./Status";
import { PhaserGameClass } from "@/components/phaser-game/PhaserGame";
import { PreviewPhaserGame } from "@/components/phaser-game/PreviewGame";
import { IRoomObject } from "escape-rooms-types/types/game";
import EventBridge from "@/utils/EventBridge";
import Keyhooks from "../Keyhooks";
import PuzzleBase, { StringStatePayload } from "../PuzzleBase";

export default class KeypadWithFob extends PuzzleBase<StringStatePayload> {
  private static readonly HINT_MESSAGE: string =
    "You might need to solve the TV pass code first to find what you need!";
  private static readonly USE_KEY_MESSAGE = "KEY_USED";
  private static instance: KeypadWithFob;

  private readonly buttonTextControls: Array<Phaser.GameObjects.Text> = [];
  private readonly buttonGraphics: Array<Phaser.GameObjects.Graphics> = [];
  private readonly keyImage: Phaser.GameObjects.Image;
  private readonly INITIAL_BUTTON_COLOR = 0x000000;
  private readonly ALTERNATE_BUTTON_COLOR = 0x7288a2;

  private codeTextObject!: Phaser.GameObjects.Text;
  private messageTextObject!: Phaser.GameObjects.Text;
  private textBackground!: Phaser.GameObjects.Graphics;

  private readonly keys = [5, 9, 3, 8, 9, 5, 4, 2, 6];

  private readonly VALID_ANSWER: string = "596";

  private readonly WIDTH = 422;
  private readonly HEIGHT = 625;

  private codeApproved = false;
  private fobApplied = false;

  public keyAvailableInInventory = false;

  private currentStatus: Status = new StatusCodeNotApprovedKeyNotFound();

  private readonly gridConfiguration = {
    x: 0,
    y: 0,
    paddingX: 40,
    paddingY: 40,
    cardWidth: 70,
    cardHeight: 70,
    numberOfColumns: 3,
    buttonRadius: 5,
    includeZero: false,
    buttonTextFontStyle: {
      fontFamily: "Arial",
      fontSize: "36px",
      color: "#ffffff",
      align: "center",
      fontStyle: "normal",
    },
    buttonTextAlternateColor: "#000000",
  };

  private readonly numbersDisplayConfiguration = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    radius: 4,
    backgroundColor: {
      default: 0x000000,
      correct: "green",
      incorrect: "red",
    },
    fontStyle: {
      fontFamily: "Arial",
      fontSize: "24px",
      color: "#ffffff",
      align: "left",
      fontStyle: "bold",
    },
    letterSpacing: 15,
  };

  constructor(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    actionId: string,
    callback: () => void = () => {}
  ) {
    super(scene, sourceObject, actionId, callback, KeypadWithFob.HINT_MESSAGE);

    this.gridConfiguration.x = SCREEN_CENTER_X - 145;
    this.gridConfiguration.y = SCREEN_CENTER_Y - 18;

    this.numbersDisplayConfiguration.x = this.gridConfiguration.x;
    this.numbersDisplayConfiguration.y = this.gridConfiguration.y - 132;
    this.numbersDisplayConfiguration.width = 290;
    this.numbersDisplayConfiguration.height = 84;

    const keypadBackground = new Phaser.GameObjects.Graphics(this.scene);
    keypadBackground.fillStyle(0xffffff, 1);
    keypadBackground.fillRoundedRect(
      SCREEN_CENTER_X - this.WIDTH / 2,
      SCREEN_CENTER_Y - this.HEIGHT / 2,
      this.WIDTH,
      this.HEIGHT,
      20
    );

    this.add(keypadBackground);

    this.keyImage = new Phaser.GameObjects.Image(
      this.scene,
      SCREEN_CENTER_X,
      SCREEN_CENTER_Y - 222,
      "key-black"
    );
    this.add(this.keyImage);

    this.initializeTextArea();
    this.initializeButtons();

    const env = (this.scene.game as PhaserGameClass | PreviewPhaserGame).env;
    EventBridge.on(`${env}.setUsingItem`, this.onInventoryItemUse);

    this.scene.add.existing(this);
  }

  public static getInstance(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    actionId: string,
    callback: () => void = () => {}
  ): KeypadWithFob {
    if (!this.instance) {
      this.instance = new KeypadWithFob(
        scene,
        sourceObject,
        actionId,
        callback
      );
    }

    this.instance.codeTextObject.text = "";

    return this.instance;
  }

  public setVisible(value: boolean): this {
    if (value) {
      this.updateStatus();
      this.codeTextObject.setVisible(false);
      this.messageTextObject.setVisible(true);
      this.messageTextObject.text = this.currentStatus.getMessage();
      this.setTextBackgroundColor(ButtonColors.Black);

      if (this.codeApproved) {
        this.buttonGraphics.forEach((b) => b.disableInteractive());
        this.buttonTextControls.forEach((b) => b.disableInteractive());
      }
    }

    return super.setVisible(value);
  }

  protected removeEventHandlers(): void {}

  protected updatePuzzle(currentState: StringStatePayload): void {
    if (currentState && currentState.value === KeypadWithFob.USE_KEY_MESSAGE) {
      if (!this.fobApplied) {
        this.keyImage.setTexture("key-green");
        this.codeTextObject.setText("").setVisible(false);
        this.fobApplied = true;
        this.messageTextObject.setText("Fob recognised").setVisible(true);
        this.currentStatus = this.currentStatus.keyUsed();

        this.refreshMessage();
        this.checkIfComplete();
      }
    } else {
      this.updateCodeText(currentState.value);
    }
  }

  protected onCloseButtonClick(): void {
    this.setVisible(false);
    this.scene.hud.setHintButtonVisible(false);
  }

  protected isPuzzleStatePayloadValid(payload): boolean {
    return (
      typeof payload === "object" &&
      payload !== null &&
      "value" in payload &&
      typeof payload.value === "string"
    );
  }

  private updateStatus() {
    this.hintBar.hideMessage();
    this.hintBar.setMessage(this.currentStatus.getHint());
    this.messageTextObject
      .setVisible(true)
      .setText(this.currentStatus.getMessage());
    this.codeTextObject.setText("");

    if (this.codeApproved && this.fobApplied) {
      this.setTextBackgroundColor(Colors.Green);
    } else {
      this.setTextBackgroundColor(Colors.Black);
    }
  }

  private onInventoryItemUse = (roomObject: IRoomObject) => {
    if (!this.visible) {
      return;
    }

    if (roomObject.name === Keyhooks.INVENTORY_ITEM_NAME) {
      const puzzleStatePayload = { value: KeypadWithFob.USE_KEY_MESSAGE };
      this.emitPuzzleStateChanged(puzzleStatePayload);
    }
  };

  private updateCodeText(number: string) {
    if (this.codeApproved) {
      return;
    }

    this.messageTextObject.setVisible(false);
    this.codeTextObject.setVisible(true);

    this.codeTextObject.setText(number);
    this.checkIfComplete();
  }

  private initializeTextArea() {
    this.textBackground = new Phaser.GameObjects.Graphics(this.scene);
    this.textBackground.fillStyle(
      this.numbersDisplayConfiguration.backgroundColor.default,
      1
    );

    this.textBackground.fillRoundedRect(
      this.numbersDisplayConfiguration.x,
      this.numbersDisplayConfiguration.y,
      this.numbersDisplayConfiguration.width,
      this.numbersDisplayConfiguration.height,
      this.numbersDisplayConfiguration.radius
    );

    this.add(this.textBackground);

    const x = this.numbersDisplayConfiguration.x;
    const y =
      this.numbersDisplayConfiguration.y +
      this.numbersDisplayConfiguration.height / 2;

    this.codeTextObject = new Phaser.GameObjects.Text(
      this.scene,
      x,
      y - 20,
      this.VALID_ANSWER,
      this.numbersDisplayConfiguration.fontStyle
    ).setLetterSpacing(this.numbersDisplayConfiguration.letterSpacing);

    this.codeTextObject.setX(
      this.scene.scale.width / 2 - this.codeTextObject.width / 2
    );
    this.codeTextObject.setText("");
    this.add(this.codeTextObject);

    this.messageTextObject = new Phaser.GameObjects.Text(
      this.scene,
      x,
      y,
      "Input code and tap fob",
      this.numbersDisplayConfiguration.fontStyle
    ).setOrigin(0.5);

    this.messageTextObject.setX(
      this.scene.scale.width / 2 - this.codeTextObject.width / 2
    );
    this.add(this.messageTextObject);
  }

  private initializeButtons() {
    for (let i = 0; i < this.keys.length; i++) {
      const x =
        this.gridConfiguration.x +
        (this.gridConfiguration.cardWidth + this.gridConfiguration.paddingX) *
          (i % this.gridConfiguration.numberOfColumns);
      const y =
        this.gridConfiguration.y +
        (this.gridConfiguration.cardHeight + this.gridConfiguration.paddingY) *
          Math.floor(i / this.gridConfiguration.numberOfColumns);
      const currentNumber = this.keys[i];

      const buttonBackground = new Phaser.GameObjects.Graphics(this.scene);

      buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
      buttonBackground.fillRoundedRect(
        x,
        y,
        this.gridConfiguration.cardWidth,
        this.gridConfiguration.cardHeight,
        5
      );
      buttonBackground.setInteractive(
        new Phaser.Geom.Rectangle(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight
        ),
        Phaser.Geom.Rectangle.Contains
      );
      this.buttonGraphics.push(buttonBackground);

      const buttonText = new Phaser.GameObjects.Text(
        this.scene,
        x + this.gridConfiguration.cardWidth / 2,
        y + this.gridConfiguration.cardHeight / 2,
        currentNumber.toString(),
        this.gridConfiguration.buttonTextFontStyle
      )
        .setOrigin(0.5)
        .setName(currentNumber.toString())
        .setInteractive();

      this.buttonTextControls.push(buttonText);

      buttonText.on(Phaser.Input.Events.POINTER_DOWN, () => {
        this.onClickCallback(buttonText.name);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_DOWN, () => {
        this.onClickCallback(buttonText.name);
      });

      buttonText.on(Phaser.Input.Events.POINTER_OVER, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.ALTERNATE_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextAlternateColor);
      });

      buttonText.on(Phaser.Input.Events.POINTER_OUT, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextFontStyle.color);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_OVER, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.ALTERNATE_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextAlternateColor);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_OUT, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextFontStyle.color);
      });
    }

    this.add(this.buttonGraphics);
    this.add(this.buttonTextControls);
  }

  private onClickCallback(value: string) {
    const currentText = this.codeTextObject.text;

    if (currentText.length >= this.VALID_ANSWER.length) {
      return;
    }

    const code = currentText + value.toString();
    const puzzleStatePayload = { value: code };
    this.emitPuzzleStateChanged(puzzleStatePayload);
  }

  private checkIfComplete() {
    const currentCode = this.codeTextObject.text;

    if (!this.codeApproved) {
      if (currentCode === this.VALID_ANSWER) {
        this.setTextBackgroundColor(Colors.Green);
        this.codeTextObject.setText("").setVisible(false);
        this.messageTextObject.setText("Code approved").setVisible(true);
        this.codeApproved = true;
        this.currentStatus = this.currentStatus.codeApproved();
        this.refreshMessage();
      } else if (currentCode.length == this.VALID_ANSWER.length) {
        this.setTextBackgroundColor(Colors.Red);
        this.codeTextObject.setVisible(false);
        this.messageTextObject.setText("Code incorrect").setVisible(true);
        this.refreshMessage();
      }
    }

    if (this.codeApproved && this.fobApplied) {
      this.closeButton.setVisible(false);
      this.refreshMessage();
      this.completePuzzle();
    }
  }

  private refreshMessage() {
    const timerEvent = this.scene.time.delayedCall(2000, () => {
      this.updateStatus();
    });

    this.timerEvents.push(timerEvent);
  }

  private setTextBackgroundColor(color: number) {
    this.textBackground.fillStyle(color, 1);
    this.textBackground.fillRoundedRect(
      this.numbersDisplayConfiguration.x,
      this.numbersDisplayConfiguration.y,
      this.numbersDisplayConfiguration.width,
      this.numbersDisplayConfiguration.height,
      this.numbersDisplayConfiguration.radius
    );
  }
}
