import { HEADER_HEIGHT } from "@/constants";
import UIScene from "@/phaser/scenes/UIScene";
import EventBridge from "@/utils/EventBridge";

export default class HintButton extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly hintButton: Phaser.GameObjects.Image;
  private readonly tooltipBackground: Phaser.GameObjects.Graphics;
  private readonly tooltipText: Phaser.GameObjects.Text;

  constructor(
    scene: UIScene
  ) {
    super(scene, 0, 0);

    this.scene = scene;
    
    this.hintButton = new Phaser.GameObjects.Image(
      scene,
      scene.renderer.width - 300,
      HEADER_HEIGHT / 2,
      "hint-button"
    )
      .setOrigin(0.5)
      .setInteractive()
      .on(Phaser.Input.Events.POINTER_DOWN, () => {
        if (this.scene.env === "preview") {
          EventBridge.emit("ui.hintUsed");
          EventBridge.emit("ui.addToTeamScore", -10);
        } else {
          EventBridge.emit("game.useHint");
        }
      })
      .on(Phaser.Input.Events.POINTER_OVER, () => {
        this.tooltipBackground.setVisible(true);
        this.tooltipText.setVisible(true);
      })
      .on(Phaser.Input.Events.POINTER_OUT, () => {
        this.tooltipBackground.setVisible(false);
        this.tooltipText.setVisible(false);
      });
      
      this.add(this.hintButton);

      this.tooltipText = new Phaser.GameObjects.Text(
        this.scene,
        this.hintButton.getBottomCenter().x!,
        this.hintButton.getBottomCenter().y! + 23,
        "Beware, hints cost points!",
        {
            fontFamily: "Arial",
            fontSize: "14px",
            color: "#000000",
            align: "center",
        }).setOrigin(0.5, 0).setVisible(false);

      
      const tooltipPadding = 8;

      this.tooltipBackground = new Phaser.GameObjects.Graphics(this.scene);
      this.tooltipBackground.fillStyle(0xBFC1C7, 1);
      this.tooltipBackground.fillRoundedRect(
        this.tooltipText.getTopLeft().x! - tooltipPadding, 
        this.tooltipText.getTopLeft().y! - tooltipPadding,
        this.tooltipText.width + 2 * tooltipPadding,
        this.tooltipText.height + 2 * tooltipPadding,
        5
      ).setVisible(false);
      
      this.add(this.tooltipBackground);
      this.add(this.tooltipText);
  }
}