import {
  Button,
  CalendarPicker,
  Column,
  Input,
  Modal,
  Panel,
  Select,
  Table,
  TextArea,
  TimePicker,
} from "@appkit4/react-components";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./SessionSettings.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useEffect, useMemo, useState } from "react";
import {
  createGameSession,
  deleteGameSession,
  getGameSession,
  startSession,
  updateGameSession,
} from "@/api/session";
import { getGames } from "@/api/game";
import { IGame } from "escape-rooms-types/types/game";
import { IGameSession } from "escape-rooms-types/types/gameSession";
import { useGameStore } from "@/store/game-store";
import {
  deleteUserFromSession,
  inviteUserToSession,
  resendPlatformInvite,
} from "@/api/auth";
import { renderNotification } from "../notification/renderFunctions";
import { addToClipboard, getTimezones, getUserTimezone } from "@/utils/helpers";
import {
  IGameSessionData,
  prepareGameSessionForAPI,
  prepareGameSessionForUI,
} from "@/models/GameSessionData";

const SessionSettings = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { gameId, sessionId } = useParams();
  const { session, setSession } = useGameStore();
  const [draft, setDraft] = useState<IGameSessionData>(session!);
  const [games, setGames] = useState<Array<IGame>>([]);
  const [inviteEmail, setInviteEmail] = useState<string>("");
  const [inviteEmailError, setInviteEmailError] = useState<string | null>(null);
  const [inviteTableData, setInviteTableData] = useState<Array<any>>([]);
  const [showDeleteGameSessionModal, setShowDeleteGameSessionModal] =
    useState(false);
  const [sessionIdToDelete, setSessionIdToDelete] = useState<string | null>(
    null
  );
  const [showDeleteSessionUserModal, setShowDeleteSessionUserModal] =
    useState(false);
  const [playerIdToDelete, setPlayerIdToDelete] = useState<string | null>(null);
  const [sessionNameError, setSessionNameError] = useState<string | undefined>(
    undefined
  );
  const [gameIdError, setGameIdError] = useState<string | undefined>(undefined);

  const getGamesQuery = useQuery({
    queryKey: "getGames",
    queryFn: () =>
      getGames(undefined, undefined, undefined, undefined, "", "", "", ""),
    onSuccess: (res) => {
      if (res?.data?.data !== undefined) {
        setGames(res.data.data);
      }
    },
  });

  const updateStateWithGameSessionData = (res: any, fn?: (res: any) => any) => {
    if (res?.data?.data !== undefined) {
      const data = prepareGameSessionForUI(res.data.data);
      setSession(data);
      setDraft(data);
      if (fn !== undefined) {
        fn(res);
      }
    }
  };

  const getGameSessionQuery = useQuery({
    queryKey: "getGameSession",
    queryFn: () => getGameSession(sessionId!),
    onSuccess: (res) => updateStateWithGameSessionData(res),
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const saveGameSessionMutation = useMutation({
    mutationKey: "saveGameSession",
    mutationFn: () => {
      const data = prepareGameSessionForAPI(draft);
      return updateGameSession(sessionId!, data);
    },
    onSuccess: (res) =>
      updateStateWithGameSessionData(res, (res) => {
        renderNotification("Session saved successfully.", "success");
      }),
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const linkGameToSessionMutation = useMutation({
    mutationKey: "linkGameToSession",
    mutationFn: () => updateGameSession(sessionId!, { gameId: draft?.gameId }),
    onSuccess: (res) => updateStateWithGameSessionData(res),
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const inviteUserToGameSession = useMutation({
    mutationKey: "inviteUserToSession",
    mutationFn: (email: string) => inviteUserToSession(sessionId!, email),
    onSuccess: (res) => {
      queryClient.invalidateQueries("getGameSession");
      renderNotification("Player invited to session.", "success");
      setInviteEmail("");
    },
    onError: (err) => {
      setInviteEmailError(err.response.data.message);
      renderNotification(err.message, "error");
    },
  });

  const resendPlayerInvite = useMutation({
    mutationKey: "resendPlayerInvite",
    mutationFn: (email: string) => resendPlatformInvite(email),
    onSuccess: (res) => {
      renderNotification("Invitation has been sent to user.", "success");
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const deleteUserFromGameSession = useMutation({
    mutationKey: "deleteUserFromGameSession",
    mutationFn: (playerId: string) =>
      deleteUserFromSession(sessionId!, playerId),
    onSuccess: (res) => {
      queryClient.invalidateQueries("getGameSession");
      renderNotification("Player removed from session.", "success");
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const startGameSession = useMutation({
    mutationKey: "startGameSession",
    mutationFn: () => startSession(sessionId!),
    onSuccess: (res) => {
      queryClient.invalidateQueries("getGameSession");
      renderNotification("Session started", "success");
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const addAllEmailsToClipboard = () => {
    const emails = session!.players.map((player) => player.email);
    addToClipboard(emails?.join("\n"), () =>
      renderNotification("Added to clipboard", "success")
    );
  };

  const deleteGameSessionQuery = useQuery({
    queryKey: ["deleteGameSessions"],
    queryFn: () => deleteGameSession(sessionIdToDelete!),
    onSuccess: (res) => {
      navigate("/sessions");
    },
    enabled: false,
  });

  const handleDeleteGameSession = () => {
    if (!sessionIdToDelete) {
      return;
    }

    setTimeout(() => {
      deleteGameSessionQuery.refetch();
    }, 500);
    setShowDeleteGameSessionModal(false);
  };

  const handleDeleteSessionUser = () => {
    if (!playerIdToDelete) {
      return;
    }

    setTimeout(() => {
      deleteUserFromGameSession.mutate(playerIdToDelete);
    }, 500);
    setShowDeleteSessionUserModal(false);
  };

  const renderInput = (
    title: string,
    onChange: (e) => void,
    value: string,
    error: string | undefined,
    disabled: boolean = false,
    maxLength: number | undefined = undefined
  ) => {
    return (
      <Input
        type={"text"}
        title={title}
        required={true}
        value={value?.trim()}
        maxLength={maxLength}
        onChange={onChange}
        disabled={disabled}
        error={error}
        onKeyDown={(event: any) => {
          if (event.code === "Space") event.preventDefault();
        }}
        errorNode={
          <div
            id="errormessage"
            aria-live="polite"
            className={`ap-field-email-validation-error ${styles.error}`}
          >
            {error}
          </div>
        }
      ></Input>
    );
  };

  const renderSelect = (
    data: { value: string; label: string }[],
    value: string | undefined,
    onSelect: (e) => void,
    error: string | undefined,
    placeholder: string = "Game",
    noResultFound: string = "No published games found"
  ) => {
    return (
      <Select
        data={data}
        required={true}
        value={value}
        onSelect={onSelect}
        searchable
        placeholder={placeholder}
        noResultFound={noResultFound}
        error={error}
      ></Select>
    );
  };

  useEffect(() => {
    if (session == null) return;
    if (session.players == null) return;
    setInviteTableData(session?.players.map((player) => ({ ...player })));
  }, [session?.players]);

  const handleKeyDown = (event: Event) => {
    if (event.code === "Enter") {
      inviteUserToGameSession.mutate(inviteEmail);
      event.currentTarget.blur();
    }
  };

  const renderActions = (row: any, field: string) => {
    return (
      <div className="flex gap-1 justify-center">
        <i
          className="Appkit4-icon icon-delete-outline btn"
          onClick={() => {
            setPlayerIdToDelete(row._id);
            setShowDeleteSessionUserModal(true);
          }}
        ></i>
      </div>
    );
  };
  const renderStatus = (row: any, field: string) => {
    const statusString =
      row["status"].charAt(0).toUpperCase() + row["status"].slice(1);
    return (
      <div className="flex align-center gap-1">
        <div
          className={`circle ${
            row.status.toLowerCase() === "pending"
              ? "ap-bg-color-background-warning"
              : "ap-bg-color-background-success"
          }`}
        ></div>
        <span>{statusString}</span>
      </div>
    );
  };

  const linkGame = () => {
    if (!session) return;
    setSession({ ...session, gameId: draft?.gameId! });
  };

  const openGameSession = (sessionId: string) => {
    return navigate(`/sessions/${sessionId}`);
  };

  const addGameSessionQuery = useQuery({
    queryKey: ["createGameSessions"],
    queryFn: () => createGameSession({ gameId: gameId! }),
    onSuccess: (res) => {
      // getGameSessionsQuery.refetch();
      openGameSession(res.data.data._id);
    },
    enabled: false,
  });

  const gamesData = useMemo(() => {
    const publishedGames = games
      .filter((game) => game.status === "published")
      .sort((g1: IGame, g2: IGame) => {
        if (g1.name > g2.name) {
          return 1;
        }

        if (g1.name < g2.name) {
          return -1;
        }

        return 0;
      });
    return publishedGames.map((game) => ({
      value: game._id,
      label: game.name,
    }));
  }, [games]);

  const handleSave = () => {
    if (draft?.name.length < 1 || draft?.gameId.length < 1) {
      if (draft?.name.length < 1) {
        setSessionNameError("Please enter a session name");
      }
      if (draft?.gameId.length < 1) {
        setGameIdError("Please make a selection");
      }
      return;
    }
    saveGameSessionMutation.mutate();
  };

  const checkIfChanged = () => {
    return JSON.stringify(session) !== JSON.stringify(draft);
  };

  return (
    <div className="ap-container">
      <div className="ap-my-spacing-5">
        <Button
          className={styles.backButton}
          kind="text"
          icon="icon-left-chevron-outline"
          onClick={() => navigate("/sessions")}
        >
          <span className="ap-font-16">Back to session list</span>
        </Button>
      </div>
      <div className={styles.header}>
        <h1 className={styles.heading}>{session?.name}</h1>
        <div className="flex gap-1">
          <Button
            kind="text"
            icon="icon-delete-outline"
            onClick={() => {
              setSessionIdToDelete(session?._id);
              setShowDeleteGameSessionModal(true);
            }}
          >
            Delete session
          </Button>
          <Button
            kind="primary"
            icon="icon-save-outline"
            onClick={() => handleSave()}
            disabled={!checkIfChanged()}
          >
            Save changes
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-5">
            <div className="row ap-my-spacing-5">
              <div className="col-6">
                <h2 className={styles.sectionHeading}>Basic information</h2>
              </div>
            </div>
            <div className="row ap-my-spacing-5">
              <div className="col-12">
                {renderInput(
                  "Name of session",
                  (e) => {
                    setDraft({ ...draft!, name: e });
                    setSessionNameError(undefined);
                  },
                  draft?.name,
                  sessionNameError,
                  false,
                  180
                )}
              </div>
            </div>
            <div className="row ap-my-spacing-5">
              <div className="col-12">
                {renderSelect(
                  gamesData,
                  draft?.gameId,
                  (e) => {
                    setDraft({ ...draft!, gameId: e });
                    setGameIdError(undefined);
                  },
                  gameIdError
                )}
                <div className={`ap-field-content`}>
                  {gameIdError && (
                    <div
                      id="errormessage"
                      aria-live="polite"
                      className={`ap-field-email-validation-error ${styles.error}`}
                    >
                      {gameIdError}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row ap-my-spacing-6">
              <div className="col-12">
                <TextArea
                  maxLength={420}
                  value={draft?.description}
                  onChange={(e) => {
                    setDraft({ ...draft!, description: e });
                  }}
                  title="Additional details"
                  disabled={session?.status === "completed"}
                />
              </div>
            </div>
          </Panel>
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-5">
            <div className="row ap-my-spacing-5">
              <div className="col-6">
                <h2 className={styles.sectionHeading}>Scheduling</h2>
              </div>
            </div>
            <div className="row gap-2 ap-my-spacing-5">
              <div className="col-12 flex align-center">
                <div className="timepicker-container w-full">
                  <CalendarPicker
                    fieldTitle="Planned date"
                    locale="en"
                    value={draft?.plannedDateEdit}
                    minDate={new Date()}
                    fieldWidth="100%"
                    onChange={(e, isValid: boolean) => {
                      if (isValid) {
                        setDraft({ ...draft!, plannedDateEdit: e });
                      }
                    }}
                    disabled={session?.status === "completed"}
                  />
                </div>
              </div>
              <div className="col-12 flex align-center gap-2">
                <div className={`${styles.w65} timepicker-container`}>
                  <TimePicker
                    className={styles.timePicker}
                    value={draft?.plannedTimeEdit}
                    hourTime={12}
                    onChange={(e) =>
                      setDraft({ ...draft!, plannedTimeEdit: e })
                    }
                    fieldTitle="Planned time"
                    disabled={session?.status === "completed"}
                  />
                </div>
                <Select
                  data={getTimezones()}
                  value={draft?.timeZone || getUserTimezone()}
                  onSelect={(e) => {
                    setDraft({ ...draft!, timeZone: e });
                  }}
                  placeholder="Time zone"
                  disabled={session?.status === "completed"}
                  searchable
                  noResultFound="No timezones found"
                />
              </div>
            </div>
          </Panel>
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-5">
            <div className="row ap-my-spacing-5">
              <div className="col-6">
                <h2 className={styles.sectionHeading}>Links</h2>
              </div>
            </div>
            <div className="row gap-2 ap-my-spacing-5">
              <div className="col-12 align-center">
                <div>
                  <Input
                    type="text"
                    maxLength={100}
                    className="ap-mb-spacing-4"
                    value={draft?.googleMeetLink}
                    onChange={(e) => setDraft({ ...draft!, googleMeetLink: e })}
                    title="Google Meet link"
                    disabled={session?.status === "completed"}
                    suffix={
                      <i
                        className="Appkit4-icon icon-copy-outline ml-auto ap-mr-spacing-3 btn"
                        onClick={() =>
                          addToClipboard(draft?.googleMeetLink, () =>
                            renderNotification("Added to clipboard", "success")
                          )
                        }
                      />
                    }
                  />
                </div>
                <div className={styles.inputField}>
                  <p className="inline w-full word-break">
                    {`${window.location.origin}/sessions/${session?._id}/play`}
                  </p>
                  <i
                    className="Appkit4-icon icon-copy-outline ml-auto btn"
                    onClick={() =>
                      addToClipboard(
                        `${window.location.origin}/sessions/${session?._id}/play`,
                        () =>
                          renderNotification("Added to clipboard", "success")
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </Panel>
        </div>
        <div className="col-6">
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-5">
            <div className="row ap-my-spacing-5">
              <h2 className={styles.sectionHeading}>Manage players</h2>
              <p className="ap-font-weight-2 ap-mb-spacing-2">
                Add new players to the list
              </p>
              <p className={styles.paragraphText}>
                We recommend a maximum of 5 players to ensure a good game
                dynamic
              </p>
            </div>
            <div
              className="flex align-start mt-2 ap-mb-spacing-7 gap-2"
              style={{ alignItems: "center" }}
            >
              <Input
                className="col-8"
                type={"text"}
                title={"Type emails"}
                error={inviteEmailError}
                errorNode={
                  <div
                    id="errormessage"
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    {inviteEmailError}
                  </div>
                }
                value={inviteEmail}
                onChange={(e) => {
                  setInviteEmail(e);
                  setInviteEmailError(null);
                }}
                disabled={session?.status === "completed"}
                onKeyDown={(e) => handleKeyDown(e)}
              ></Input>
              <Button
                style={{ width: "100%", height: "100%" }}
                icon="icon-add-user-outline"
                kind="secondary"
                disabled={
                  inviteEmail.length < 1 || session?.status === "completed"
                }
                onClick={() => inviteUserToGameSession.mutate(inviteEmail)}
              >
                Add player
              </Button>
            </div>
            <div className={styles.tableHeader}>
              <p className="ap-font-weight-2">Players</p>
              <div className="">
                <Button
                  kind="text"
                  className="ml-auto ap-my-spacing-2"
                  icon="icon-copy-outline"
                  onClick={() => addAllEmailsToClipboard()}
                >
                  Copy emails
                </Button>
              </div>
            </div>
            <Table
              originalData={[...inviteTableData]}
              hasTitle
              className={`data-table ${styles.playerTable}`}
              style={{ padding: 0 }}
            >
              <Column field="name">Name</Column>
              <Column field="email">Email</Column>
              <Column field="status" renderCell={renderStatus}>
                Status
              </Column>
              <Column field="name" renderCell={renderActions}>
                Actions
              </Column>
            </Table>
            {session?.players.length === 0 && (
              <div className={`${styles.noSessionContainer}`}>
                <img src="/no-content.png" alt="" />
                <div>No players added yet</div>
              </div>
            )}
          </Panel>
        </div>
      </div>
      <Modal
        visible={showDeleteGameSessionModal}
        title={intl.formatMessage({ id: "session.delete.modal.title" })}
        ariaLabel={intl.formatMessage({ id: "session.delete.modal.title" })}
        onCancel={() => {
          setSessionIdToDelete(null);
          setShowDeleteGameSessionModal(false);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button kind="secondary" onClick={() => handleDeleteGameSession()}>
              <FormattedMessage id="session.action.delete.modal.confirm" />
            </Button>
            <Button
              onClick={() => {
                setSessionIdToDelete(null);
                setShowDeleteGameSessionModal(false);
              }}
            >
              <FormattedMessage id="session.action.delete.modal.cancel" />
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="session.delete.modal.description" />
        </p>
      </Modal>
      <Modal
        visible={showDeleteSessionUserModal}
        title={intl.formatMessage({ id: "player.delete.modal.title" })}
        ariaLabel={intl.formatMessage({ id: "player.delete.modal.title" })}
        onCancel={() => {
          setPlayerIdToDelete(null);
          setShowDeleteSessionUserModal(false);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button kind="secondary" onClick={() => handleDeleteSessionUser()}>
              <FormattedMessage id="player.delete.modal.confirm" />
            </Button>
            <Button
              onClick={() => {
                setPlayerIdToDelete(null);
                setShowDeleteSessionUserModal(false);
              }}
            >
              <FormattedMessage id="player.delete.modal.cancel" />
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="player.delete.modal.description" />
        </p>
      </Modal>
    </div>
  );
};

export default SessionSettings;
