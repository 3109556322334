import {
  Button,
  Input,
  Tag,
  Table,
  Column,
  TextArea,
  Tooltip,
  Panel,
  Switch,
  Upload,
  Select,
  Modal,
} from "@appkit4/react-components";
import { useNavigate } from "react-router-dom";
import styles from "./GameSettings2.module.scss";
import React, { useState, useEffect, ReactElement } from "react";
import { useGameStore } from "@/store/game-store";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteGame, getGame, updateGame } from "@/api/game";
import GameTabs from "../game-tabs/GameTabs";
import GameMission from "../game-mission/GameMission";
import svg1 from "../../../public/asset-state-textures/fs-room1-background_default.svg";
import svg2 from "../../../public/asset-state-textures/fs-room2-background_default_light_on.svg";
import svg3 from "../../../public/asset-state-textures/fs-room3-background_default.svg";
import { useModal } from "../game-customisation-template-pages/UnsavedChangesContext";
import { IGame } from "escape-rooms-types/types/game";
import { industries } from "@/constants";
import { FormattedMessage, useIntl } from "react-intl";
import { getGameSessionCountByGameId } from "@/api/session";
import { renderNotification } from "../notification/renderFunctions";
import GameHeader from "../game-header/GameHeader";

const GameSettings2 = () => {
  const navigate = useNavigate();
  const modalContext = useModal();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const name = useGameStore((state) => state.game.name);
  const status = useGameStore((state) => state.game.status);
  const rooms = useGameStore((state) => state.game.rooms);
  const game = useGameStore((state) => state.game);
  const { setGame, setGameField } = useGameStore((state) => state);
  const [titleValue, setTitleValue] = useState("");
  const [industryValue, setIndustryValue] = useState("");
  const [clientValue, setClientValue] = useState("");
  const [descriptionValue, setAdditionalDetails] = useState("");
  const [showDeleteGameModal, setShowDeleteGameModal] = useState<boolean>(false);
  const [showDeleteGameConfirmationModal, setShowDeleteGameConfirmationModal] = useState<boolean>(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [targetGame, setTargetGame] = useState<IGame | undefined>(undefined);
  const [targetGameSessionCount, setTargetGameSessionCount] = useState(0);
  const published = game.status === "published";

  const [initialTitleValue, setInitialTitleValue] = useState("");
  const [initialIndustryValue, setInitialIndustryValue] = useState("");
  const [initialClientValue, setInitialClientValue] = useState("");
  const [initialAdditionalDetails, setInitialAdditionalDetails] = useState("");

  useEffect(() => {
    if (game) {
      setInitialTitleValue(game.name || "");
      setInitialIndustryValue(game.industry || "");
      setInitialClientValue(game.client || "");
      setInitialAdditionalDetails(game.description || "");
    }
  }, [game]);

  const isDirty =
    titleValue !== initialTitleValue ||
    industryValue !== initialIndustryValue ||
    clientValue !== initialClientValue ||
    descriptionValue !== initialAdditionalDetails;

  const tdata: Array<any> = [
    {
      name: game?.creator || "",
      email: game?.creatorEmail || "",
      actions: "",
    },
  ];

  const [originalData, setOriginalData] = React.useState(tdata);

  const renderTotalCell = (row: any, field: string) => {
    if (!(field in row)) return "";

    return `${row[field]}`;
  };

  const onUploadChange = (file: File, fileList: FileList): void => {
    console.log("onChange", file, fileList);
    //   modalContext?.setIsChanged(true);
    //   console.log('game editor is changed: ', modalContext?.isChanged)
  };
  const uploadFiles = (fileList: any) => {
    console.log(fileList);
  };

  const onAdditionalDetailsChange = (value: any, event: any) => {
    setAdditionalDetails(value);
  };

  const onTitleChange = (value: any, event: any) => {
    setTitleValue(value);
  };

  const onIndustryChange = (value: any, event: any) => {
    setIndustryValue(value);
  };

  const onClientChange = (value: any, event: any) => {
    setClientValue(value);
  };

  const loadGame = (gameData: any) => {
    if (gameData == null) {
      return;
    }
    setGame(gameData);

    setTitleValue(gameData.name);
    setIndustryValue(gameData.industry);
    setClientValue(gameData.client);
    setAdditionalDetails(gameData.description);

  };
  const gameId = location.pathname.split("/").at(-2);
  const getGameQuery = useQuery({
    queryKey: ["getGame"],
    queryFn: () => getGame(gameId!),
    onSuccess: (res) => {
      loadGame(res.data.data);
    },
  });

  const deletePreConfirmationGameMutation = useMutation({
    mutationKey: "deletePreConfirmationGame",
    mutationFn: () => getGameSessionCountByGameId(targetGame._id),
    onSuccess: (res) => {
      setTargetGameSessionCount(res);
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const deleteGameMutation = useMutation({
    mutationKey: "deleteGame",
    mutationFn: () => deleteGame(targetGame._id),
    onSuccess: (res) => {
      // getGamesQuery.refetch();
      renderNotification("Your game is deleted", "success");
      navigate('/');
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const publishGame = () => {
    const updatedGame = { ...game, status: "published" };
    setGame(updatedGame);
    console.log(updatedGame);
    saveGameMutation.mutate(updatedGame, {
      onSuccess: () => {
        renderNotification("Your game is published", "success");
      },
      onError: (err) => {
        console.error(err);
      }
    });
  };

  const saveChanges = () => {
    const updatedGame = {
      ...game,
      name: titleValue,
      industry: industryValue,
      client: clientValue,
      description: descriptionValue,
    };

    saveGameMutation.mutate(updatedGame, {
      onSuccess: () => {
        renderNotification("Your game is saved", "success");
      },
      onError: (err) => {
        console.error(err);
      },
    });
  };

  const saveGameMutation = useMutation(
    (updatedGame: IGame) => updateGame(gameId!, updatedGame),
    {
      mutationKey: "saveGame",
      onSuccess: (res) => {
        // setUnsavedChanges(false);
        // return queryClient.invalidateQueries({ queryKey: ["getGame"] });
      },
      onError: (err) => {
        console.error(err);
      },
    });

  useEffect(() => {
    getGameQuery.refetch();
  }, []);

  // TODO: Replace this placeholder component with the actual Introduction component.
  const [bodyComponent, setBodyComponent] = useState<ReactElement | null>(
    // <IntroductionTest />
  );
  const [selectedTopLevelIndex, setSelectedTopLevelIndex] = useState(0);
  const [selectedPuzzleIndex, setSelectedPuzzleIndex] = useState<number | null>(
    null
  );
  const [selectedTrainingQuestionIndex, setSelectedTrainingQuestionIndex] =
    useState<number | null>(null);

  const handleBackClick = () => {
    if (modalContext?.isChanged) {
      modalContext?.setConfirmedRoute('/');
      modalContext?.setIsHeaderItemClicked(true);
      modalContext?.showModal();
    } else {
      navigate('/');
    }
  }

  // TODO: Replace the placeholder components with the actual page components.
  useEffect(() => {
    if (selectedTopLevelIndex === 0) {
      // setBodyComponent(<IntroductionTest />);
    } else if (selectedTopLevelIndex === 1) {
      setBodyComponent(<GameMission />);
    } else if (selectedTopLevelIndex === 5) {
      // setBodyComponent(<OutroTest />);
    } else if (
      (selectedTopLevelIndex === 2 ||
        selectedTopLevelIndex === 3 ||
        selectedTopLevelIndex === 4) &&
      selectedPuzzleIndex === null
    ) {
      // setBodyComponent(<RoomOverviewTest roomId={getRoomId} />);
    } else if (
      (selectedTopLevelIndex === 2 ||
        selectedTopLevelIndex === 3 ||
        selectedTopLevelIndex === 4) &&
      selectedPuzzleIndex !== null &&
      selectedTrainingQuestionIndex === null
    ) {
      // setBodyComponent(<PuzzleOverviewTest puzzleId={getPuzzleId} />);
    } else if (selectedTrainingQuestionIndex === 0) {
      // setBodyComponent(<CreateQuestionsTest puzzleId={getPuzzleId} />);
    } else if (
      selectedTrainingQuestionIndex &&
      selectedTrainingQuestionIndex > 0
    ) {
      // setBodyComponent(<EditQuestionsTest questionId={getTrainingQuestionId} />);
    }
  }, [
    selectedTopLevelIndex,
    selectedPuzzleIndex,
    selectedTrainingQuestionIndex,
  ]);

  function formatCustomDateTime(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);

    let hour = date.getHours();
    const minute = String(date.getMinutes()).padStart(2, "0");
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;

    return `${day}/${month}/${year} at ${hour}:${minute}${ampm}`;
  }

  return (
    <div className="ap-container">
      <div className={styles.gameHeaderContainer}>
        <GameHeader />
        <Button className={`${styles.addSessionBtn} ap-my-spacing-5`} onClick={() => saveChanges()} icon="icon-save-outline" disabled={!isDirty || published}>
          Save settings
        </Button>
      </div>
      <div className="row mt-2">
        <div
          className="col-md-4 mb-4"
          style={{ width: "548px" }}>
          <Panel style={{ width: "548px", paddingTop: "1.25rem" }}>
            <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">Basic information</h1>
            <div className={styles["info-container"]}>
              <div className={styles["info-item"]}>
                <div className={styles["info-column"]}>
                  <p className={styles["bi-header"]}>Created by</p>
                </div>
                <div className={styles["second-info-column"]}>
                  <p className={styles["bi-body"]}>
                    {game.creator} on {formatCustomDateTime(game.createdAt)} CET
                  </p>
                </div>
              </div>
              <div className={styles["info-item"]}>
                <div className={styles["info-column"]}>
                  <p className={styles["bi-header"]}>Last edited by</p>
                </div>
                <div className={styles["second-info-column"]}>
                  <p className={styles["bi-body"]}>
                    {game.creator} on {formatCustomDateTime(game.updatedAt)} CET
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.switch}>
              <Tooltip position="top-left" content="Save changes to publish game">
                <Switch
                  defaultChecked={published}
                  disabled={published}
                  onChange={() => {
                    setShowPublishModal(true);
                  }}
                >
                  {"Publish game"}
                </Switch>
              </Tooltip>
            </div>
            <div style={{ width: "calc(100% - 10px)" }}>
              <Input
                type={"text"}
                title={"Name of Game"}
                required={true}
                onClick={() => {
                  console.log("you clicked the input");
                }}
                value={titleValue}
                style={{ width: "100%", paddingBottom: "10px" }}
                onChange={onTitleChange}
                //error={questionTitleError}
                errorNode={
                  <div
                    id="errormessage"
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    Please enter a question title
                  </div>
                }
              ></Input>
              <Select
                data={industries.map(({ label, value }) => ({ label, value }))}
                value={industryValue}
                searchable={false}
                placeholder={"Industry"}
                defaultValue={industryValue}
                required={true}
                style={{ width: "100%", paddingBottom: "10px" }}
                onSelect={(dropdownValue) => {
                  setIndustryValue(String(dropdownValue));
                }}
              />
              <Input
                type={"text"}
                title={"Client"}
                style={{ width: "100%", paddingBottom: "10px" }}
                autosize={true}
                required={true}
                value={clientValue}
                onChange={onClientChange}
                // error={questionBodyError}
                errorNode={
                  <div
                    id="errormessage"
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    Please enter a question body
                  </div>
                }
              ></Input>
              <div>
                <TextArea
                  style={{ width: "100%", height: "78px" }}
                  title={"Additional details"}
                  maxLength={420}
                  autosize={true}
                  value={descriptionValue}
                  onChange={onAdditionalDetailsChange}
                ></TextArea>
              </div>
            </div>
            <div className="mt-2">
              <Button
                kind="text"
                icon="icon-delete-outline"
                onClick={() => {
                  setShowDeleteGameModal(true);
                  setTargetGame(game);
                }}
              >
                Delete Game
              </Button>
            </div>
          </Panel>
        </div>
        <div className="col-md-8" style={{ width: "780px", height: "400px" }}>
          <Panel
            style={{ width: "780px", height: "400px", marginLeft: "32px", paddingTop: "1.25rem" }}
          >
            <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">Game administrators</h1>
            <p className="ap-typography ap-font-weight-2 mb-2">Add new administrators for this game</p>
            <div style={{ display: "flex", gap: "8px", alignItems: "flex-end" }}>
              <Input
                type={"text"}
                title={"Type e-mails"}
                style={{ width: "100%" }}
                autosize={true}
                //value={bodyValue}
                // onChange={onClientChange}
                // error={questionBodyError}
                errorNode={
                  <div
                    id="errormessage"
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    Type e-mails
                  </div>
                }
              ></Input>
              <Button kind="secondary" icon="icon-add-user-outline" style={{ marginBottom: "5px" }}>
                Add administrator
              </Button>
            </div>
            <p className="ap-typography ap-font-weight-2 mt-3 mb-2">Administrators</p>
            <div className="ap-table-demo">
              <Table originalData={originalData} hasTitle striped>
                <Column field="name">Name</Column>
                <Column field="email">Email</Column>
                <Column field="actions" renderCell={renderTotalCell}>
                  Actions
                </Column>
              </Table>
            </div>
          </Panel>
        </div>
      </div>
      <div className="row mt-0">
        <div
          className="col-md-4"
          style={{ width: "548px", height: "696.87px" }}
        >
          <Panel style={{ width: "548px", height: "696.87px", paddingTop: "1.25rem" }}>
            <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">Customise colours and logo (optional)</h1>
            <p className="ap-typography ap-font-weight-2" style={{ paddingBottom: "20px" }}>Colour scheme for the illustrations</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  backgroundColor: "#415385",
                  borderRadius: "4px",
                  border: "1px solid #D1D1D1",
                  marginRight: "10px",
                }}
              ></div>
              <span>Primary colour</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  backgroundColor: "#FFB600",
                  borderRadius: "4px",
                  border: "1px solid #D1D1D1",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              ></div>
              <span>Secondary colour</span>
            </div>
            <Button kind="primary" icon="icon-art-themes-outline">
              Pick new colours
            </Button>
            <div className={styles.divider}></div>
            <p>Upload a logo to integrate into the illustrations</p>
            <span>Format requirements - please only use:</span>
            <div style={{ width: "calc(100% - 10px)" }}>
              <Upload
                className="upload"
                onChange={onUploadChange}
                multiple={false}
                autoUpload={true}
                onUpload={uploadFiles}
                acceptFileType=".JPG,.PNG,.MP4"
                maxFileSize={10 * 1024 * 1024}
                config={{
                  trigger: false,
                  type: "inline",
                  size: true,
                }}
              ></Upload>
            </div>
            <div className={styles.divider}></div>
            <p>Preview</p>
            <div style={{ display: "flex" }}>
              <img
                src={svg1}
                alt="Room 1"
                style={{
                  height: "92.87px",
                  width: "164.7px",
                  marginRight: "10px",
                }}
              />
              <img
                src={svg2}
                alt="Room 2"
                style={{
                  height: "92.87px",
                  width: "164.7px",
                  marginRight: "10px",
                }}
              />
              <img
                src={svg3}
                alt="Room 3"
                style={{ height: "92.87px", width: "164.7px" }}
              />
            </div>
          </Panel>
        </div>
      </div>
      <Modal
        visible={showDeleteGameModal}
        title={intl.formatMessage({ id: "game.action.delete.modal.title" })}
        ariaLabel={intl.formatMessage({ id: "game.action.delete.modal.title" })}
        onCancel={() => {
          setShowDeleteGameModal(false);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                deletePreConfirmationGameMutation.mutate();
                setShowDeleteGameModal(false);
                setShowDeleteGameConfirmationModal(true);
              }}
            >
              <FormattedMessage id="game.action.delete.modal.confirm" />
            </Button>
            <Button
              onClick={() => {
                setShowDeleteGameModal(false);
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="game.action.delete.modal.description" />
        </p>
      </Modal>

      <Modal
        visible={showDeleteGameConfirmationModal}
        title={intl.formatMessage({ id: "game.action.delete.confirmation.modal.title" })}
        ariaLabel={intl.formatMessage({ id: "game.action.delete.confirmation.modal.title" })}
        onCancel={() => {
          setShowDeleteGameConfirmationModal(false);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                deleteGameMutation.mutate();
                setShowDeleteGameConfirmationModal(false);
              }}
            >
              <FormattedMessage id="game.action.delete.confirmation.modal.confirm" />
            </Button>
            <Button
              onClick={() => {
                setShowDeleteGameConfirmationModal(false);
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
          </div>
        }
      >
        <p style={{ whiteSpace: "pre-wrap" }}>
          {(() => {
            if (targetGameSessionCount > 1) {
              return intl.formatMessage({
                id: "game.action.delete.confirmation.modal.description"
              }).replace("{{numberOfSessions}}", targetGameSessionCount.toString())
            } else if (targetGameSessionCount === 1) {
              return intl.formatMessage({
                id: "game.action.delete.confirmation.modal.description.singleSession"
              })
            } else {
              return intl.formatMessage({
                id: "game.action.delete.confirmation.modal.description.noSessions"
              })
            }
          })()}
        </p>
      </Modal>

      <Modal
        visible={showPublishModal}
        title="Ready to publish your game?"
        ariaLabel={intl.formatMessage({ id: "game.publish.modal.title" })}
        onCancel={() => {
          setShowPublishModal(false);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowPublishModal(false);
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button
              kind="primary"
              icon="icon-gameroom-outline"
              onClick={() => {
                publishGame();
                setShowPublishModal(false);
              }}
            >
              Publish game
            </Button>
          </div>
        }
      >
        <p className="ap-mb-spacing-4">
          Ensure your game is ready for the spotlight!
        </p>
        <p>
          Quick checks:
        </p>
        <ul className="ap-mb-spacing-4">
          <li>All content is final.</li>
          <li>The introduction has been filled out.</li>
          <li>Game duration is as intended.</li>
          <li>Branding is for the correct client.</li>
        </ul>
        <p>
          Review carefully, this action is permanent, Once published, your game is available for session creation and cannot go back to drafts but can still be amended or deleted
        </p>
      </Modal>
    </div>

  );
};

export default GameSettings2;

