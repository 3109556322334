import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import { SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";
import PuzzleBase, { StringStatePayload } from "./PuzzleBase";
import GameScene from "@/phaser/scenes/GameScene";

export default class Keyhooks extends PuzzleBase<StringStatePayload> {
  public static readonly INVENTORY_ITEM_NAME = "Fs3 Key";
  private static readonly INITIAL_MESSAGE: string =
    "Be careful what you choose... points could be lost";
  private static readonly COMPLETE_MESSAGE: string = "Key added to inventory";

  private readonly images: Phaser.GameObjects.Image[] = [];
  private readonly CORRECT_KEY_NUMBER = 5;
  private readonly penaltyPoints = 50;
  private keyAdded = false;

  constructor(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    actionId: string,
    callback: () => void = () => {}
  ) {
    super(scene, sourceObject, actionId, callback, Keyhooks.INITIAL_MESSAGE);

    const image = new Phaser.GameObjects.Image(
      this.scene,
      SCREEN_CENTER_X,
      SCREEN_CENTER_Y,
      "keyhooks"
    );
    this.add(image);

    this.loadKeys();

    this.scene.add.existing(this);
    this.scene.input.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);

    this.hintBar.setAnimationDuration(0);
    this.hintBar.displayMessage(Keyhooks.INITIAL_MESSAGE);

    this.scene.hud.setHintButtonVisible(false);
  }

  protected isPuzzleStatePayloadValid(payload): boolean {
    return (
      typeof payload === "object" &&
      payload !== null &&
      "value" in payload &&
      typeof payload.value === "string"
    );
  }

  protected updatePuzzle(currentState: StringStatePayload): void {
    const imageName = currentState.value;
    const clickedImageNumber = parseInt(imageName);
    const clickedImage = this.images.find((image) => image.name === imageName);

    if (!clickedImage) {
      return;
    }

    if (clickedImageNumber == this.CORRECT_KEY_NUMBER) {
      this.hintBar.displayMessage(Keyhooks.COMPLETE_MESSAGE);
      clickedImage.setVisible(false);
      this.scene.input.off(
        Phaser.Input.Events.POINTER_DOWN,
        this.onPointerDown
      );

      if (!this.keyAdded) {
        this.keyAdded = true;
        this.completePuzzle();
      }
    } else {
      const topCenter = clickedImage.getTopCenter();
      this.displayTooltip(topCenter.x!, topCenter.y!);
    }
  }

  protected removeEventHandlers(): void {
    this.scene.input.off(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
  }

  private onPointerDown = (pointer: Phaser.Input.Pointer) => {
    const clickedImage = this.images.find((image) =>
      image.getBounds().contains(pointer.x, pointer.y)
    );

    if (clickedImage) {
      const imageName = clickedImage.name;
      const puzzleStatePayload = { value: imageName };
      if (imageName === this.CORRECT_KEY_NUMBER.toString()) {
        this.addKeyItemToInventory();
      }
      this.emitPuzzleStateChanged(puzzleStatePayload);
    }
  };

  private loadKeys() {
    const numberOfKeys = 9;

    for (let i = 0; i < numberOfKeys; i++) {
      const keyImage = new Phaser.GameObjects.Image(this.scene, 0, 0, "key");
      keyImage.name = i.toString();
      this.add(keyImage);
      this.images.push(keyImage);

      if (i === this.CORRECT_KEY_NUMBER) {
        if (
          this.scene.inventory
            ?.getNamesOfItems()
            .includes(Keyhooks.INVENTORY_ITEM_NAME)
        ) {
          keyImage.setVisible(false);
        }
      }
    }

    Phaser.Actions.GridAlign(this.images, {
      width: 3,
      height: 3,
      cellWidth: 298,
      cellHeight: 213,
      x: 310,
      y: 212,
    });
  }

  private displayTooltip(x: number, y: number) {
    const messageText = new Phaser.GameObjects.Text(
      this.scene,
      x + 80,
      y - 40,
      "Ouch, you got zapped!",
      {
        fontFamily: "Roboto-Regular",
        fontSize: "20px",
        color: "#000000",
        align: "center",
      }
    ).setOrigin(0.5);

    const MESSAGE_BACKGROUND_PADDING = 20;
    const POINTS_BACKGROUND_PADDING = 10;
    const messageBackgroundWidth =
      messageText.width + 2 * MESSAGE_BACKGROUND_PADDING;
    const messageBackgroundHeight =
      messageText.height + 2 * MESSAGE_BACKGROUND_PADDING;

    const messageBackground = new Phaser.GameObjects.Graphics(this.scene);
    messageBackground.fillStyle(0xd3c9b0, 1);
    messageBackground.fillRoundedRect(
      messageText.getTopLeft().x! - MESSAGE_BACKGROUND_PADDING,
      messageText.getTopLeft().y! - MESSAGE_BACKGROUND_PADDING,
      messageBackgroundWidth,
      messageBackgroundHeight,
      15
    );

    this.add(messageBackground);
    this.add(messageText);

    const pointsText = new Phaser.GameObjects.Text(
      this.scene,
      messageText.getTopCenter().x!,
      messageText.getTopCenter().y! - MESSAGE_BACKGROUND_PADDING,
      `-${this.penaltyPoints} points`,
      {
        fontFamily: "Roboto-Regular",
        fontSize: "12px",
        color: "#ffffff",
        align: "center",
      }
    ).setOrigin(0.5);

    const pointsBackgroundWidth =
      pointsText.width + 2 * POINTS_BACKGROUND_PADDING;
    const pointsBackgroundHeight =
      pointsText.height + 2 * POINTS_BACKGROUND_PADDING;

    const pointsBackground = new Phaser.GameObjects.Graphics(this.scene);
    pointsBackground.fillStyle(0xc52a1a, 1);
    pointsBackground.fillRoundedRect(
      pointsText.getTopLeft().x! - POINTS_BACKGROUND_PADDING,
      pointsText.getTopLeft().y! - POINTS_BACKGROUND_PADDING,
      pointsBackgroundWidth,
      pointsBackgroundHeight,
      15
    );

    this.add(pointsBackground);
    this.add(pointsText);

    this.scene.time.delayedCall(2000, () => {
      messageText.destroy();
      messageBackground.destroy();
      pointsText.destroy();
      pointsBackground.destroy();
    });
  }

  private addKeyItemToInventory() {
    const roomObjects = this.scene.sourceScene.roomObjects;

    Object.keys(roomObjects).forEach((key) => {
      const roomObject = roomObjects[key];

      if (roomObject.gameObject.name === Keyhooks.INVENTORY_ITEM_NAME) {
        if (this.scene.sourceScene instanceof GameScene) {
          const gameScene = this.scene.sourceScene as GameScene;

          gameScene.game.socketEmit("pickupItem", {
            sessionId: gameScene.game.session._id,
            sourceObject: roomObject.gameObject,
          });
        }
      }
    });
  }
}
