import GameRoomObject from "../RoomObject/GameRoomObject";
import EventBridge from "@/utils/EventBridge";
import GameScene from "@/phaser/scenes/GameScene";

export class DisplayPuzzleGameActionHandler {
  private gameScene: GameScene;
  private sourceObject: GameRoomObject;
  private puzzleName: string;
  private actionId: string;

  constructor(
    gameScene: GameScene,
    sourceObject: GameRoomObject,
    puzzleName: string,
    actionId: string
  ) {
    this.gameScene = gameScene;
    this.sourceObject = sourceObject;
    this.puzzleName = puzzleName;
    this.actionId = actionId;
  }

  public execute(): void {
    const openPuzzlePayload = {
      puzzleName: this.puzzleName,
      sourceObject: this.sourceObject,
      actionId: this.actionId,
    };

    EventBridge.emit("ui.openPuzzle", openPuzzlePayload);

    this.gameScene.game.socketEmit("puzzleStarted", {
      sessionId: this.gameScene.game.session._id,
      puzzleId: this.puzzleName,
      sourceObjectRef: this.sourceObject.ref,
    });
  }
}
