import UIScene from "@/phaser/scenes/UIScene";
import IconButton from "./buttons/IconButton";
import GameScene from "@/phaser/scenes/GameScene";

export default class CloseButton extends Phaser.GameObjects.Container {
  private closeButton: IconButton;
  private callback: () => void;
  private uiScene: UIScene;

  constructor(
    scene: UIScene,
    callback: () => void
  ) {
    super(scene, 0, 0);
    
    this.uiScene = scene;
    this.callback = callback;

    this.closeButton = new IconButton(
      scene,
      scene.renderer.width - 94,
      104,
      "close-image",
      () => this.onClick(),
    ).setOrigin(0);

    this.add(this.closeButton);
  }

  private onClick() {
    if (this.uiScene.sourceScene instanceof GameScene) {
      const gameScene = this.uiScene.sourceScene as GameScene;

      if (gameScene.isNavigatorScene()) {
        gameScene.game.socketEmit("handleMessage",  {
            sessionId: gameScene.game.session._id,
            sourcePlayerId: gameScene.user.id,
            type: "closeModal"
          });

        this.callback();
      }
    }
    else {
      this.callback();
    }
  }
}