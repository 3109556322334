import UIScene from "@/phaser/scenes/UIScene";
import CloseButton from "../ui/CloseButton";
import PopupBackground from "../ui/PopupBackground";
import HintBar from "../ui/HintBar";
import { SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";
import EventBridge from "@/utils/EventBridge";


export default class ImagePopup extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly image: Phaser.GameObjects.Image;
  private readonly popupBackground: PopupBackground;
  private readonly hintBar: HintBar;

  constructor(
    scene: UIScene,
    imageType: string,
    hintMessage: string
  ) {
    super(scene, 0, 0);

    this.scene = scene;
    this.scene.hud.setHintButtonVisible(true); 

    this.popupBackground = new PopupBackground(scene);
    this.add(this.popupBackground);

    this.image = new Phaser.GameObjects.Image(
      scene,
      SCREEN_CENTER_X,
      SCREEN_CENTER_Y,
      imageType
    );
    this.add(this.image);

    this.hintBar = new HintBar(scene, hintMessage);
    this.add(this.hintBar);

    const closeButton = new CloseButton(this.scene, () => this.closeModal());
    this.add(closeButton);

    scene.add.existing(this);

    EventBridge.on("ui.closeModal", () => this.closeModal());
  }

  closeModal() {
    EventBridge.remove("ui.closeModal");
    
    this.scene.hud.setHintButtonVisible(false); 
    this.scene.makeGameSceneInteractive();
    this.destroy();
  }
}
