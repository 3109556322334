import { IGameSession } from "escape-rooms-types/types/gameSession";

export interface IGameSessionData extends IGameSession {
  plannedDateEdit: Date | null;
  plannedTimeEdit: Date | null;
}

export const prepareGameSessionForUI = (
  data: IGameSession
): IGameSessionData => {
  data.plannedDateEdit = data.plannedDate
    ? new Date(data.plannedDate)
    : undefined;
  data.plannedTimeEdit = data.plannedTime
    ? new Date(`2050-01-01 ${data.plannedTime}:00`)
    : undefined;
  return data;
};

export const prepareGameSessionForAPI = (
  data: IGameSessionData
): IGameSession => {
  const padStart = (value: number): string => value.toString().padStart(2, "0");
  if (data.plannedDateEdit) {
    data.plannedDate = `${data.plannedDateEdit?.getFullYear()}-${padStart(
      data.plannedDateEdit?.getMonth() + 1
    )}-${padStart(data.plannedDateEdit?.getDate())}`;
  } else {
    data.plannedDate = null;
  }
  if (data.plannedTimeEdit) {
    data.plannedTime = `${padStart(
      data.plannedTimeEdit?.getHours()
    )}:${padStart(data.plannedTimeEdit?.getMinutes())}`;
  } else {
    data.plannedTime = null;
  }
  return data;
};
