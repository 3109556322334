import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import PuzzleBase, { StringStatePayload } from "./PuzzleBase";
import { Colors } from "@/constants";

export default class DoorHandle extends PuzzleBase<StringStatePayload> {
  private static readonly HINT_MESSAGE: string =
    "You might want to figure out how to open the TV and minifridge first.";

  private readonly buttonTextControls: Array<Phaser.GameObjects.Text> = [];
  private readonly buttonGraphics: Array<Phaser.GameObjects.Graphics> = [];
  private readonly yPosition: integer;

  private readonly INITIAL_BUTTON_COLOR = 0xffdb80;
  private readonly ALTERNATE_BUTTON_COLOR = 0x7f5b00;
  private readonly VALID_ANSWER: string = "534";

  private codeTextObject!: Phaser.GameObjects.Text;
  private textBackground!: Phaser.GameObjects.Graphics;
  private underlineCodeTextObject!: Phaser.GameObjects.Text;

  private readonly gridConfiguration = {
    x: 0,
    y: 0,
    paddingX: 29,
    paddingY: 29,
    cardWidth: 57,
    cardHeight: 57,
    numberOfColumns: 3,
    buttonRadius: 9.5,
    includeZero: false,
    buttonTextFontStyle: {
      fontFamily: "Arial",
      fontSize: "38px",
      color: "#202942",
      align: "center",
      fontStyle: "bold",
    },
    buttonTextAlternateColor: "#ffffff",
  };

  private readonly numbersDisplayConfiguration = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    radius: 4,
    backgroundColor: {
      default: Colors.Black,
      correct: "green",
      incorrect: "red",
    },
    fontStyle: {
      fontFamily: "Arial",
      fontSize: "38px",
      color: "#ffffff",
      align: "left",
      fontStyle: "bold",
    },
    letterSpacing: 40,
  };

  private backgroundImage: Phaser.GameObjects.Image;

  constructor(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    actionId: string,
    callback: () => void = () => {}
  ) {
    super(scene, sourceObject, actionId, callback, DoorHandle.HINT_MESSAGE);

    this.yPosition = 252;

    this.backgroundImage = new Phaser.GameObjects.Image(
      scene,
      scene.scale.width / 2,
      this.yPosition - 52,
      "door-handle"
    ).setOrigin(0);

    this.backgroundImage.setX(
      this.backgroundImage.x - this.backgroundImage.width / 2
    );

    this.add(this.backgroundImage);

    this.gridConfiguration.x = this.scene.scale.width / 2 - 5;
    this.gridConfiguration.y = this.yPosition + 84;

    this.numbersDisplayConfiguration.x = this.gridConfiguration.x;
    this.numbersDisplayConfiguration.y = this.yPosition - 30;
    this.numbersDisplayConfiguration.width = 229;
    this.numbersDisplayConfiguration.height = 80;

    this.initializeTextArea();
    this.initializeButtons();

    this.scene.add.existing(this);
  }

  protected updatePuzzle(currentState: StringStatePayload): void {
    this.updateCodeText(currentState.value);
  }

  protected isPuzzleStatePayloadValid(payload): boolean {
    return (
      typeof payload === "object" &&
      payload !== null &&
      "value" in payload &&
      typeof payload.value === "string"
    );
  }

  protected removeEventHandlers(): void {}

  private updateCodeText(code: string) {
    this.codeTextObject.setText(code);
    this.checkIfComplete();
  }

  private initializeTextArea() {
    this.textBackground = new Phaser.GameObjects.Graphics(this.scene);
    this.textBackground.fillStyle(
      this.numbersDisplayConfiguration.backgroundColor.default,
      1
    );

    this.textBackground.fillRoundedRect(
      this.numbersDisplayConfiguration.x,
      this.numbersDisplayConfiguration.y,
      this.numbersDisplayConfiguration.width,
      this.numbersDisplayConfiguration.height,
      this.numbersDisplayConfiguration.radius
    );

    this.add(this.textBackground);

    const x =
      this.numbersDisplayConfiguration.x +
      this.numbersDisplayConfiguration.letterSpacing;
    const y =
      this.numbersDisplayConfiguration.y +
      this.numbersDisplayConfiguration.height / 2 -
      this.numbersDisplayConfiguration.letterSpacing / 2;

    this.codeTextObject = new Phaser.GameObjects.Text(
      this.scene,
      x,
      y,
      this.VALID_ANSWER,
      this.numbersDisplayConfiguration.fontStyle
    ).setLetterSpacing(this.numbersDisplayConfiguration.letterSpacing);

    this.codeTextObject.setText("");
    this.add(this.codeTextObject);

    this.underlineCodeTextObject = new Phaser.GameObjects.Text(
      this.scene,
      x,
      y + 10,
      "_".repeat(this.VALID_ANSWER.length),
      this.numbersDisplayConfiguration.fontStyle
    ).setLetterSpacing(this.numbersDisplayConfiguration.letterSpacing);

    this.add(this.underlineCodeTextObject);
  }

  private initializeButtons() {
    for (let i = 0; i < 10; i++) {
      const x =
        this.gridConfiguration.x +
        (this.gridConfiguration.cardWidth + this.gridConfiguration.paddingX) *
          (i % this.gridConfiguration.numberOfColumns);
      const y =
        this.gridConfiguration.y +
        (this.gridConfiguration.cardHeight + this.gridConfiguration.paddingY) *
          Math.floor(i / this.gridConfiguration.numberOfColumns);
      const currentNumber = (i + 1) % 10;

      if (currentNumber === 0 && !this.gridConfiguration.includeZero) {
        continue;
      }

      const buttonBackground = new Phaser.GameObjects.Graphics(this.scene);

      buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
      buttonBackground.fillRoundedRect(
        x,
        y,
        this.gridConfiguration.cardWidth,
        this.gridConfiguration.cardHeight,
        5
      );
      buttonBackground.setInteractive(
        new Phaser.Geom.Rectangle(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight
        ),
        Phaser.Geom.Rectangle.Contains
      );
      this.buttonGraphics.push(buttonBackground);

      const buttonText = new Phaser.GameObjects.Text(
        this.scene,
        x + this.gridConfiguration.cardWidth / 2,
        y + this.gridConfiguration.cardHeight / 2,
        currentNumber.toString(),
        this.gridConfiguration.buttonTextFontStyle
      )
        .setOrigin(0.5)
        .setName(currentNumber.toString())
        .setInteractive();

      this.buttonTextControls.push(buttonText);

      buttonText.on(Phaser.Input.Events.POINTER_DOWN, () => {
        this.onClickCallback(buttonText.name);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_DOWN, () => {
        this.onClickCallback(buttonText.name);
      });

      buttonText.on(Phaser.Input.Events.POINTER_OVER, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.ALTERNATE_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextAlternateColor);
      });

      buttonText.on(Phaser.Input.Events.POINTER_OUT, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextFontStyle.color);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_OVER, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.ALTERNATE_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextAlternateColor);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_OUT, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextFontStyle.color);
      });
    }

    this.add(this.buttonGraphics);
    this.add(this.buttonTextControls);
  }

  private onClickCallback(value: string) {
    const currentText = this.codeTextObject.text;

    if (currentText.length >= this.VALID_ANSWER.length) {
      return;
    }

    const code = currentText + value.toString();
    const puzzleStatePayload = { value: code };
    this.emitPuzzleStateChanged(puzzleStatePayload);
  }

  private checkIfComplete() {
    const currentCode = this.codeTextObject.text;

    if (currentCode === this.VALID_ANSWER) {
      this.setTextBackgroundColor(Colors.Green);
      this.completePuzzle();
    } else if (currentCode.length == this.VALID_ANSWER.length) {
      this.setTextBackgroundColor(Colors.Red);
      this.refreshNumbersDisplay();
    }
  }

  private refreshNumbersDisplay() {
    const timerEvent = this.scene.time.delayedCall(2000, () => {
      this.setTextBackgroundColor(
        this.numbersDisplayConfiguration.backgroundColor.default
      );
      this.underlineCodeTextObject.setColor("white");
      this.codeTextObject.setColor("white").setText("");
    });

    this.timerEvents.push(timerEvent);
  }

  private setTextBackgroundColor(color: number) {
    this.textBackground.clear();
    this.textBackground.fillStyle(color, 1);
    this.textBackground.fillRoundedRect(
      this.numbersDisplayConfiguration.x,
      this.numbersDisplayConfiguration.y,
      this.numbersDisplayConfiguration.width,
      this.numbersDisplayConfiguration.height,
      this.numbersDisplayConfiguration.radius
    );
  }
}
