import api from "@/api";
import { UploadFileDescriptor } from "@/models/Files/UploadFileDescriptor";
import axios from "axios";

export function getAssets() {
  return api.get(`/asset?limit=1000`);
}

// From CDN
export function getAssetSvg(textureUrl: string) {
  return axios.get(`/asset-state-textures/${textureUrl}.svg`);
}

export function uploadAssetFile(file: UploadFileDescriptor, path?: string) {
  const formData = new FormData();
  formData.append("file", file.originFile as Blob, file.name);
  const endpoint = path ? `/asset/upload/file?path=${path}` : "/asset/upload/file";
  return api.post(endpoint, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function deleteAssetFile(fileName: string) {
  const sanitizedName = fileName.replace(/^\/+/, "");
  const encodedFileName = encodeURIComponent(sanitizedName);
  return api.delete(`/asset/${encodedFileName}`);
}

