import React, { Fragment, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import styles from "./GameEditorSidebar.module.scss";
import { menuItemProps } from '@/constants';
import { useModal } from '../game-customisation-template-pages/UnsavedChangesContext';
import { useGameStore } from "@/store/game-store";

interface GameEditorSidebarProps {
  menuItems: menuItemProps[];
  setSelectedTopLevelIndex: React.Dispatch<React.SetStateAction<number>>;
  setSelectedPuzzleIndex: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedTrainingQuestionIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

const GameEditorSidebar: React.FC<GameEditorSidebarProps> = (
  {
    menuItems,
    setSelectedTopLevelIndex,
    setSelectedPuzzleIndex,
    setSelectedTrainingQuestionIndex
  }) => {

  const [activeIndex, setActiveIndex] = React.useState([0]);
  const [lastMenuState, setLastMenuState] = React.useState(menuItems);
  const modalContext = useModal();
  const game = useGameStore((state) => state.game);
  const rooms = useGameStore((state) => state.game.rooms);

  const handleMenuItemClick = (index: number[]) => {
    setActiveIndex(index.toString());
    setSelectedTopLevelIndex(index[0]);
    setSelectedTrainingQuestionIndex(index[2] !== undefined ? index[2] : null);
  };

  useEffect(() => {
  }, [modalContext?.isMenuItemClicked]);


  useEffect(() => {
    setSelectedTopLevelIndex(activeIndex[0])
    setSelectedTrainingQuestionIndex(null)
    if (activeIndex.length > 2) {
      const lastTrainingQuestionIndex = menuItems[activeIndex[0]].children[activeIndex[1]].content.trainingQuestions.length;
      const last_length = lastMenuState[activeIndex[0]].children[activeIndex[1]].content.trainingQuestions.length
      if (lastTrainingQuestionIndex > last_length) {
        setSelectedTrainingQuestionIndex(lastTrainingQuestionIndex)
        setActiveIndex([activeIndex[0], activeIndex[1], lastTrainingQuestionIndex])
      } else if (lastTrainingQuestionIndex === last_length) {
        setSelectedTrainingQuestionIndex(activeIndex[2])
        setActiveIndex([activeIndex[0], activeIndex[1], activeIndex[2]])
      } else if (lastTrainingQuestionIndex < last_length) {
        setSelectedTrainingQuestionIndex(null)
        setActiveIndex([activeIndex[0], activeIndex[1]])
      }
    }
    setLastMenuState(menuItems)
  }, [menuItems])

  const MenuItemButton = React.forwardRef(function MenuItemButton(
    { content, className, suffixIcon, suffixExpandedIcon, prefixIcon, children, onClick, onKeyUp, tabIndex, title, index }
    , ref) {

    const handleClick = () => {
      setActiveIndex(index);
      setLastMenuState(menuItems)
      setSelectedTopLevelIndex(index[0]);
      setSelectedPuzzleIndex(index[1] !== undefined ? index[1] : null);
      console.log("index[1] !== undefined ? index[1] : null", index[1] !== undefined ? index[1] : null)
      setSelectedTrainingQuestionIndex(index[2] !== undefined ? index[2] : null);
      if (onClick) onClick();
    };
    const open = className.includes('ps-open');
    const active = activeIndex.toString() === index.toString();
    const nextIndex = index ? parseInt(index) + 1 : 0;

    return (
      <a
        ref={ref}
        className={`${active ?
          styles.sidebarMenuButtonActive :
          (nextIndex.toString() === activeIndex.toString() ?
            styles.sidebarMenuButtonNextActive :
            styles.sidebarMenuButton)} ${className}`}
        onClick={handleClick}
        {...{ onKeyUp, tabIndex, title }}
        key={tabIndex}
      >
        {suffixIcon
          ? <span className={`Appkit4-icon icon-${prefixIcon}-outline`} />
          : undefined
        }
        {content}
        {children}
        {suffixIcon
          ? <span className={`${styles.suffixIcon} Appkit4-icon icon-${open && suffixExpandedIcon
            ? suffixExpandedIcon
            : suffixIcon
            }-outline`} />
          : undefined
        }
      </a>
    );
  });

  MenuItemButton.displayName = 'MenuItemButton';

  const handleAddRoom = (event, id: number) => {
    event.stopPropagation();
  };

  const handleAddTrainingQuestion = (event) => {
    event.stopPropagation();
    setTrainingQuestionCount(prevCount => prevCount + 1);
  };

  function roomItem(room) {
    if (!room) return null;

    return (
      <div className="ap-ml-spacing-3">
        <div className={`${styles.roomTitle} ap-font-weight-2 ap-mb-spacing-2`}>
          <span>{room.name}</span>
          {!room.isActive && room.order > 0 && (
            <button className={styles.addRoomButton} onClick={(e) => handleAddRoom(e, id)}>• Add room</button>
          )}
        </div>
        <div className={styles.roomMetaData}>
          <span>{room.numberOfPuzzles} puzzles</span>
          <span> • </span>
          <span>{room.puzzleTime} min</span>
          <span> • </span>
          <span>{getQuestionCount(room)} training questions</span>
        </div>
      </div>
    );
  };

  function getPuzzlesFromRoom(room: any) {
    if (!room?.objects) return [];
    const puzzles: any[] = [];

    for (const obj of room.objects) {
      const actions = obj.actions || [];
      for (let i = 0; i < actions.length; i++) {
        const action = actions[i];
        if (action.type === "displayPuzzle" && action.isVisibleOnAdminPanel) {
          const puzzle: any = {
            title: action.modalPayload?.title || "Untitled",
            challengeDescription: action.description || "",
            bullets: (action.bullets || []).map((b: any) => ({
              item: b.text,
              itemBulletsDescriptions: b.children || []
            })),
            trainingQuestions: []
          };

          const nextAction = actions[i + 1];
          if (
            nextAction &&
            nextAction.type === "displayQuestionnaire" &&
            nextAction.questionnaire?.questions?.length
          ) {
            puzzle.trainingQuestions = nextAction.questionnaire.questions;
          }

          puzzles.push(puzzle);
        }
      }
    }
    return puzzles;
  }

  function renderPuzzleItems(room: any, roomIndex: number) {
    if (!room?.isActive) return null;
    const puzzleList = getPuzzlesFromRoom(room);

    return puzzleList.map((puzzle: any, puzzleIndex: number) => {
      const puzzleData = puzzle;

      return (
        <div key={puzzleIndex} className={styles.puzzleContainer}>
          <MenuItem
            className={styles.puzzleTitleContainer}
            component={<MenuItemButton />}
            disabled={!room.isActive && room.order > 0}
            index={[...roomIndex, puzzleIndex]}
          >
            <span className="Appkit4-icon icon-box-outline ap-mr-spacing-3" />
            <span>{puzzleData?.title}</span>
          </MenuItem>

          {puzzleData.trainingQuestions?.length > 0 && (
            <div className="ap-mb-spacing-4">
              {puzzleData.trainingQuestions.map((question: any, questionIndex: number) => (
                <Fragment key={question.id}>
                  <MenuItem
                    key={questionIndex}
                    className={styles.trainingQuestion}
                    component={<MenuItemButton />}
                    prefixIcon="ask-question"
                    suffixIcon="pencil"
                    index={[...roomIndex, puzzleIndex, questionIndex + 1]}
                  >
                    <div className={styles.trainingQuestionInner}>
                      <span className={styles.trainingQuestionTitle}>{question.uiTitle}</span>
                      <div>
                        <span className="ap-typography-body-xs"> • </span>
                        <span className="ap-typography-body-xs ap-ml-spacing-3">1 min</span>
                      </div>
                    </div>
                  </MenuItem>
                </Fragment>
              ))}
            </div>
          )}
          <MenuItem
            key={puzzleIndex}
            className={styles.trainingQuestion}
            component={<MenuItemButton />}
            prefixIcon="ask-question"
            suffixIcon="pencil"
            disabled={room ? (!room.isActive && room.order > 0) : false}
            index={[...roomIndex, puzzleIndex, 0]}
          >
            <div className={styles.trainingQuestionInner}>
              <span className={styles.trainingQuestionTitle}>Training Question 1</span>
              <div>
                <span className="ap-typography-body-xs"> • </span>
                <span className="ap-typography-body-xs ap-ml-spacing-3">0 min</span>
              </div>
            </div>
          </MenuItem>

          <MenuItem
            key={`${puzzleIndex}-add`}
            className={styles.trainingQuestion}
            component={<MenuItemButton />}
            disabled={room ? (!room.isActive && room.order > 0) : false}
            index={[...roomIndex, puzzleIndex, 0]}
            onClick={handleAddTrainingQuestion}
          >
            <div className={`${styles.addTrainingQuestion} ap-px-spacing-4`}>
              <span className="Appkit4-icon icon-circle-plus-fill ap-mr-spacing-3" />
              Add training question
            </div>
          </MenuItem>
        </div>
      );
    });
  }


  function renderRoomNavItem(roomIndex: number) {
    const room = rooms[roomIndex - 2];

    return (
      <Fragment key={roomIndex.toString()}>
        <SubMenu
          key={roomIndex.toString()}
          disabled={room ? (!room.isActive && room.order > 0) : false}
          component={<MenuItemButton className={styles.sidebarRoomMenuButton} />}
          content={roomItem(room)}
          prefixIcon="door-opened"
          suffixIcon="down-chevron"
          suffixExpandedIcon="up-chevron"
          index={[...roomIndex]}
        >
          {renderPuzzleItems(room, roomIndex)}
        </SubMenu>
      </Fragment>
    );
  }

  // Function to render a single menu item
  function renderMenuItem(item, index: number) {
    const { content, prefixIcon, suffixIcon, children } = item;
    item.room = rooms[index - 2];
    item.index = index;

    if (children) {
      return renderRoomNavItem(index);
    } else {
      // Render a regular menu item
      return (
        <MenuItem
          key={index}
          component={<MenuItemButton />}
          prefixIcon={prefixIcon}
          suffixIcon={suffixIcon}
          index={index}
          disabled={content === "Introduction" && !game.displayIntroduction}
        >
          <div className="ap-ml-spacing-3">
            <span className="ap-font-weight-2">{content}</span>
            {(!game.displayIntroduction && content === "Introduction") && (
              <button
                className={styles.addRoomButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMenuItemClick([0]);
                }}
              >
                • Add Intro
              </button>
            )}
          </div>
        </MenuItem>
      );
    }
  }

  // Function to generate the menu from the array of objects
  function generateMenu(menuItems: menuItemProps[]) {
    let count = 0;
    const increment = () => {
      count++;
      return count - 1;
    };
    return (
      <Menu className={styles.sidebarMenu}>
        {menuItems.map(item => renderMenuItem(item, [increment()]))}
      </Menu>
    );
  }

  return (
    <div className={styles.sidebar}>
      <Sidebar className={styles.sidebar}>
        {generateMenu(menuItems)}
      </Sidebar>
    </div>
  );

  function getQuestionCount(room) {
    let count = 0;

    room.objects?.forEach(obj => {
      obj.actions?.forEach(action => {
        count += action.questionnaire?.questions?.length || 0;
      });
    });

    return count;
  }

};

export default GameEditorSidebar;
