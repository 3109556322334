import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../../RoomObject/BaseRoomObject";
import PopupBackground from "../../../ui/PopupBackground";
import { Colors, SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";
import { IQuestion, IActiveQuestionnaire } from "escape-rooms-types/types/game";
import Question from "./Question";
import Introduction from "./Introduction";
import Summary from "./Summary";
import GameScene from "@/phaser/scenes/GameScene";
import PreviewRoomObject from "@/phaser/classes/RoomObject/PreviewRoomObject";
import GameRoomObject from "@/phaser/classes/RoomObject/GameRoomObject";
import EventBridge from "@/utils/EventBridge";
import { PhaserGameClass } from "@/components/phaser-game/PhaserGame";

export default class Questionnaire extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly actionId: string;
  private readonly callback: () => void;
  private readonly popupBackground: PopupBackground;
  private readonly timerEvents: Phaser.Time.TimerEvent[] = [];
  private readonly background: Phaser.GameObjects.Graphics;
  private readonly introduction: Introduction;
  private readonly summary: Summary;

  private readonly questions: IQuestion[];

  private readonly WIDTH = 1285;
  private readonly HEIGHT = 670;
  private readonly RADIUS = 0;

  private currentQuestionIndex = 0;
  private questionControl: Question;
  private waitingForOtherPlayers: boolean = false;
  private activeQuestionnaire: IActiveQuestionnaire;

  constructor(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    actionId: string,
    questions: IQuestion[],
    callback: () => void = () => {},
    activeQuestionnaire?: IActiveQuestionnaire
  ) {
    super(scene, 0, 0);

    this.scene = scene;
    this.sourceObject = sourceObject;
    this.actionId = actionId;
    this.questions = questions;
    this.callback = callback;
    this.activeQuestionnaire = activeQuestionnaire;

    if (activeQuestionnaire && activeQuestionnaire.currentActiveQuestionIds) {
      const gameScene = this.getGameScene();

      if (gameScene !== undefined) {
        this.currentQuestionIndex = questions.findIndex(
          (q) =>
            q._id ===
            activeQuestionnaire.currentActiveQuestionIds[gameScene.user!.id]
        );
      }
    }

    this.popupBackground = new PopupBackground(scene);
    this.add(this.popupBackground);

    this.background = new Phaser.GameObjects.Graphics(this.scene);
    this.background.fillStyle(Colors.Black, 1);
    this.background.fillRoundedRect(
      SCREEN_CENTER_X - this.WIDTH / 2,
      SCREEN_CENTER_Y - this.HEIGHT / 2,
      this.WIDTH,
      this.HEIGHT,
      this.RADIUS
    );
    this.add(this.background);

    this.introduction = new Introduction(
      this.scene,
      this.sourceObject,
      this.onStart,
      this.WIDTH,
      this.HEIGHT
    );
    this.introduction.setPosition(
      SCREEN_CENTER_X - this.WIDTH / 2,
      SCREEN_CENTER_Y - this.HEIGHT / 2
    );
    this.introduction.setSize(this.WIDTH, this.HEIGHT);
    this.add(this.introduction);

    this.questionControl = new Question(
      this.scene,
      this.sourceObject,
      this.onValidAnswerSelected,
      this.onInvalidAnswerSelected
    );
    this.questionControl.setPosition(
      SCREEN_CENTER_X - this.WIDTH / 2,
      SCREEN_CENTER_Y - this.HEIGHT / 2
    );

    this.summary = new Summary(this.scene, this.WIDTH, this.HEIGHT);
    this.summary.setPosition(
      SCREEN_CENTER_X - this.WIDTH / 2,
      SCREEN_CENTER_Y - this.HEIGHT / 2
    );
    this.summary.setSize(this.WIDTH, this.HEIGHT);
    this.summary.setVisible(false);
    this.add(this.summary);

    this.scene.add.existing(this);

    this.addEventListeners();
  }

  private getGameScene(): GameScene | undefined {
    if (this.scene.sourceScene instanceof GameScene == false) {
      return undefined;
    }

    const gameScene = this.scene.sourceScene as GameScene;
    if (!gameScene) {
      return undefined;
    }

    return gameScene;
  }

  private addEventListeners() {
    const gameScene = this.getGameScene();

    if (gameScene === undefined) {
      return;
    }

    gameScene.game.socket.on("markUserAsDone", (payload) => {
      this.rerenderPlayers();
    });

    gameScene.game.socket.on("leaveSession", async (payload) => {
      await gameScene.session!.refetchSession();
    });

    gameScene.game.socket.on("joinSession", async (payload) => {
      await gameScene.session!.refetchSession();
    });

    EventBridge.on("session.refetched", () => {
      this.rerenderPlayers();
    });

    gameScene.game.socket.once("questionnaireFinished", (payload) => {
      if (payload.actionId !== this.actionId) {
        return;
      }

      EventBridge.emit("ui.setProgress", payload.scores.progress);
      EventBridge.emit("ui.setScore", payload.scores.teamScore);

      this.removeEventListerners();
      this.summary.markAllAsFinished();

      setTimeout(() => {
        this.onComplete();
      }, 2000);
    });
  }

  private onInvalidAnswerSelected = (answer: string) => {
    const gameScene = this.getGameScene();

    if (gameScene === undefined) {
      return;
    }

    gameScene.game.socketEmit("incorrectPlayerAnswer", {
      sessionId: gameScene.session!.id,
      playerId: gameScene.user!.id,
      answer: answer,
    });
  };

  private onValidAnswerSelected = () => {
    this.questionControl.disableInteractive();

    const gameScene = this.getGameScene();

    if (gameScene !== undefined) {
      gameScene.game.socketEmit("markQuestionForUserAsDone", {
        sessionId: gameScene.session!.id,
        questionId: this.questions[this.currentQuestionIndex]._id,
        roomId: gameScene.session!.activeRoomId,
        roomObjectId: this.sourceObject._id,
        actionId: this.actionId,
      });
    }

    const timerEvent = this.scene.time.delayedCall(1500, () => {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.displayActiveQuestion();
      } else {
        this.displayCompleteScreen();
      }
    });

    this.timerEvents.push(timerEvent);
  };

  private onStart = () => {
    this.introduction.destroy();

    const gameScene = this.getGameScene();

    if (gameScene !== undefined) {
      if (
        this.activeQuestionnaire &&
        this.activeQuestionnaire.finishedPlayers.includes(gameScene.user!.id!)
      ) {
        this.waitingForOtherPlayers = true;
        this.currentQuestionIndex = this.questions.length;
        this.displayCompleteScreen();

        return;
      }
    }

    this.add(this.questionControl);
    this.displayActiveQuestion();
  };

  private onComplete() {
    this.removeEventListerners();

    if (this.scene.env === "preview") {
      (this.sourceObject as PreviewRoomObject).incrementProgress();
      (this.sourceObject as PreviewRoomObject).completeAction();
    } else {
      (this.sourceObject as GameRoomObject).completeAction(
        this.scene.sourceScene.user.id!
      );
    }

    this.scene.activeQuestionnaire = undefined;

    if (this.scene.isTimeOut) {
      const gameScene = this.getGameScene();

      if (gameScene !== undefined && gameScene.session !== undefined) {
        if (!gameScene.session.completedQuestionnaires) {
          gameScene!.session.completedQuestionnaires = [];
        }

        gameScene.session.completedQuestionnaires.push(this.actionId);
        this.scene.hud.startClock();
      }
    }

    this.destroy();
  }

  private displayActiveQuestion() {
    this.questionControl.setQuestion(
      this.questions[this.currentQuestionIndex],
      this.currentQuestionIndex,
      this.questions.length
    );
  }

  private displayCompleteScreen() {
    this.questionControl.visible = false;
    this.summary.visible = true;

    if (this.scene.env === "game") {
      return;
    } else if (this.scene.env === "preview") {
      setTimeout(() => {
        this.summary.markAllAsFinished();
        this.onComplete();
      }, 1500);
      return;
    }
  }

  private removeEventListerners() {
    if (this.scene.sourceScene instanceof GameScene) {
      const gameScene = this.scene.sourceScene as GameScene;
      const game = gameScene.game as PhaserGameClass;

      game.socket.off("markUserAsDone");
      game.socket.off("incorrectPlayerAnswer");
      game.socket.off("nextQuestion");
      game.socket.off("questionnaireFinished");

      EventBridge.remove("session.refetched");
    }
  }

  private rerenderPlayers() {
    this.renderPlayers();
  }

  private renderPlayers() {
    this.summary.rerenderPlayers();
  }
}
