import React from "react";
import {
  Button,
  Tooltip,
  Modal,
  TextArea,
  Upload,
  Switch,
  Input,
  Notification,
  toaster,
  Notice,
} from "@appkit4/react-components";
import { useState, useEffect } from "react";
import { useModal } from "./UnsavedChangesContext";
import { useGameStore } from "@/store/game-store";
import { IRoomEditorObject } from "@/types/game";
import styles from "./Room.module.scss";
import { useMutation } from "react-query";
import { updateGame } from "@/api/game";
import { renderNotification } from "../notification/renderFunctions";

type Props = {
  roomIndex: number;
};

const Room = ({ roomIndex }: Props) => {
  const theme = useGameStore((state) => state.game.industry);
  const game = useGameStore((state) => state.game);
  const rooms = useGameStore((state) => state.game.rooms);
  const secondRoom = rooms[1];
  const thirdRoom = rooms[2];
  console.log("rooms", rooms);
  console.log("game state theme", theme);
  console.log('room index', roomIndex);

  const roomContent = rooms[roomIndex - 1];
  var isRoomActive = roomContent.isActive
  console.log('roomContent', roomContent);

  //console.log('isRoomActive1', isRoomActive1); 

  if (!roomContent) {
    console.warn(`No content found for index ${roomIndex} and theme ${theme}`);
    return null;
  }
  const formatListItem = (text) => {
    console.log("isRoomActive", isRoomActive);
    const [boldPart, ...rest] = text.split(":");
    return (
      <>
        <strong>{boldPart}:</strong>
        {rest.join(":")}
      </>
    );
  };

  const modalContext = useModal();
  const isModalOpen = modalContext?.isModalOpen || false;
  const showModal = modalContext?.showModal || (() => { });
  const hideModal = modalContext?.hideModal || (() => { });
  //const [isRoomActive, setIsRoomActive] = useState(roomContent.isActive);
  // const [activeRoom, setActiveRoom] = useState(roomContent.isActive);
  //const [needsRoomSave, setNeedsRoomSave] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const toggleRoomActiveMutation = useMutation({
    mutationKey: ["toggleRoomActive", game._id, roomIndex],
    mutationFn: async (active: boolean) => {
      const updatedRooms = [...rooms];
      updatedRooms[roomIndex - 1] = {
        ...updatedRooms[roomIndex - 1],
        isActive: active,
      };
      console.log("updatedRooms", updatedRooms);
      console.log("updatedRooms[roomIndex - 1].isActive", updatedRooms[roomIndex - 1].isActive);
      return updateGame(game._id, { ...game, rooms: updatedRooms });
    },
    onSuccess: (response, newActive) => {
      useGameStore.getState().setGame(response.data.data);
      renderNotification(`Room ${roomIndex} is ${newActive ? "active" : "unactive"}`, "success");
    },
    onError: () => {
      renderNotification("Could not update the room status", "error");
    },
  });

  const handleSavedClose = () => {
    console.log("Closed!");
  };

  const handleSwitchChange = () => {
    if (isRoomActive) {
      // If currently active, open modal
      setShowRemoveModal(true);
    } else {
      // Otherwise directly toggle on
      toggleRoomActiveMutation.mutate(true);
    }
  };

  const handleRemoveRoom = () => {
    toggleRoomActiveMutation.mutate(false);
    setShowRemoveModal(false);
  };

  const onHeaderChange = (value: any, event: any) => {
    isRoomActive = value;
    //  setIsRoomActive(value);
    modalContext?.setIsChanged(true);
    console.log("game editor is changed: ", modalContext?.isChanged);
  };

  const handleNoticeClose = () => {
    console.log("Closed!");
  };

  const thirdRoomNotice = (
    <Notice
      title="Note"
      message="You can only add this room if you have already added room 2"
      status="icon-information-outline"
      closeable={false}
      className={styles.gameNotice}
    ></Notice>
  );

  const roomGameTimeNotice = (
    <Notice
      title="Note before adding this room"
      message="Adding this room will extend your game time by 15 minutes."
      status="icon-information-outline ap-font-16"
      closeable={false}
      className={styles.gameNotice}
    ></Notice>
  );

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={styles.headerContainer} style={{ width: "940px" }}>
          <h1 className="ap-typography ap-font-weight-2 ap-mb-spacing-4">
            {roomContent.name}
          </h1>
        </div>
        <div className="ap-typography-body" style={{ width: "940px" }}>
          <span>{`${roomContent.numberOfPuzzles} puzzles`}</span>
          <span> • </span>
          <span>{`${roomContent.puzzleTime} min`}</span>
          <span> • </span>
          <span>{`${getQuestionCount(roomContent)} training questions`}</span>
        </div>
        {roomContent.order !== 0 && (
          <Tooltip
            trigger="hover"
            position="top-left"
            distance={4}
            id="tooltipDesc"
            appendAfterTarget={true}
            content="Toggle ON to add the room. Toggle OFF to remove. Any added content remains when the toggle is off and can be reinstated by turning it back ON."
          >
            <Switch
              checked={isRoomActive}
              onChange={handleSwitchChange}
              disabled={(!secondRoom.isActive && roomContent.order === 2) || (thirdRoom.isActive && roomContent.order === 1)}
              style={{ paddingBottom: "10px", paddingTop: "20px" }}
            >
              Add Room
            </Switch>
          </Tooltip>
        )}

        <Modal
          visible={showRemoveModal}
          title={"Remove room?"}
          ariaLabel={"Remove room?"}
          modalStyle={{ width: "33.75rem" }}
          footerStyle={{
            paddingTop: "8px",
            marginTop: "-8px",
            minHeight: "64px",
          }}
          onCancel={() => {
            setShowRemoveModal(false);
          }}
          header={""}
          icons={""}
          footer={
            <>
              <Button onClick={() => setShowRemoveModal(false)} kind="secondary">
                Cancel
              </Button>
              <Button onClick={handleRemoveRoom}>Remove room</Button>
            </>
          }
          bodyStyle={{ minHeight: "92px" }}
        >
          <p>
            This room will be hidden from players upon removal. Rest assured, any
            training questions added will be safely stored.
            <br />
            <br />
            Please move content to other rooms before disabling if needed.
            <br />
            <br />
            Re-enabling later will restore all content as you left it, ready for
            edits or use.
            <br />
          </p>
        </Modal>

        {roomContent.order >= 1 && !roomContent.isActive && !(secondRoom.isActive && roomContent.order === 1) && !(!secondRoom.isActive && roomContent.order === 2) && roomGameTimeNotice}
        {!secondRoom.isActive && roomContent.order === 2 && thirdRoomNotice}
        <div className={styles.divider}></div>
        <p
          className="ap-typography-body"
          style={{ marginBottom: "20px", width: "940px" }}
        >
          {roomContent.description}
        </p>
        <ul className="ap-typography-body">
          {roomContent.bullets.map((bullet: any, index: any) => (
            <li key={index}>{formatListItem(bullet)}</li>
          ))}
        </ul>
      </div>
    </>
  );

  function getQuestionCount(room) {
    let count = 0;
  
    room.objects?.forEach(obj => {
      obj.actions?.forEach(action => {
        count += action.questionnaire?.questions?.length || 0;
      });
    });
  
    return count;
  }

};

export default Room;
