import Phaser from "phaser";
import gameConfig from "../../phaser/game-config";
import { useEffect, useRef } from "react";
import { IGameSession } from "escape-rooms-types/types/game";
import styles from "./PreviewGame.module.scss";
import { getGameSession } from "@/api/session";
import { useQuery } from "react-query";
import { io, Socket } from "socket.io-client";
import { WS_URL } from "@/constants";
import { v4 } from "uuid"

interface IUser {
  id?: string;
  firstName?: string;
  lastName?: string;
}

export class PhaserGameClass extends Phaser.Game {
  public session: IGameSession;
  public env: string;
  public user: IUser;
  public socket: Socket;
  public assets: any[];

  constructor(
    gameConfig: Phaser.Types.Core.GameConfig,
    session: IGameSession,
    user: IUser,
    assets: any[],
  ) {
    super(gameConfig);
    this.session = session;
    this.socket = io(WS_URL, {
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
      },
    });
    this.env = "game";
    this.user = user;
    this.assets = assets;
  }

  socketEmit(eventName: string, eventData: any) {
    const customField = 'traceId';
    const dataWithCustomField = {
      ...eventData,
      [customField]: v4()
    }
    this.socket.emit(eventName, dataWithCustomField);
  }
}

const PhaserGame = ({
  sessionId,
  user,
  assets,
}: {
  sessionId: string;
  user: IUser;
  assets: any[];
}) => {
  const phaserGameRef = useRef<PhaserGameClass | null>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const { data, isSuccess } = useQuery({
    queryKey: ["getSession", sessionId],
    queryFn: () => getGameSession(sessionId),
    cacheTime: 0,
  });

  useEffect(() => {
    // Set up
    if (phaserGameRef.current || data === undefined) {
      return;
    }

    const session = data.data.data;

    phaserGameRef.current = new PhaserGameClass(gameConfig, session, user, assets);

    phaserGameRef.current.events.on("ready", () => {
      if (phaserGameRef.current == null) {
        return;
      }
    });

    return () => {
      phaserGameRef.current?.destroy(true);
      phaserGameRef.current = null;
    };
  }, [data, assets, user]);

  return (
    <div className={styles.container}>
      <div
        className={styles.phaserContainer}
        id="phaser-game-container"
        ref={divRef}
      ></div>
    </div>
  );
};

export default PhaserGame;
