import PreviewScene from "@/phaser/scenes/PreviewScene";
import EventBridge from "@/utils/EventBridge";
import {
  IMediaPayload,
  IModal,
  IQuestionnaire,
  IRoomObjectPropertyUpdate,
  IStateBasedPayload,
} from "escape-rooms-types/types/game";
import { ItemModalPayload, TextModalPayload } from "../modals/ModalPayloads";
import BaseAction from "./BaseAction";
import PreviewRoomObject from "../RoomObject/PreviewRoomObject";
import { ImagePopupPayload } from "../modals/ImagePopupPayload";

export default class PreviewAction extends BaseAction {
  declare scene: PreviewScene;
  declare sourceObject: PreviewRoomObject;

  constructor(
    scene: PreviewScene,
    sourceObject: PreviewRoomObject,
    score: number,
    id: string,
    ref: string,
    trigger: "onClick" | "afterPrevious" | "onItemUse",
    type:
      | "changeSelf"
      | "changeOther"
      | "displayImage"
      | "displayImageByState"
      | "displayUnlockScreen"
      | "displayPuzzle"
      | "displayMessage"
      | "displayMessageTooltip"
      | "displayMedia"
      | "displayQuestionnaire"
      | "displayChallenge"
      | "addToInventory"
      | "endRoom"
      | "endGame",
    changeObjectPayload?: IRoomObjectPropertyUpdate,
    modalPayload?: IModal,
    questionnaire?: IQuestionnaire,
    triggerItemRef?: string,
    mediaPayload?: IMediaPayload,
    repeatable?: boolean,
    stateBasedPayload?: IStateBasedPayload
  ) {
    super(
      scene,
      sourceObject,
      score,
      id,
      ref,
      trigger,
      type,
      changeObjectPayload,
      modalPayload,
      questionnaire,
      triggerItemRef,
      mediaPayload,
      repeatable,
      stateBasedPayload
    );
    this.scene = scene;
    this.sourceObject = sourceObject;
  }

  executeAction() {
    if (this.score > 0) {
      EventBridge.emit("ui.showXPAlert", this.score);
      EventBridge.emit("ui.addToTeamScore", this.score);
      EventBridge.emit("ui.addToPlayerScore", this.score);
    }
    switch (this.type) {
      case "changeSelf":
        this.updateObjectProperties(this.sourceObject.ref);
        break;
      case "changeOther":
        this.updateObjectProperties(this.changeObjectPayload?.targetObjectRef!);
        break;
      case "displayImage":
      case "displayImageByState":
        this.displayImage();
        break;
      case "displayUnlockScreen":
        const unlockScreenPayload = {
          text: this.modalPayload?.message || "",
          description: this.modalPayload?.title || "",
          sourceObject: this.sourceObject,
          callback: () => {
            if (this.repeatable) {
              return
            }
          },
        } satisfies TextModalPayload;
        EventBridge.emit("ui.openUnlockScreen", unlockScreenPayload);
        break;
      case "displayPuzzle":
        const puzzlePayload = {
          puzzleName: this.modalPayload.message,
          sourceObject: this.sourceObject,
          callback: () => {
            if (this.repeatable) {
              return
            }
          },
        }
        EventBridge.emit("ui.openPuzzle", puzzlePayload);
        break;
      case "displayMessage":
        const textModalPayload = {
          text: this.modalPayload?.message || "",
          sourceObject: this.sourceObject,
          callback: () => {
            if (this.repeatable) {
              return
            }
          },
        } satisfies TextModalPayload;
        EventBridge.emit("ui.openMessagePopup", textModalPayload);
        break;
      case "displayMessageTooltip":
        const textTooltipPayload = {
          text: this.modalPayload?.message || "",
          sourceObject: this.sourceObject,
          callback: () => {
            if (this.repeatable) {
              return;
            }
          },
        } satisfies TextModalPayload;
        EventBridge.emit("ui.openMessageTooltip", textTooltipPayload);
        break;
      case "displayQuestionnaire":
        let questionnairePayload = {
          actionId: this.id,
          questions: this.questionnaire?.questions,
          sourceObject: this.sourceObject,
        };
        if (this.questionnaire?.questions?.length || 0 > 0) {
          EventBridge.emit("ui.openQuestionnaire", questionnairePayload);
        } else {
          EventBridge.emit("ui.openMessagePopup", {
            text: "Questionnaire does not have questions",
            sourceObject: this.sourceObject,
          } satisfies TextModalPayload);
        }
        break;
      case "displayChallenge":
        let challengePayload = {
          questions: this.questionnaire?.questions,
          sourceObject: this.sourceObject,
          continueCallback: () => {
            this.sourceObject.completeAction();
          },
        };
        if (this.questionnaire?.questions?.length || 0 > 0) {
          EventBridge.emit("ui.openIndividualChallenge", challengePayload);
        } else {
          EventBridge.emit("ui.openMessagePopup", {
            text: "Individual challenge does not have questions",
            sourceObject: this.sourceObject,
          } satisfies TextModalPayload);
        }
        break;
      case "addToInventory":
        if (this.modalPayload != null) {
          let addToInventorypayload: ItemModalPayload = {
            text: this.modalPayload?.message || "placeholder",
            object: this.scene.roomObjects[this.sourceObject.ref],
            env: "preview",
          };
          EventBridge.emit("ui.openItemModal", addToInventorypayload);
          break;
        }
        EventBridge.emit("ui.addItemToInventory", this.sourceObject.gameObject);
        break;
      case "displayMedia":
        EventBridge.emit("ui.openMediaModal", {
          type: this.mediaPayload?.type || "image",
          mediaUrl: this.mediaPayload?.mediaUrl,
          optionalMessage: this.mediaPayload?.message,
          object: this.sourceObject,
          repeatable: this.repeatable,
        });
        break;
      case "endRoom":
        EventBridge.emit("ui.endRoom", () => {
          this.scene.scene.pause("preview-scene");
          this.scene.scene.pause("ui-scene");
        });
        break;
      case "endGame":
        EventBridge.emit("ui.endGame", () => {
          this.scene.scene.pause("preview-scene");
          this.scene.scene.pause("ui-scene");
        });
        break;
      default:
        break;
    }
  }
  
  private displayImage() {
    let text = "";
    let description = "";

    if (this.stateBasedPayload && this.stateBasedPayload.length > 0) {
      const currentStateConfig = this.stateBasedPayload.find(p => p.state === this.sourceObject.currentState);
      
      text = currentStateConfig.title;
      description = currentStateConfig.message;
    }
    else {
      text = this.modalPayload?.message || "";
      description = this.modalPayload?.title || "";
    }

    const imagePopupPayload = new ImagePopupPayload(text, description);
    EventBridge.emit("ui.openImagePopup", imagePopupPayload);
  }
}
