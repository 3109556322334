import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import PuzzleBase, { StringStatePayload } from "./PuzzleBase";
import { Colors } from "@/constants";

export default class Safebox extends PuzzleBase<StringStatePayload> {
  private static readonly HINT_MESSAGE: string =
    "Might want to have a look at the painting while the lights on";

  private readonly buttonTextControls: Array<Phaser.GameObjects.Text> = [];
  private readonly buttonGraphics: Array<Phaser.GameObjects.Graphics> = [];

  private readonly INITIAL_BUTTON_COLOR = 0x000000;
  private readonly ALTERNATE_BUTTON_COLOR = 0x2b2b2b;

  private codeTextObject!: Phaser.GameObjects.Text;
  private textBackground!: Phaser.GameObjects.Graphics;

  private readonly VALID_ANSWER: string = "546";
  private readonly INITIAL_TEXT: string = "Enter the code";

  private readonly gridConfiguration = {
    x: 0,
    y: 0,
    paddingX: 40,
    paddingY: 40,
    cardWidth: 70,
    cardHeight: 70,
    numberOfColumns: 3,
    buttonRadius: 4,
    includeZero: false,
    buttonTextFontStyle: {
      fontFamily: "Arial",
      fontSize: "48px",
      color: "#ffffff",
      align: "center",
      fontStyle: "bold",
    },
    buttonTextAlternateColor: "#ffffff",
  };

  private readonly numbersDisplayConfiguration = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    radius: 4,
    backgroundColor: {
      default: Colors.Black,
      correct: "green",
      incorrect: "red",
    },
    fontStyle: {
      fontFamily: "Arial",
      fontSize: "20px",
      color: "#ffffff",
      align: "left",
      fontStyle: "bold",
    },
  };

  constructor(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    actionId: string,
    callback: () => void = () => {}
  ) {
    super(scene, sourceObject, actionId, callback, Safebox.HINT_MESSAGE);

    this.gridConfiguration.x =
      this.scene.scale.width / 2 + this.gridConfiguration.paddingX - 3;
    this.gridConfiguration.y =
      this.scene.scale.height / 2 -
      (3 * this.gridConfiguration.cardHeight +
        2 * this.gridConfiguration.paddingY) /
        2;

    const NUMBERS_DISPLAY_WIDTH = 316;
    const NUMBERS_DISPLAY_HEIGHT = 290;

    this.numbersDisplayConfiguration.x =
      this.gridConfiguration.x -
      (NUMBERS_DISPLAY_WIDTH + this.gridConfiguration.paddingX + 8);
    this.numbersDisplayConfiguration.y =
      this.scene.scale.height / 2 - NUMBERS_DISPLAY_HEIGHT / 2;
    this.numbersDisplayConfiguration.width = NUMBERS_DISPLAY_WIDTH;
    this.numbersDisplayConfiguration.height = NUMBERS_DISPLAY_HEIGHT;

    const BACKGROUND_WIDTH = 768;
    const BACKGROUND_HEIGHT = 370;

    const keypadBackground = new Phaser.GameObjects.Graphics(this.scene);
    keypadBackground.fillStyle(0xffffff, 1);
    keypadBackground.fillRoundedRect(
      this.scene.scale.width / 2 - BACKGROUND_WIDTH / 2,
      this.scene.scale.height / 2 - BACKGROUND_HEIGHT / 2,
      BACKGROUND_WIDTH,
      BACKGROUND_HEIGHT,
      20
    );

    this.add(keypadBackground);

    this.initializeTextArea();
    this.initializeButtons();

    this.scene.add.existing(this);
  }

  protected updatePuzzle(currentState: StringStatePayload): void {
    this.updateCodeText(currentState.value);
  }

  protected isPuzzleStatePayloadValid(payload): boolean {
    return (
      typeof payload === "object" &&
      payload !== null &&
      "value" in payload &&
      typeof payload.value === "string"
    );
  }

  protected removeEventHandlers(): void {}

  private updateCodeText(code: string) {
    this.updateText(code);
    this.checkIfComplete();
  }

  private updateText(currentText: string) {
    this.codeTextObject.setText(currentText);
    this.codeTextObject.setX(
      this.numbersDisplayConfiguration.x +
        (this.numbersDisplayConfiguration.width - this.codeTextObject.width) / 2
    );
  }

  private initializeTextArea() {
    this.textBackground = new Phaser.GameObjects.Graphics(this.scene);
    this.textBackground.fillStyle(
      this.numbersDisplayConfiguration.backgroundColor.default,
      1
    );

    this.textBackground.fillRoundedRect(
      this.numbersDisplayConfiguration.x,
      this.numbersDisplayConfiguration.y,
      this.numbersDisplayConfiguration.width,
      this.numbersDisplayConfiguration.height,
      this.numbersDisplayConfiguration.radius
    );

    this.add(this.textBackground);

    const x =
      this.numbersDisplayConfiguration.x +
      this.numbersDisplayConfiguration.width / 2;
    const y =
      this.numbersDisplayConfiguration.y +
      this.numbersDisplayConfiguration.height / 2 -
      10;

    this.codeTextObject = new Phaser.GameObjects.Text(
      this.scene,
      x,
      y,
      "Enter the code",
      this.numbersDisplayConfiguration.fontStyle
    );

    this.codeTextObject.setX(
      this.numbersDisplayConfiguration.x +
        (this.numbersDisplayConfiguration.width - this.codeTextObject.width) / 2
    );
    this.add(this.codeTextObject);
  }

  private initializeButtons() {
    for (let i = 0; i < 10; i++) {
      const x =
        this.gridConfiguration.x +
        (this.gridConfiguration.cardWidth + this.gridConfiguration.paddingX) *
          (i % this.gridConfiguration.numberOfColumns);
      const y =
        this.gridConfiguration.y +
        (this.gridConfiguration.cardHeight + this.gridConfiguration.paddingY) *
          Math.floor(i / this.gridConfiguration.numberOfColumns);
      const currentNumber = (i + 1) % 10;

      if (currentNumber === 0 && !this.gridConfiguration.includeZero) {
        continue;
      }

      const buttonBackground = new Phaser.GameObjects.Graphics(this.scene);

      buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
      buttonBackground.fillRoundedRect(
        x,
        y,
        this.gridConfiguration.cardWidth,
        this.gridConfiguration.cardHeight,
        5
      );
      buttonBackground.setInteractive(
        new Phaser.Geom.Rectangle(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight
        ),
        Phaser.Geom.Rectangle.Contains
      );
      this.buttonGraphics.push(buttonBackground);

      const buttonText = new Phaser.GameObjects.Text(
        this.scene,
        x + this.gridConfiguration.cardWidth / 2,
        y + this.gridConfiguration.cardHeight / 2,
        currentNumber.toString(),
        this.gridConfiguration.buttonTextFontStyle
      )
        .setOrigin(0.5)
        .setName(currentNumber.toString())
        .setInteractive();

      this.buttonTextControls.push(buttonText);

      buttonText.on(Phaser.Input.Events.POINTER_DOWN, () => {
        this.onClickCallback(buttonText.name);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_DOWN, () => {
        this.onClickCallback(buttonText.name);
      });

      buttonText.on(Phaser.Input.Events.POINTER_OVER, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.ALTERNATE_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextAlternateColor);
      });

      buttonText.on(Phaser.Input.Events.POINTER_OUT, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextFontStyle.color);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_OVER, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.ALTERNATE_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextAlternateColor);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_OUT, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(
          x,
          y,
          this.gridConfiguration.cardWidth,
          this.gridConfiguration.cardHeight,
          this.gridConfiguration.buttonRadius
        );
        buttonText.setColor(this.gridConfiguration.buttonTextFontStyle.color);
      });
    }

    this.add(this.buttonGraphics);
    this.add(this.buttonTextControls);
  }

  private updateButtonStyle(
    buttonBackground: Phaser.GameObjects.Graphics,
    buttonText: Phaser.GameObjects.Text,
    backgroundColor: number,
    textColor: string
  ): void {
    buttonBackground.clear();
    buttonBackground.fillStyle(backgroundColor);
    buttonBackground.fillRoundedRect(
      buttonBackground.x - this.gridConfiguration.cardWidth / 2,
      buttonBackground.y - this.gridConfiguration.cardWidth / 2,
      this.gridConfiguration.cardWidth,
      this.gridConfiguration.cardHeight,
      this.gridConfiguration.buttonRadius
    );
    buttonText.setColor(textColor);
  }

  private onClickCallback(value: string) {
    let currentText = this.codeTextObject.text;

    if (currentText === this.INITIAL_TEXT) {
      currentText = "";
    }

    if (currentText.length >= this.VALID_ANSWER.length) {
      return;
    }

    const code = currentText + value.toString();
    const puzzleStatePayload = { value: code };
    this.emitPuzzleStateChanged(puzzleStatePayload);
  }

  private checkIfComplete() {
    const currentCode = this.codeTextObject.text;

    if (currentCode === this.VALID_ANSWER) {
      this.codeTextObject.setColor("white");
      this.updateText("Correct!");
      this.setTextBackgroundColor(Colors.Green);
      this.completePuzzle();
    } else if (currentCode.length == this.VALID_ANSWER.length) {
      const changeColorTimerEvent = this.scene.time.delayedCall(500, () => {
        this.codeTextObject.setColor("white");
        this.updateText("Wrong code");
        this.setTextBackgroundColor(Colors.Red);
      });

      this.timerEvents.push(changeColorTimerEvent);

      const initializeTextTimerEvent = this.scene.time.delayedCall(2000, () => {
        this.initializeTextArea();
      });

      this.timerEvents.push(initializeTextTimerEvent);
    }
  }

  private setTextBackgroundColor(color: number) {
    this.textBackground.clear();
    this.textBackground.fillStyle(color, 1);
    this.textBackground.fillRoundedRect(
      this.numbersDisplayConfiguration.x,
      this.numbersDisplayConfiguration.y,
      this.numbersDisplayConfiguration.width,
      this.numbersDisplayConfiguration.height,
      this.numbersDisplayConfiguration.radius
    );
  }
}
