import UIScene from "@/phaser/scenes/UIScene";
import { generateFakePlayers } from "@/constants";
import Player from "@/phaser/classes/session/Player";
import GameScene from "@/phaser/scenes/GameScene";
import QuestionnairePlayer from "../../questionnaire/QuestionnairePlayer";
import PlayerAvatar from "@/phaser/classes/PlayerAvatar";

export default class Summary extends Phaser.GameObjects.Container {
    public readonly scene: UIScene;
  
    private readonly message: Phaser.GameObjects.Text;
    private readonly waitingForOtherPlayers: boolean = false;
    private readonly playerAvatars: PlayerAvatar[] = [];
    private players!: QuestionnairePlayer[];
    
    constructor(
        scene: UIScene,
        width: number, 
        height: number
    ) {
        super(scene, 0, 0);

        this.scene = scene;
        
        this.width = width;
        this.height = height;

        this.message = new Phaser.GameObjects.Text(
            scene,
            this.width / 2,
            this.height / 2 - 50,
            "Well done!\nWaiting for other players to complete...",
            {
                fontFamily: "Arial",
                fontSize: "36px",
                align: "center",
            }).setOrigin(0.5);
      
        this.add(this.message);
    }

    public rerenderPlayers() {
        this.playerAvatars.forEach(c => c.destroy());
        this.playerAvatars.length = 0;

        let players: Player[] | undefined = [];
        
        if (this.scene.env === "preview") {
            players = generateFakePlayers(5);
        } else {
            players = (this.scene.sourceScene as GameScene).session?.players;
        }

        this.players = players!
            .filter((p: Player) => p.inactive === false)
            .map((player: Player) => {
                return new QuestionnairePlayer(this.scene, player, 0, 0);
            });

        const numberOfPlayers = this.players.length;
        const cellWidth = 64;
        const startX = this.width / 2 - numberOfPlayers / 2 * cellWidth + cellWidth / 4 - 5;

        this.players.forEach((player) => this.playerAvatars.push(player.avatar));

        for (let i = 0; i < this.playerAvatars.length; i++) {
            this.add(this.playerAvatars[i]);
        }

        Phaser.Actions.GridAlign(this.playerAvatars, {
            width: 15,
            height: 1,
            cellWidth: cellWidth,
            cellHeight: cellWidth,
            x: startX,
            y: this.height / 2 + 50,
          });

        if (this.scene.env === "game") {
            const gameScene = (this.scene.sourceScene as GameScene);
            const finishedPlayers = gameScene.session!.activeQuestionnaire?.finishedPlayers;

            if (!finishedPlayers) {
                console.log(' * no finished players - probably active questionnaire is not set');
                this.players.forEach((player) => player.markAsFinished());
            }

            if (finishedPlayers && finishedPlayers.length > 0) {
                finishedPlayers.forEach((playerId: string) => {
                this.players.find((player: QuestionnairePlayer) => player.id === playerId)
                    ?.markAsFinished();
                });
            }
        }
    }

    public markAllAsFinished() {
        if (this.players) {
            this.players.forEach((player) => player.markAsFinished());
        }

        this.message.setText("Well done!");
    }
}