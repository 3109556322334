import { HEADER_HEIGHT } from "@/constants";
import GameScene from "@/phaser/scenes/GameScene";
import BaseScene from "@/phaser/scenes/BaseScene";
import EventBridge from "@/utils/EventBridge";
import UIScene from "@/phaser/scenes/UIScene";

export default class NavigatorButton extends Phaser.GameObjects.Container {
  private readonly sourceScene: BaseScene;
  public readonly scene: UIScene;

  private readonly initialsText: Phaser.GameObjects.Text;
  private readonly navigatorButton: Phaser.GameObjects.Graphics;
  private readonly tooltipBackground: Phaser.GameObjects.Graphics;
  private readonly tooltipText: Phaser.GameObjects.Text;

  constructor(
    scene: UIScene,
    sourceScene: BaseScene
  ) {    
    super(sourceScene, 0, 0);

    this.scene = scene;
    this.sourceScene = sourceScene;

    const x = scene.renderer.width - 180;
    const y = HEADER_HEIGHT / 2;

    const circleWidth = 32;

    this.navigatorButton = this.addNavigatorButton(x, y, circleWidth / 2);
    this.initialsText = this.addNavigatorInitialsText(x, y);
    this.tooltipText = this.addTooltipText(x, y + 39);
    this.tooltipBackground = this.addTooltipBackgroud(this.tooltipText);

    EventBridge.on("ui.setNavigator", () => this.setNavigator())    
  }

  private addNavigatorButton(x: number, y: number, radius: number): Phaser.GameObjects.Graphics {
    const circle = new Phaser.Geom.Circle(x, y, radius);
    const color = 0x1f2841;

    const navigatorButton = new Phaser.GameObjects.Graphics(this.scene)      
    navigatorButton.fillStyle(color, 1);
    navigatorButton.fillCircleShape(circle);
    navigatorButton.setInteractive(circle, Phaser.Geom.Circle.Contains);

    navigatorButton.on(Phaser.Input.Events.POINTER_OVER, () => this.showTooltip());
    navigatorButton.on(Phaser.Input.Events.POINTER_OUT, () => this.hideTooltip());

    this.add(navigatorButton);

    return navigatorButton;
  }

  private addNavigatorInitialsText(x: number, y: number): Phaser.GameObjects.Text {
    const initialsText = new Phaser.GameObjects.Text(
      this.scene, 
      x, y, 
      "N/A",
      {
          font: "400 14px Arial",
          color: "white"         
      }).setOrigin(0.5);

    initialsText.setInteractive();
    initialsText.on(Phaser.Input.Events.POINTER_OVER, () => this.showTooltip());
    initialsText.on(Phaser.Input.Events.POINTER_OUT, () => this.hideTooltip());
    initialsText.on(Phaser.Input.Events.POINTER_DOWN, () => this.changeNavigatorRequested());

    this.add(initialsText);

    if (this.sourceScene instanceof GameScene) {
      const gameScene = this.scene.sourceScene as GameScene;
      const currentPlayer = gameScene.session!.players.find(p => p.id === gameScene.user!.id);
      if (currentPlayer) {
        const initials = this.getInitials(currentPlayer.firstName, currentPlayer.lastName);
        initialsText.setText(initials);
      }
    }
    
    return initialsText;
  }

  private addTooltipText(x: number, y: number): Phaser.GameObjects.Text {
    const text = this.calculateTooltipText();
    const tooltipText = new Phaser.GameObjects.Text(
      this.scene,
      x, y,
      text,
      {
          fontFamily: "Arial",
          fontSize: "14px",
          color: "#000000",
          align: "center",
      }).setOrigin(0.5, 0)
      .setVisible(false);

      return tooltipText;
  }

  private addTooltipBackgroud(tooltipText: Phaser.GameObjects.Text): Phaser.GameObjects.Graphics {    
      const tooltipBackground = new Phaser.GameObjects.Graphics(this.scene);
      
      this.fillTooltipBackground(tooltipBackground, tooltipText);
      
      this.add(tooltipBackground);
      this.add(tooltipText);

      return tooltipBackground;
  }

  private fillTooltipBackground(tooltipBackground: Phaser.GameObjects.Graphics, tooltipText: Phaser.GameObjects.Text): void {
    const tooltipPadding = 8;
    
    tooltipBackground.fillStyle(0xBFC1C7, 1);
    tooltipBackground.fillRoundedRect(
      tooltipText.getTopLeft().x! - tooltipPadding, 
      tooltipText.getTopLeft().y! - tooltipPadding,
      tooltipText.width + 2 * tooltipPadding,
      tooltipText.height + 2 * tooltipPadding,
      5
    ).setVisible(false);
  }

  private showTooltip() {
    const text = this.calculateTooltipText();
    this.tooltipText.setText(text);
    this.tooltipBackground.setVisible(true);
    this.tooltipText.setVisible(true);
  }

  private hideTooltip() {
    this.tooltipBackground.setVisible(false);
    this.tooltipText.setVisible(false);
  }

  private changeNavigatorRequested() {
    if (this.sourceScene instanceof GameScene) {
      const gameScene = this.sourceScene as GameScene;
      
      if (!gameScene || gameScene.isNavigatorScene()) {
        return;
      }

      EventBridge.emit("game.navigatorChangeRequested");
    }
  }

  private setNavigator() {
    if (this.sourceScene instanceof GameScene) {
      const gameScene = this.sourceScene as GameScene
      const navigator = gameScene.navigatorPlayer;
      const intials = this.getInitials(navigator.firstName, navigator.lastName); 
      this.initialsText.setText(intials)

      const tooltipText = this.calculateTooltipText();
      this.tooltipText.setText(tooltipText);
      this.fillTooltipBackground(this.tooltipBackground, this.tooltipText);
    }
  }

  private calculateTooltipText(): string {
    if (!(this.sourceScene instanceof GameScene)) {
      return "You're the navigator";
    }

    const gameScene = this.sourceScene as GameScene;

    if (!gameScene || gameScene.isNavigatorScene()) {
       return "You're the navigator";
    }

    const navigatorName = gameScene.navigatorPlayer.getName();
    
    return `Click to replace ${navigatorName} and become the navigator`;
  }

  private getInitials(firstName: string | undefined, lastName: string | undefined): string {
    let result = "";

    if (firstName && firstName.length > 0) {
      result += firstName[0].toUpperCase();
    }

    if (lastName && lastName.length > 0) {
      result += lastName[0].toUpperCase();
    }

    if (result === "") {
      result = "N/A";
    }

    return result;
  }
}