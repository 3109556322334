import {
  Tooltip,
} from "@appkit4/react-components";
import styles from "../game-mission/GameMission.module.scss";
import { useModal } from "./UnsavedChangesContext";
import { useGameStore } from "@/store/game-store";

type Props = {
  roomIndex: number;
  puzzleIndex: number;
};

const Puzzle = ({ roomIndex, puzzleIndex }: Props) => {
  const rooms = useGameStore((state) => state.game.rooms);
  const puzzleData = getPuzzlesFromRoomWithoutTraining(rooms[roomIndex - 2])?.[puzzleIndex];
  if (!puzzleData) return null;

  const modalContext = useModal();
  const isModalOpen = modalContext?.isModalOpen || false;
  const showModal = modalContext?.showModal || (() => { });
  const hideModal = modalContext?.hideModal || (() => { });

  function getPuzzlesFromRoomWithoutTraining(room: any) {
    if (!room?.objects) return [];
    const puzzles: any[] = [];
  
    for (const obj of room.objects) {
      const actions = obj.actions || [];
      for (let i = 0; i < actions.length; i++) {
        const action = actions[i];
        if (action.type === "displayPuzzle" && action.isVisibleOnAdminPanel) {
          const puzzle: any = {
            title: action.modalPayload?.title || "Untitled",
            challengeDescription: action.description || "",
            bullets: (action.bullets || []).map((b: any) => ({
              item: b.text,
              itemBulletsDescriptions: b.children || [],
            })),
          };
          puzzles.push(puzzle);
        }
      }
    }
    return puzzles;
  }

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={styles.headerContainer}>
          <h1 className="ap-typography ap-font-weight-2">
            {puzzleData.title}
          </h1>
        </div>
        <div className={styles.inlineSpans} />
        <Tooltip
          trigger="hover"
          position="top-left"
          distance={4}
          id="tooltipDesc"
          appendAfterTarget={true}
          content="Toggle ON to add the room. Toggle OFF to remove. Any added content remains when the toggle is off and can be reinstated by turning it back ON."
        >
        </Tooltip>
        <div className={styles.divider}></div>
        <h3 className="ap-typography-body ap-font-weight-2">Overall solution path</h3>
        <p
          className="ap-typography-body"
          style={{ marginBottom: "20px", width: "940px" }}
        >
          {puzzleData.challengeDescription}
        </p>
        <h3 className="ap-typography-body ap-font-weight-2">Interactive elements and solutions</h3>
        <ul className="ap-typography-body" style={{ width: "940px" }}>
          {puzzleData.bullets.map((bullet: any, index: number) => (
            <li key={index}>
              <strong>{bullet.item}</strong>
              <ul>
                {bullet.itemBulletsDescriptions.map((desc: string, i: number) => (
                  <li
                    key={i}
                    dangerouslySetInnerHTML={{ __html: desc }}
                  />
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <Tooltip
          trigger="hover"
          position="top-left"
          distance={4}
          id="tooltipDesc"
          appendAfterTarget={true}
          content="Toggle ON to include an intro for the context; OFF for a direct game start. Intro content is retained, simply hidden from view."
        ></Tooltip>
      </div>
    </>
  );
};

export default Puzzle;
