import {
    Modal,
    Button
} from "@appkit4/react-components";

import React from "react";
import {useNavigate} from "react-router-dom";

interface GameSavedProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    createdGameId?: string;
}

const GameSaved = (props : GameSavedProps) => {
    const { visible, setVisible, createdGameId } = props;
    const navigate = useNavigate();
    return (
        <>
            <Modal
                onCancel={() => setVisible(false)} 
                visible={visible}
                title={"Your draft game is saved!"}
                ariaLabel={"Your draft game is saved!"}
                closable={true}
                maskCloseable={true}
                modalStyle={{ width: '37rem', height:'30rem'}}
                footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
                header={""}
                icons={""}
                footer={
                    <>
                        <Button onClick={() => navigate(`/games/${createdGameId}/edit`)}>Let's get started!</Button>
                    </>
                }
                bodyStyle={{ minHeight: '92px'}}
            >
                <p style ={{paddingBottom:'20px', fontSize:'18px'}}>We've prepped a basic game setup based on your preferences, you can now:</p>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ verticalAlign: 'top', padding: '8px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '20px', color: '#D04A02'}} className="Appkit4-icon icon-door-opened-outline ap-font-36 ap-mb-spacing-4" aria-hidden="true"></span>
                                    <div style={{ marginLeft: '8px', whiteSpace: 'nowrap', paddingBottom:'20px' }}>
                                        <h2 className="ap-font-weight-2 ap-mb-spacing-4" style={{fontSize:'18px'}}>Add or remove rooms</h2>
                                        <span>Adjust as needed</span>
                                    </div>
                                </div>
                            </td>
                            <td style={{ verticalAlign: 'top', padding: '8px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '20px', color: '#D04A02'}} className="Appkit4-icon icon-ask-question-outline ap-font-36 ap-mb-spacing-4" aria-hidden="true"></span>
                                    <div style={{ marginLeft: '8px', whiteSpace: 'nowrap', paddingBottom:'20px' }}>
                                    <h2 className="ap-font-weight-2 ap-mb-spacing-4" style={{fontSize:'18px'}}>Add training questions</h2>
                                        <span>Drag them to adjust flow</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top', padding: '8px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '20px', color: '#D04A02'}} className="Appkit4-icon icon-play-outline ap-font-36 ap-mb-spacing-4" aria-hidden="true"></span>
                                    <div style={{ marginLeft: '8px', whiteSpace: 'nowrap', paddingBottom:'20px' }}>
                                    <h2 className="ap-font-weight-2 ap-mb-spacing-4" style={{fontSize:'18px'}}>Add videos</h2>
                                        <span>Mind the time!</span>
                                    </div>
                                </div>
                            </td>
                            <td colSpan="2" style={{ verticalAlign: 'top', padding: '8px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '20px', color: '#D04A02'}} className="Appkit4-icon icon-image-outline ap-font-36 ap-mb-spacing-4" aria-hidden="true"></span>
                                    <div style={{ marginLeft: '8px', whiteSpace: 'nowrap' }}>
                                    <h2 className="ap-font-weight-2 ap-mb-spacing-4" style={{fontSize:'18px'}}>Add branding</h2>
                                        <span>In 'Game Settings'</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top', padding: '8px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '20px', color: '#D04A02'}} className="Appkit4-icon icon-calendar-outline ap-font-36 ap-mb-spacing-4" aria-hidden="true"></span>
                                    <div style={{ marginLeft: '8px', whiteSpace: 'nowrap', paddingBottom:'20px' }}>
                                    <h2 className="ap-font-weight-2 ap-mb-spacing-4" style={{fontSize:'18px'}}>Schedule sessions</h2>
                                        <span>Schedule & invite in "sessions"</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Modal>
        </>
    );
}

export default GameSaved;
