import React from "react";
import {
  Button,
  Modal,
  Upload,
  Switch,
  Input,
  Checkbox,
} from "@appkit4/react-components";
import styles from "../game-mission/GameMission.module.scss";
import { useState, useEffect } from "react";
import { updateGame } from "@/api/game";
import { useGameStore } from "@/store/game-store";
import { useModal } from '../game-customisation-template-pages/UnsavedChangesContext';
import { useMutation } from "react-query";
import { renderNotification } from "@/components/notification/renderFunctions";
import { deleteAssetFile, uploadAssetFile } from "@/api/assets";
import { UploadFileDescriptor } from "@/models/Files/UploadFileDescriptor";
import { v4 as uuidv4 } from "uuid";

interface TrainingQuestionProps {
  roomIndex: number;
  puzzleIndex: number;
  questionIndex: number;
  onDelete: (
    roomIndex: number,
    puzzleIndex: number,
    questionIndex: number
  ) => void;
}

const TrainingQuestion: React.FC<TrainingQuestionProps> = ({
  roomIndex,
  puzzleIndex,
  questionIndex
}) => {
  const baseUrl = window.location.origin;
  const rooms = useGameStore((state) => state.game.rooms);
  const game = useGameStore((state) => state.game);
  const activeRooms = rooms.filter((room) => room.isActive && room.isVisible);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [questionTitleError, setQuestionTitleError] = useState(false);
  const [questionBodyError, setQuestionBodyError] = useState(false);
  const [answerError, setAnswerError] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const modalContext = useModal();
  const [selectedQuestion, setSelectedQuestion] = useState({
    uiTitle: '',
    title: '',
    choices: {
      answers: ['', ''],
      correctAnswer: 0,
      randomiseAnswers: false
    },
    mediaUrl: ''
  });
  const [titleValue, setTitleValue] = useState(selectedQuestion.uiTitle);
  const [answerValues, setAnswerValues] = useState(selectedQuestion.choices.answers ?? null);
  const [bodyValue, setBodyValue] = useState(selectedQuestion.title);
  const [actualIndices, setActualIndices] = useState({ objectIndex: 0, actionIndex: 0, questionIndex: 0 });
  const [gameSaved, setGameSaved] = useState(true);
  const [randomiseAnswers, setRandomiseAnswers] = useState(false);

  const saveGameMutation = useMutation({
    mutationKey: ["saveGame", game._id],
    mutationFn: () => updateGame(game._id, game),
    onSuccess: (res) => {
      renderNotification("Game has been saved.", "success");
      useGameStore.getState().setGame(res.data.data)
    },
    onError: (err) => {
      renderNotification(err.response.data.message, "error");
    },
  });

  const uploadFileMutation = useMutation({
    mutationFn: async (file: UploadFileDescriptor) => {
      const fileExtension = file.name.split('.').pop();
      const guidFilename = `${uuidv4()}.${fileExtension}`;
      const filePath = "game-editor";
      await uploadAssetFile({ ...file, name: guidFilename }, filePath);
      const mediaId = `${filePath}/${guidFilename}`;

      setSelectedQuestion((prev) => {
        const updatedQuestion = { ...prev, mediaUrl: mediaId };
        const updatedGame = JSON.parse(JSON.stringify(game));
        updateQuestionInGame(updatedGame, updatedQuestion);
        useGameStore.getState().setGame(updatedGame);
        setGameSaved(false);
        return updatedQuestion;
      });
    },
    onSuccess: () => {
      renderNotification("File uploaded successfully!", "success");
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const deleteFileMutation = useMutation({
    mutationFn: async () => {
      if (!selectedQuestion.mediaUrl) {
        throw new Error("No file to delete.");
      }
      return deleteAssetFile(selectedQuestion.mediaUrl);
    },
    onSuccess: () => {
      setSelectedQuestion((prev) => {
        const updatedQuestion = { ...prev, mediaUrl: "" };
        const updatedGame = JSON.parse(JSON.stringify(game));
        updateQuestionInGame(updatedGame, updatedQuestion);
        useGameStore.getState().setGame(updatedGame);
        setGameSaved(false);
        renderNotification("File deleted.", "success");
        return updatedQuestion;
      })
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const handleUploadChange = (
    fileOrFiles: UploadFileDescriptor | UploadFileDescriptor[]
  ) => {
    const files = Array.isArray(fileOrFiles) ? fileOrFiles : [fileOrFiles];

    files.forEach((file) => {
      if (file.status === "deleted") {
        deleteFileMutation.mutate();
      } else if (file.status === "selected" || file.status === "ready") {
        uploadFileMutation.mutate(file);
        setFileUploaded(true);
      }
    });

    modalContext?.setIsChanged(true);
  };

  useEffect(() => {
    setRandomiseAnswers(selectedQuestion.choices.randomiseAnswers ?? false);
  }, [selectedQuestion]);


  useEffect(() => {
    if (!gameSaved) {
      saveGameMutation.mutate()
      setGameSaved(true)
    }
  }, [game])

  function updateQuestionInGame(updatedGame: any, newQuestionData: any) {
    const updatedRooms = updatedGame.rooms.map((room: any, rIndex: number) => {
      if (rIndex === roomIndex - 2) {
        const updatedObjects = room.objects.map((obj: any, oIndex: number) => {
          if (oIndex === actualIndices.objectIndex) {
            const updatedActions = obj.actions.map((action: any, aIndex: number) => {
              if (aIndex === actualIndices.actionIndex && action.type === "displayQuestionnaire") {
                action.questionnaire.questions[actualIndices.questionIndex] = {
                  ...action.questionnaire.questions[actualIndices.questionIndex],
                  mediaUrl: newQuestionData.mediaUrl,
                };
              }
              return action;
            });
            obj.actions = updatedActions;
          }
          return obj;
        });
        room.objects = updatedObjects;
      }
      return room;
    });
    updatedGame.rooms = updatedRooms;
  }

  useEffect(() => {
    let questionFound = false
    let puzzles_found = 0
    let questions_found = 0
    let actual_object_index = 0
    let actual_action_index = 0
    let actual_question_index = 0
    let puzzle_indices = { objectIndex: 0, actionIndex: 0 }
    for (const object of rooms[roomIndex - 2].objects) {
      if (puzzles_found === puzzleIndex + 1) {
        break
      }
      for (const action of object.actions) {
        if (puzzles_found === puzzleIndex + 1 && action.type === "displayPuzzle") {
          break
        }
        if (action.type === "displayPuzzle") {
          puzzles_found += 1
          puzzle_indices = { objectIndex: actual_object_index, actionIndex: actual_action_index }
        } else if (puzzles_found === puzzleIndex + 1 && action.type === "displayQuestionnaire") {
          for (const question of action.questionnaire.questions) {
            if (questions_found === questionIndex + 1) {
              break
            }
            questions_found += 1
            if (questions_found === questionIndex) {
              questionFound = true
              setActualIndices({ objectIndex: actual_object_index, actionIndex: actual_action_index, questionIndex: actual_question_index })
              break
            } else {
              actual_question_index += 1
            }
          }
        }
        if (questionFound) {
          break
        } else {
          actual_action_index += 1
        }
      }
      if (questionFound) {
        break
      } else {
        actual_action_index = 0
        actual_object_index += 1
      }
    }
    if (!questionFound) {
      setSelectedQuestion({
        uiTitle: '',
        title: '',
        choices: { answers: ['', ''] }
      })
      const puzzle_actions = rooms[roomIndex - 2].objects[puzzle_indices.objectIndex].actions
      let last_questionnaire_index = null
      for (let i = puzzle_indices.actionIndex + 1; i < puzzle_actions.length; i++) {
        if (puzzle_actions[i].type === "displayPuzzle") {
          break
        } else if (puzzle_actions[i].type === "displayQuestionnaire") {
          last_questionnaire_index = i
        }
      }
      if (last_questionnaire_index) {
        const last_questionnaire = puzzle_actions[last_questionnaire_index].questionnaire
        setActualIndices({ objectIndex: puzzle_indices.objectIndex, actionIndex: last_questionnaire_index, questionIndex: last_questionnaire.questions.length })
      } else {
        setActualIndices({ objectIndex: puzzle_indices.objectIndex, actionIndex: puzzle_indices.actionIndex + 1, questionIndex: 0 })
      }
    } else {
      setSelectedQuestion(rooms[roomIndex - 2]
        .objects[actual_object_index]
        .actions[actual_action_index]
        .questionnaire.questions[actual_question_index])
    }
  }, [roomIndex, puzzleIndex, questionIndex])

  useEffect(() => {
    setTitleValue(selectedQuestion.uiTitle);
    setAnswerValues(selectedQuestion.choices.answers ?? null);
    setBodyValue(selectedQuestion.title);
  }, [selectedQuestion])


  const updateQuestion = () => {
    const updated_game = JSON.parse(JSON.stringify(game))
    const updated_room = { ...updated_game.rooms[roomIndex - 2] }
    const updated_object = { ...updated_room.objects[actualIndices.objectIndex] }
    const blank_questionnaire_action = {
      score: 0,
      trigger: 'afterPrevious',
      type: 'displayQuestionnaire',
      changeObjectPayload: {
        ref: '',
        targetObjectRef: '',
        newProperties: {
        },
      },
      triggerItemRef: '',
      questionnaire: {
        questions: [
          {
            ref: '',
            type: '',
            uiTitle: '',
            title: '',
            choices: {
              ref: '',
              answers: [],
              correctAnswer: 0,
            },
            answer: '',
          }
        ],
      }
    }

    if (updated_object.actions.length <= actualIndices.actionIndex) {
      updated_object.actions.push(blank_questionnaire_action)
    } else if (
      updated_object.actions.length <= actualIndices.actionIndex ||
      (
        updated_object.actions[actualIndices.actionIndex] &&
        updated_object.actions[actualIndices.actionIndex].type !== "displayQuestionnaire"
      )
    ) {
      updated_object.actions.splice(actualIndices.actionIndex, 0, blank_questionnaire_action)
    }

    const updated_action = updated_object.actions[actualIndices.actionIndex]

    const updated_question = {
      uiTitle: titleValue,
      title: bodyValue,
      choices: {
        answers: answerValues,
        correctAnswer: selectedQuestion.choices.correctAnswer, // ensure correctAnswer is included
        randomiseAnswers: randomiseAnswers
      },
    };

    updated_action.questionnaire.questions[actualIndices.questionIndex] = updated_question

    updated_object.actions[actualIndices.actionIndex] = updated_action
    updated_room.objects[actualIndices.objectIndex] = updated_object
    updated_game.rooms[roomIndex - 2] = updated_room

    useGameStore.getState().setGame(updated_game)
    setGameSaved(false)

    return updated_game
  };

  const deleteQuestion = () => {
    const updated_game = JSON.parse(JSON.stringify(game))
    const updated_room = { ...updated_game.rooms[roomIndex - 2] }
    const updated_object = { ...updated_room.objects[actualIndices.objectIndex] }

    const updated_action = updated_object.actions[actualIndices.actionIndex]

    updated_action.questionnaire.questions.splice(actualIndices.questionIndex, 1)

    updated_object.actions[actualIndices.actionIndex] = updated_action
    updated_room.objects[actualIndices.objectIndex] = updated_object
    updated_game.rooms[roomIndex - 2] = updated_room

    useGameStore.getState().setGame(updated_game)
    setGameSaved(false)
    hideDeleteModal()

    return updated_game
  };


  const handleDeleteLastRow = () => {
    if (answerValues.length > 2) {
      setAnswerValues(answerValues.slice(0, -1));
    }
  };

  const handleDeleteClick = () => {
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const onTitleChange = (value: any, event: any) => {
    setTitleValue(value);
    modalContext?.setIsChanged(true);
  };

  const onBodyChange = (value: any, event: any) => {
    setBodyValue(value);
    modalContext?.setIsChanged(true);
  };

  const [isTimeImpactModalVisible, setIsTimeImpactModalVisible] = useState(false);

  const handleAddAnswer = () => {
    setAnswerValues([...answerValues, ""]);
  };

  function getGameStats(activeRooms) {
    let totalPuzzleTime = 0;
    let totalQuestions = 0;
    activeRooms.forEach((room) => {
      totalPuzzleTime += room.puzzleTime || 0;
      totalQuestions += getQuestionCount(room);
    });
    return totalPuzzleTime + totalQuestions;
  }

  function getQuestionCount(room) {
    let count = 0;
    room.objects?.forEach((obj) => {
      obj.actions?.forEach((action) => {
        if (action.type === "displayQuestionnaire" && action.questionnaire?.questions) {
          count += action.questionnaire.questions.length;
        }
      });
    });
    return count;
  }

  function getRoomsDuration(rooms) {
    return rooms.reduce((sum, room) => sum + (room.countdown || 0), 0);
  }

  const onUploadChange = (file: File, fileList: FileList): void => {
    console.log("onChange", file, fileList);
    modalContext?.setIsChanged(true);
  };
  const uploadFiles = (fileList: any) => {
    console.log(fileList);
  };

  const onSaveGame = () => {
    const emptyAnswerFound = answerValues.some((ans) => !ans.trim());
    const errors = {
      questionTitleError: !titleValue,
      questionBodyError: !bodyValue,
      answerError: emptyAnswerFound,
    };

    const hasErrors = Object.values(errors).some(Boolean);

    setQuestionTitleError(errors.questionTitleError);
    setQuestionBodyError(errors.questionBodyError);
    setAnswerError(errors.answerError);

    if (getGameStats(activeRooms) === getRoomsDuration(rooms)) {
      setIsTimeImpactModalVisible(true);
      return;
    }

    if (hasErrors) {
      renderNotification(
        "Some information is missing. Please fill out all required fields.",
        "error"
      );
    } else {
      updateQuestion();
    }
  };

  function handleCorrectAnswerChange(index: number) {
    console.log("correct answer changed to", index);
    setSelectedQuestion((prev) => ({
      ...prev,
      choices: {
        ...prev.choices,
        correctAnswer: index,
      },
    }));
    modalContext?.setIsChanged(true);
  }

  const previewMediaUrl = selectedQuestion.mediaUrl ? `${baseUrl}/${selectedQuestion.mediaUrl}` : null;
  const isPreviewVideo = selectedQuestion.mediaUrl
    ? selectedQuestion.mediaUrl.toLowerCase().endsWith(".mp4")
    : false;

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={styles.headerContainer}>
          <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
            Training Question
          </h1>
          <div className={styles.buttonContainer}>
            <Button
              icon="icon-delete-outline"
              onClick={handleDeleteClick}
              kind="secondary"
              className={styles.deleteButton}
            >
              Delete question
            </Button>
            <Button
              onClick={onSaveGame}
              kind="primary"
              className={styles.saveButton}
            >
              Save changes
            </Button>
          </div>
        </div>
        <p className="ap-typography-body ap-mb-spacing-6">
          Write your training questions and answers in this section.
        </p>

        <>
          <div className={styles.divider}></div>
          <h3 className="ap-typography-body ap-font-weight-2">Question</h3>
          <Input
            type={"text"}
            title={"Title of the question"}
            onClick={() => {
              console.log("you clicked the input");
            }}
            value={titleValue}
            style={{ width: "940px", paddingBottom: "20px", fontSize: 16 }}
            onChange={onTitleChange}
            error={questionTitleError}
            errorNode={
              <div
                id="errormessage"
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                Please enter a question title
              </div>
            }
          ></Input>
          <Input
            type={"text"}
            title={"Question"}
            style={{ width: "940px", paddingBottom: "20px", fontSize: 16 }}
            required={true}
            value={bodyValue}
            onChange={onBodyChange}
            error={questionBodyError}
            errorNode={
              <div
                id="errormessage"
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                Please enter a question body
              </div>
            }
          ></Input>
          <div className={styles.divider}></div>
          <h3 className="ap-typography-body ap-font-weight-2">Add an image or video to your introduction (optional)</h3>
          <p className="ap-typography-body">
            You can upload JPG, PNG or MP4 files. The max file size is 10mb.{" "}
            <br />
          </p>
          <p className="ap-typography-body ap-font-weight-2">Videos extend question completion time. Adjust your game plan
            accordingly.
          </p>
          <Upload
            className="upload"
            onChange={handleUploadChange}
            multiple={false}
            autoUpload={false}
            style={{ width: "940px", paddingBottom: "20px" }}
            acceptFileType=".JPG,.PNG,.MP4"
            maxFileSize={10 * 1024 * 1024}
            config={{
              trigger: false,
              type: "inline",
              size: true,
            }}
          ></Upload>
        </>
        <div className={styles.divider}></div>
        <h3 className="ap-typography-body ap-font-weight-2">Answers</h3>
        <p className="ap-typography-body ap-mb-spacing-6">
          Add at least 2 possible answers and at least 1 correct answer.
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "10px",
            paddingTop: "20px",
          }}
        >
          <Switch
            className="ap-typography-body ap-mb-spacing-6"
            checked={randomiseAnswers}
            onChange={(checked) => {
              setRandomiseAnswers(checked);
              modalContext?.setIsChanged(true);
            }}
          >
            {"Randomise Answers"}
          </Switch>

          <p
            className="ap-typography-body ap-mb-spacing-6"
            style={{ marginRight: "50px" }}
          >
            Correct*
          </p>
        </div>
        <div>
          <div className="ap-typography-body ap-mb-spacing-6">
            {answerValues.map((answer, index) => (
              <div key={index} className={styles.answerContainer}>
                <div style={{ width: "844px", paddingRight: "20px" }}>
                  <Input
                    title={"Answer"}
                    value={answer}
                    style={{ width: "100%" }} // Full width for the input
                    required={true}
                    onChange={(value: any) => {
                      const newAnswerValues = [...answerValues];
                      newAnswerValues[index] = value;
                      setAnswerValues(newAnswerValues);
                      modalContext?.setIsChanged(true);
                    }}
                    error={answerError}
                    errorNode={
                      <div
                        id="errormessage"
                        aria-live="polite"
                        className="ap-field-email-validation-error"
                      >
                        Please enter an answer
                      </div>
                    }
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={index === selectedQuestion.choices.correctAnswer}
                    onChange={() => handleCorrectAnswerChange(index)}
                    style={{
                      width: "25.5px",
                      height: "25.5px",
                      marginRight: "20px",
                    }}
                  />
                  {index === answerValues.length - 1 && (
                    <Button
                      icon="icon-delete-outline"
                      kind="text"
                      className={styles.deleteButton}
                      onClick={handleDeleteLastRow}
                    ></Button>
                  )}
                </div>
              </div>
            ))}
            <Button
              icon="icon-circle-plus-fill"
              onClick={handleAddAnswer}
              kind="text"
              className={styles.deleteButton}
            >
              Add answer
            </Button>
          </div>
        </div>

        {
          <Modal
            visible={false}
            title={"Unsaved changes"}
            ariaLabel={"Unsaved changes"}
            modalStyle={{ width: "33.75rem" }}
            footerStyle={{
              paddingTop: "8px",
              marginTop: "-8px",
              minHeight: "64px",
            }}
            header={""}
            icons={""}
            footer={
              <>
                <Button
                  onClick={() => {
                    hideModal();
                  }}
                  kind="secondary"
                >
                  Discard changes and exit
                </Button>
                <Button
                  onClick={() => {
                    hideModal();
                  }}
                >
                  Save and exit
                </Button>
              </>
            }
            bodyStyle={{ minHeight: "92px" }}
          >
            <p>
              You have unsaved changes in this section.
              <br />
              <br />
              If you move to another section now, those changes will be lost.
              <br />
              <br />
              Would you like to save your changes before leaving?
              <br />
            </p>
          </Modal>
        }
        <Modal
          visible={isDeleteModalVisible}
          title={"Delete question?"}
          ariaLabel={"Delete question?"}
          onCancel={hideDeleteModal}
          modalStyle={{ width: "33.75rem" }}
          footerStyle={{
            paddingTop: "8px",
            marginTop: "-8px",
            minHeight: "64px",
          }}
          header={""}
          icons={""}
          footer={
            <>
              <Button onClick={deleteQuestion} kind="secondary">
                Delete question
              </Button>
              <Button
                onClick={() => {
                  hideDeleteModal();
                }}
              >
                Cancel and keep
              </Button>
            </>
          }
        >
          <p>
            Are you sure you want to delete this question? This action is
            permanent.
          </p>
        </Modal>
        <Modal
          visible={isTimeImpactModalVisible}
          title={"Potential impact on game completion time."}
          ariaLabel={"Potential impact on game completion time."}
          modalStyle={{ width: "33.75rem" }}
          footerStyle={{
            paddingTop: "8px",
            marginTop: "-8px",
            minHeight: "64px",
          }}
          header={""}
          icons={""}
          footer={
            <>
              <Button
                onClick={() => {
                  setIsTimeImpactModalVisible(false);
                }}
                kind="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  updateQuestion();
                  setIsTimeImpactModalVisible(false);
                }}
              >
                Add another question
              </Button>
            </>
          }
          bodyStyle={{ minHeight: "92px" }}
        >
          <p>
            Just a heads up. Adding another training question may extend the
            game beyond the planned duration.
            <br />
            <br />
            While you can add more questions, there's a possibility that players
            might not have enough time to finish the game.
            <br />
            <br />
            Please consider this as you continue to customise your game
            experience.
            <br />
          </p>
        </Modal>
      </div>

      <div className={styles.divider}></div>
      <h3 className="ap-typography-body ap-font-weight-2">Preview the page</h3>
      <p className="ap-typography-body">
        Preview reflects the last saved version.
        <br />
      </p>

      <div className={styles.previewContainer}>
        <div className="row" style={{ alignItems: "flex-start" }}>
          <div className="col-7">
            <h1 className="ap-font-weight-2">{bodyValue}</h1>
            <div
              style={
                selectedQuestion.mediaUrl
                  ? { marginTop: "1rem" }
                  : {
                    marginTop: "1rem",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "1rem",
                  }
              }
            >
              {answerValues.map((answer, index) => (
                <div
                  key={index}
                  style={
                    selectedQuestion.mediaUrl
                      ? { marginBottom: "1rem" }
                      : {}
                  }
                >
                  <Input
                    value={answer}
                    readonly
                    className="ap-typography-body"
                    style={{
                      width: "410px",
                      border: "1px solid #fff",
                      color: "#fff",
                      backgroundColor: "#000",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          {selectedQuestion.mediaUrl && (
            <div className="col-5 mt-5">
              {isPreviewVideo ? (
                <video
                  src={previewMediaUrl}
                  controls
                  style={{ maxWidth: "100%", margin: "2rem auto", display: "block" }}
                />
              ) : (
                <img
                  src={previewMediaUrl}
                  alt="Preview Image"
                  style={{ maxWidth: "100%", margin: "2rem auto", display: "block" }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TrainingQuestion;
