import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import {useNavigate} from "react-router-dom";
import { Modal, Button } from "@appkit4/react-components";

interface ModalContextType {
    isModalOpen: boolean;
    showModal: () => void;
    hideModal: () => void;
    setConfirmedAction: (action: boolean) => void;
    confirmedAction: boolean;
    confirmedRoute: string; 
    setConfirmedRoute: React.Dispatch<React.SetStateAction<string>>; 
    isChanged: boolean; 
    setIsChanged: React.Dispatch<React.SetStateAction<boolean>>;
    isMenuItemClicked: boolean;
    setIsMenuItemClicked: (clicked: boolean) => void;
    isHeaderItemClicked: boolean;
    setIsHeaderItemClicked: (clicked: boolean) => void;
    isIntroActive: boolean;
    setIsIntroActive: React.Dispatch<React.SetStateAction<boolean>>; 
    
}

interface ModalProviderProps {
    children: ReactNode;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider = ({ children }: ModalProviderProps) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [confirmedAction, setConfirmedAction] = useState(false);
    const [isMenuItemClicked, setIsMenuItemClicked] = useState(false);
    const [isHeaderItemClicked, setIsHeaderItemClicked] = useState(false);
    const [confirmedRoute, setConfirmedRoute] = useState("");
    const [isIntroActive, setIsIntroActive] = useState(true);
    const [isChanged, setIsChanged] = useState(false); 
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();
    const showModal = () => {
        setModalOpen(true);
        setConfirmedAction(false);
    };

    const hideModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        console.log('context ischanged', isChanged)
        console.log('context menu', isMenuItemClicked)
        console.log('context header', isHeaderItemClicked)
        console.log('active', isIntroActive)
        setIsVisible((isChanged && isMenuItemClicked) || (isChanged && isHeaderItemClicked));
    }, [isChanged, isMenuItemClicked, isHeaderItemClicked, isIntroActive]);

    return (
        <ModalContext.Provider value={{ isModalOpen, showModal, hideModal, confirmedRoute, setConfirmedRoute, confirmedAction, setConfirmedAction, isChanged, setIsChanged, isMenuItemClicked, setIsMenuItemClicked, isHeaderItemClicked, setIsHeaderItemClicked, isIntroActive, setIsIntroActive }}>
            {children}
        
            <Modal
                visible={isVisible}
                onCancel={() => {
                    if (confirmedRoute) {
                        navigate(confirmedRoute);
                        setConfirmedRoute('');
                      }
                    hideModal();
                    setIsChanged(false);
                    setIsMenuItemClicked(false);
                    setIsHeaderItemClicked(false);
                  }}
                title={"Unsaved changes"}
                ariaLabel={"Unsaved changes"}
                modalStyle={{ width: "33.75rem" }}
                footerStyle={{
                    paddingTop: "8px",
                    marginTop: "-8px",
                    minHeight: "64px",
                }}
                icons={""}
                footer={
                    <>
                        <Button
                             onClick={() => {
                                if (confirmedRoute) {
                                  navigate(confirmedRoute);
                                  setConfirmedRoute('');
                                }
                                hideModal();
                                setIsChanged(false);
                                setIsMenuItemClicked(false);
                                setIsHeaderItemClicked(false);
                              }}
                            kind="secondary"
                        >
                            Discard changes and exit
                        </Button>
                        <Button
                            onClick={() => {
                                if (confirmedRoute) {
                                    // Assuming you have a save function you need to call before navigating
                                    // saveChanges().then(() => {
                                      navigate(confirmedRoute);
                                      setConfirmedRoute('');
                                      setIsChanged(false);
                                      setIsMenuItemClicked(false);
                                      setIsHeaderItemClicked(false);
                                    // });
                                  } else {
                                    hideModal();
                                  } 
                            }}
                        >
                            Save and exit
                        </Button>
                    </>
                }
                bodyStyle={{ minHeight: "92px" }}
            >
                <p>
                    You have unsaved changes in this section.
                    If you move to another section now, those changes will be lost.
                    Would you like to save your changes before leaving?
                    <br />
                </p>
            </Modal>
      
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);
