import Phaser from "phaser";
import Item from "./Item";
import UIScene from "@/phaser/scenes/UIScene";
import { IRoomObject } from "escape-rooms-types/types/game";
import EventBridge from "@/utils/EventBridge";

const MAX_ITEMS = 6;

export default class Inventory extends Phaser.GameObjects.Container {
  private items: Array<Item | null>;
  declare scene: UIScene;

  constructor(scene: UIScene) {
    super(scene);

    this.items = new Array(MAX_ITEMS).fill(null);
  }

  // Used for inital population of inventory. Does not emit any events
  public loadInventory(inventoryObj: IRoomObject[]) {
    inventoryObj.forEach((itemObj, index) => {
      const roomObject = this.scene.sourceScene.getRoomObject(itemObj.ref);
      roomObject.setVisible(false);

      const newItem = new Item(this.scene as UIScene, itemObj, index);
      this.items[index] = newItem;
      this.add(newItem);
    });
  }

  public addToInventory(object: IRoomObject) {
    // Find index of first open inventory slot
    let inventoryIsFull = true;
    let index = 0;

    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i] == null) {
        inventoryIsFull = false;
        index = i;
        break;
      }
    }

    if (inventoryIsFull) {
      console.error("Inventory is full!");
      return;
    }

    const newItem = new Item(this.scene as UIScene, object, index);
    this.items[index] = newItem;
    this.add(newItem);

    EventBridge.emit(`${this.scene.env}.addedToInventory`, object);
  }

  public removeFromInventory(objectRef: string) {
    const item = this.items.find((item) => {
      if (item == null) {
        return;
      }
      return item.roomObject.ref === objectRef;
    });

    item?.container.destroy();

    this.items = this.items.map((i) => {
      if (i == null) {
        return null;
      }
      if (i.roomObject.ref == objectRef) {
        return null;
      }
      return i;
    });
  }

  public setInventory(items: Array<IRoomObject>) {
    this.items.forEach((item) =>
      this.removeFromInventory(item?.roomObject.ref!)
    );
    items.forEach((item) => this.addToInventory(item));
  }

  public getNamesOfItems(): string[] {
    return this.items
      .filter((item) => item !== null)
      .map((item) => (item as Item).roomObject.name);
  }
}
