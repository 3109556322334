import EventBridge from "@/utils/EventBridge";
import {
  TextModalPayload,
  ItemModalPayload,
} from "@/phaser/classes/modals/ModalPayloads";
import TextModal from "@/phaser/classes/modals/TextModal";
import ItemModal from "@/phaser/classes/modals/ItemModal";
import Inventory from "@/phaser/classes/ui/inventory/Inventory";
import ChallengeModal from "@/phaser/classes/modals/challenge/ChallengeModal";
import MessagePopup from "@/phaser/classes/modals/MessagePopup";
import { IRoomObject } from "escape-rooms-types/types/game";
import ImageModal from "@/phaser/classes/modals/media/ImageModal";
import VideoModal from "@/phaser/classes/modals/media/VideoModal";
import { PreviewPhaserGame } from "@/components/phaser-game/PreviewGame";
import { PhaserGameClass } from "@/components/phaser-game/PhaserGame";
import Minimap from "@/phaser/classes/ui/Minimap";
import Hud from "@/phaser/classes/ui/hud/Hud";
import XPAlert from "../classes/ui/XPAlert";
import GameScene from "./GameScene";
import PreviewScene from "./PreviewScene";
import Dropdown from "../classes/ui/Dropdown";
import ImagePopup from "../classes/modals/ImagePopup";
import MessageTooltip from "../classes/modals/MessageTooltip";
import MemoGame from "../classes/modals/puzzles/MemoGame";
import Jigsaw from "../classes/modals/puzzles/Jigsaw";
import TVCode from "../classes/modals/puzzles/TVCode";
import FridgeKeypad from "../classes/modals/puzzles/FridgeKeypad";
import DoorHandle from "../classes/modals/puzzles/DoorHandle";
import UnlockScreen from "../classes/modals/puzzles/UnlockScreen";
import Safebox from "../classes/modals/puzzles/Safebox";
import Checkers from "../classes/modals/puzzles/Checkers";
import WordSearch from "../classes/modals/puzzles/WordSearch";
import Clocks from "../classes/modals/puzzles/Clocks";
import Keyhooks from "../classes/modals/puzzles/Keyhooks";
import KeypadWithFob from "../classes/modals/puzzles/KeypadFob/KeypadWithFob";
import OnboardingMainScreen from "../classes/modals/onboarding/OnboardingMainScreen";
import Questionnaire from "../classes/modals/puzzles/Questionnaire/Questionnaire";
import { startSession } from "@/api/session";
import { ImagePopupPayload } from "../classes/modals/ImagePopupPayload";
import GameAction from "../classes/actions/GameAction";

export default class UIScene extends Phaser.Scene {
  public sourceScene: GameScene | PreviewScene;
  public inventory?: Inventory;
  public usingItemCursor?: Phaser.GameObjects.Image;
  public transparentBackground?: Phaser.GameObjects.Rectangle; // used to make game scene non-interactive
  public env: string;
  public minimap?: Minimap;
  public hud!: Hud;
  // public refreshPlayerAvatarsTimer?: Phaser.Time.TimerEvent;
  public overlay?: Phaser.GameObjects.Group;
  public blackScreen?: Phaser.GameObjects.Rectangle;
  public userMenu?: Dropdown;
  public activeQuestionnaire?: Questionnaire;
  private onboardingScreen!: OnboardingMainScreen;
  private timeOut: boolean = false;

  constructor(sourceScene: GameScene | PreviewScene) {
    super({ key: "ui-scene" });
    this.sourceScene = sourceScene;
    this.env = (
      this.sourceScene.game as PreviewPhaserGame | PhaserGameClass
    ).env;
    this.openUserMenu = this.openUserMenu.bind(this);
    this.destroy = this.destroy.bind(this);
  }

  preload() {
    this.blackScreen = this.add
      .rectangle(0, 0, 10000, 10000, 0x000000)
      .setAlpha(0);
    this.blackScreen.depth = 1000;
    this.scene.setVisible(false);
  }

  create() {
    this.addEventListeners();
    this.events.once("destroy", this.removeEventListeners);
    const roomWidth = this.sourceScene.cameras.main.getBounds().width;
    if (roomWidth > this.sourceScene.renderer.width) {
      this.minimap = new Minimap(this, this.sourceScene);
    }

    this.transparentBackground = this.add.existing(
      new Phaser.GameObjects.Rectangle(
        this,
        0,
        0,
        this.sourceScene.renderer.width,
        this.sourceScene.renderer.height,
        0,
        0
      ).setOrigin(0)
    );

    this.inventory = new Inventory(this);

    // Load inventory from session
    if (this.env === "game") {
      this.inventory.loadInventory(
        (this.game as PhaserGameClass).session.inventory
      );
    }

    // this.minimap?.draw();

    this.hud = new Hud(this, this.sourceScene, false, this.inventory);

    // this.refreshPlayerAvatarsTimer = this.time.addEvent({
    //   callback: this.hud.refreshPlayerAvatars,
    //   callbackScope: this,
    //   delay: 5000,
    //   loop: true,
    // });
  }

  loadOnboardingScreen() {
    this.makeGameSceneUninteractive();
    this.onboardingScreen = new OnboardingMainScreen(
      this,
      this.hideOnboardingScreen
    );
    this.add.existing(this.onboardingScreen);
    this.onboardingScreen.activate();
  }

  hideOnboardingScreen = () => {
    if (this.sourceScene instanceof GameScene) {
      const gameScene = this.sourceScene as GameScene;

      if (gameScene.session?.status === "pending") {
        startSession(gameScene.game.session._id).then(() => {
          gameScene.refetchSession();
          this.closeOnboardingScreenAndStartClock();
        });
      } else {
        this.closeOnboardingScreenAndStartClock();
      }
    }
  };

  private closeOnboardingScreenAndStartClock = () => {
    this.onboardingScreen.destroy();
    this.makeGameSceneInteractive();
    this.hud.startClock();
  };

  update(time: number, delta: number) {
    super.update(time, delta);
    if (this.usingItemCursor != null) {
      this.usingItemCursor.setPosition(this.input.x, this.input.y);
    }
  }

  reloadUI() {
    this.scene.restart();
  }

  openUserMenu() {
    if (this.userMenu) {
      this.userMenu.close();
      this.userMenu = undefined;
      this.openUserMenu();
      return;
    }
    this.userMenu = new Dropdown(
      this,
      this.sourceScene.game.input.mousePointer.x,
      this.sourceScene.game.input.mousePointer.y,
      { label: "Leave Game", fn: () => this.sourceScene.leaveGame() }
    );
    this.add.existing(this.userMenu);
  }

  closeUserMenu() {
    if (this.userMenu == null) {
      return;
    }
    this.userMenu.close();
  }

  addItemToInventory(object: IRoomObject) {
    this.inventory?.addToInventory(object);
  }

  setInventory(items: Array<IRoomObject>) {
    this.inventory?.setInventory(items);
  }

  makeGameSceneUninteractive() {
    this.transparentBackground?.setInteractive();
  }

  makeGameSceneInteractive() {
    this.transparentBackground?.disableInteractive();
  }

  public get isTimeOut() {
    return this.timeOut;
  }

  addEventListeners() {
    EventBridge.on("ui.openTextModal", (payload: TextModalPayload) => {
      new TextModal(
        this,
        payload.text,
        payload.sourceObject!,
        payload.incrementAction
      );
    });

    EventBridge.on("ui.completeTextModal", (object) => {
      object.completeAction();
    });

    EventBridge.on("ui.openMessagePopup", (payload: TextModalPayload) => {
      new MessagePopup(
        this,
        payload.text,
        payload.sourceObject,
        payload.callback
      );
    });

    EventBridge.on("ui.openMessageTooltip", (payload: TextModalPayload) => {
      new MessageTooltip(
        this,
        payload.text,
        payload.sourceObject,
        payload.callback
      );
    });

    EventBridge.on("ui.openImagePopup", (payload: ImagePopupPayload) => {
      new ImagePopup(
        this, 
        payload.imageType,
        payload.hintMessage
      );
    });

    EventBridge.on("ui.openUnlockScreen", (payload) => {
      new UnlockScreen(
        this,
        payload.text,
        payload.description,
        payload.sourceObject,
        payload.actionId,
        payload.callback
      );
    });

    EventBridge.on("game.timeOut", (activeRoomId) => {
      this.timeOut = true;

      if (this.sourceScene instanceof GameScene) {
        const gameScene = this.sourceScene as GameScene;

        if (!gameScene.isNavigatorScene()) {
          return;
        }

        let endRoomAction;
        let questionnaireFound = false;
        let questionnairePayload;

        const session = (this.game as PhaserGameClass).session;
        const activeRoom = session.game.rooms.find(
          (r) => r._id === activeRoomId
        );

        for (const gameObject of Object.values(activeRoom.objects)) {
          if (!gameObject || !gameObject.actions) {
            continue;
          }

          if (questionnaireFound) {
            break;
          }

          for (const action of gameObject.actions) {

            if (action.questionnaire && action.questionnaire.ref !== "") {

              if (gameScene.session!.completedQuestionnaires?.includes(action._id)) {
                continue;
              }

              if (!session.completedQuestionnaires.includes(action._id)) {
                questionnairePayload = {
                  sessionId: gameScene.session!.id,
                  roomId: gameScene.session!.activeRoomId,
                  objectId: gameObject._id,
                  actionId: action._id,
                };

                questionnaireFound = true;
                break;
              }
            } else if (action.type === "endRoom" || action.type === "endGame") {
              endRoomAction = action;
            } else {
              // console.log(" action type found: ", action.type);
            }
          }
        }

        if (questionnaireFound) {
          if (this.activeQuestionnaire) {
            return;
          }

          gameScene.game.socketEmit(
            "questionnaireStarted",
            questionnairePayload
          );
        } else if (!questionnaireFound && endRoomAction) {

          const actionToExecute = new GameAction(
            gameScene,
            undefined!,
            0,
            endRoomAction._id!,
            endRoomAction.ref,
            endRoomAction.trigger!,
            endRoomAction.type!,
            endRoomAction.changeObjectPayload,
            endRoomAction.modalPayload,
            endRoomAction.questionnaire,
            endRoomAction.triggerItemRef,
            endRoomAction.mediaPayload,
            endRoomAction.repeatable,
            endRoomAction.stateBasedPayload
          );

          actionToExecute.executeAction();
        }
      }
    });

    EventBridge.on("ui.openPuzzle", (payload) => {
      this.hud.setHintButtonVisible(true);

      if (payload.puzzleName === "Financial Services Memo Game") {
        new MemoGame(
          this,
          payload.sourceObject,
          payload.actionId,
          payload.callback
        );
      } else if (payload.puzzleName === "Financial Services Checkers") {
        new Checkers(
          this,
          payload.sourceObject,
          payload.actionId,
          payload.callback
        );
      } else if (payload.puzzleName === "Financial Services Clocks") {
        new Clocks(
          this,
          payload.sourceObject,
          payload.actionId,
          payload.callback
        );
      } else if (payload.puzzleName === "Financial Services Jigsaw") {
        new Jigsaw(
          this,
          payload.sourceObject,
          payload.actionId,
          payload.callback
        );
      } else if (payload.puzzleName === "Financial Services Mini Fridge Keypad") {
        new FridgeKeypad(
          this,
          payload.sourceObject,
          payload.actionId,
          payload.callback
        );
      } else if (payload.puzzleName === "Financial Service Vault Keypad") {
        const keypadPuzzle = KeypadWithFob.getInstance(
          this,
          payload.sourceObject,
          payload.actionId,
          payload.callback
        );
        keypadPuzzle.keyAvailableInInventory = this.inventory
          ?.getNamesOfItems()
          .find((i) => i === Keyhooks.INVENTORY_ITEM_NAME)
          ? true
          : false;
        keypadPuzzle.setVisible(true);
      } else if (payload.puzzleName === "Financial Services Safebox") {
        new Safebox(
          this,
          payload.sourceObject,
          payload.actionId,
          payload.callback
        );
      } else if (payload.puzzleName === "Financial Services Door Handle") {
        new DoorHandle(
          this,
          payload.sourceObject,
          payload.actionId,
          payload.callback
        );
      } else if (payload.puzzleName === "Financial Services TV Code") {
        new TVCode(
          this,
          payload.sourceObject,
          payload.actionId,
          payload.callback
        );
      } else if (payload.puzzleName === "Financial Services Word Search") {
        new WordSearch(
          this,
          payload.sourceObject,
          payload.actionId,
          payload.callback
        );
      } else if (payload.puzzleName === "Financial Services Keyhooks") {
        new Keyhooks(
          this,
          payload.sourceObject,
          payload.actionId,
          payload.callback
        );
      }
    });

    EventBridge.on("ui.openMediaModal", (payload: any) => {
      // If repeatable, don't call the modal complete callback.
      const callback = payload.repeatable ? () => {} : payload.callback;
      if (payload.type === "audio") {
        new VideoModal(
          this,
          payload.mediaUrl,
          true,
          payload.optionalMessage,
          callback
        );
      }
      if (payload.type === "image") {
        new ImageModal(
          this,
          payload.mediaUrl,
          payload.optionalMessage,
          callback
        );
      }
      if (payload.type === "video") {
        new VideoModal(
          this,
          payload.mediaUrl,
          false,
          payload.optionalMessage,
          callback
        );
      }
    });

    EventBridge.on("ui.completeMessagePopup", (object) => {
      object.completeAction();
      this.makeGameSceneInteractive();
    });

    EventBridge.on("ui.showErrorScreen", () => {
      this.blackScreen?.setAlpha(1).setVisible(true).setDepth(5000);
      this.blackScreen?.setVisible(true);
      const message = new Phaser.GameObjects.Text(
        this,
        this.renderer.width / 2,
        this.renderer.height / 2,
        "Something went wrong! Please refresh the page.",
        {
          fontFamily: "Roboto-Regular",
          fontSize: "20px",
          color: "#FFFFFF",
          wordWrap: { width: 1000 },
          align: "center",
        }
      )
        .setOrigin(0.5, 0.5)
        .setLineSpacing(8);
      message.depth = 5001;
      this.add.existing(message);
      (this.sourceScene as GameScene).game.socket.disconnect();
      this.sourceScene.scene.pause();
      this.scene.pause();
    });

    EventBridge.on("ui.openItemModal", (payload: ItemModalPayload) => {
      new ItemModal(
        this,
        payload.text,
        payload.object,
        payload.env,
        payload.executedActionId
      );
    });

    EventBridge.on("ui.completeItemModal", (object) => {
      this.addItemToInventory(object.gameObject);
      object.completeAction();
    });

    EventBridge.on("ui.addItemToInventory", (object) => {
      this.addItemToInventory(object);
      // object.completeAction();
    });

    EventBridge.on("ui.setInventory", (items) => {
      this.setInventory(items);
      // object.completeAction();
    });

    EventBridge.on("ui.removeFromInventory", (objectRef) => {
      this.inventory?.removeFromInventory(objectRef);
    });

    EventBridge.on("ui.openQuestionnaire", (payload) => {
      if (this.activeQuestionnaire) {
        console.log("  activeQuestionnaire already exists");
        return;
      }

      this.activeQuestionnaire = new Questionnaire(
        this,
        payload.sourceObject,
        payload.actionId,
        payload.questions,
        payload.callback,
        payload.activeQuestionnaire
      ).setDepth(1002);
    });

    EventBridge.on("ui.openIndividualChallenge", (payload) => {
      new ChallengeModal(
        this,
        payload.questions[0],
        payload.sourceObject,
        payload.continueCallback,
        payload.exitCallback,
        payload.actionId
      );
    });

    EventBridge.on("challengeModal.complete", (sourceObject) => {
      EventBridge.emit(`${this.env}.completeObjectAction`, sourceObject);
    });

    EventBridge.on("ui.completeIndividualChallenge", (sourceObject) => {
      EventBridge.emit(`${this.env}.completeObjectAction`, sourceObject);
    });

    // EventBridge.on("ui.stopUsingItem", () => {
    //   this.usingItemCursor?.destroy();
    //   this.usingItemCursor = undefined;
    // });

    EventBridge.on("ui.endRoom", (callback) => {
      this.makeGameSceneUninteractive();
      this.tweens.add({
        targets: [this.blackScreen],
        alpha: 1,
        duration: 500,
      });

      setTimeout(() => {
        callback();
      }, 1000);
    });

    EventBridge.on("ui.endGame", (callback) => {
      this.makeGameSceneUninteractive();

      setTimeout(() => {
        callback();
      }, 500);
    });

    EventBridge.on("ui.showXPAlert", (score: number) => {
      this.add.existing(
        new XPAlert(this, this.input.x, this.input.y, score).setDepth(2000)
      );
    });

    EventBridge.on("minimap.redraw", () => {
      this.minimap?.draw();
    });
  }

  destroy() {
    this.scene.remove("ui-scene");
  }

  removeEventListeners() {
    EventBridge.remove("ui.openTextModal");
    EventBridge.remove("ui.completeTextModal");
    EventBridge.remove("ui.openImagePopup");
    EventBridge.remove("ui.openUnlockScreen");
    EventBridge.remove("ui.openMessagePopup");
    EventBridge.remove("ui.openMessageTooltip");
    EventBridge.remove("ui.openMediaModal");
    EventBridge.remove("ui.completeMessagePopup");
    EventBridge.remove("ui.openItemModal");
    EventBridge.remove("ui.completeItemModal");
    EventBridge.remove("ui.addItemToInventory");
    EventBridge.remove("ui.setInventory");
    EventBridge.remove("ui.removeFromInventory");
    EventBridge.remove("ui.openQuestionnaire");
    EventBridge.remove("ui.openIndividualChallenge");
    EventBridge.remove("challengeModal.complete");
    EventBridge.remove("ui.completeIndividualChallenge");
    EventBridge.remove("ui.stopUsingItem");
    EventBridge.remove("ui.endRoom");
    EventBridge.remove("ui.endGame");
    EventBridge.remove("ui.showXPAlert");
    EventBridge.remove("minimap.redraw");
    EventBridge.remove("ui.showErrorScreen");
    EventBridge.remove("ui.openPuzzle");
    EventBridge.remove("game.timeOut");
  }
}
