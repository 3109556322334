import { Notification, toaster } from "@appkit4/react-components/notification";
import { Button } from "@appkit4/react-components/button";

export const renderNotification = (
  message: string,
  status: "success" | "error",
  title?: string,
  handleClose: () => void = () => {}
) => {
  const ele = <Notification title={title} message={message} status={status} />;
  toaster.notify(ele, {
    position: "topHeader",
    onClose: handleClose,
    duration: 5000,
  });
};
