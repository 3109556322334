import Phaser from "phaser";
import UIScene from "@/phaser/scenes/UIScene";
import EventBridge from "@/utils/EventBridge";
import { IRoomObject } from "escape-rooms-types/types/game";
import { PhaserGameClass } from "@/components/phaser-game/PhaserGame";
import { PreviewPhaserGame } from "@/components/phaser-game/PreviewGame";
import { HEADER_HEIGHT } from "@/constants";

export default class Item extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  public readonly roomObject: IRoomObject;
  
  private readonly itemBackground;
  private readonly itemSprite;
  private readonly tooltip: Phaser.GameObjects.Container;
  
  private readonly X: number;
  private readonly Y: number;
  private readonly BACKGROUND_WIDTH = 50;
  private readonly BACKGROUND_HEIGHT = 50;

  constructor(scene: UIScene, roomObject: IRoomObject, index: number) {
    super(scene);
    this.scene = scene;
    this.roomObject = roomObject;

    const [x, y] = this.getXandYFromIndex(this.scene, index)
    this.X = x;
    this.Y = y;

    this.itemBackground = new Phaser.GameObjects.Graphics(this.scene);
    this.itemBackground.fillStyle(0xffffff, 0.4);
    this.itemBackground.fillRoundedRect(
      this.X - this.BACKGROUND_WIDTH / 2, 
      this.Y - this.BACKGROUND_HEIGHT / 2, 
      this.BACKGROUND_WIDTH, 
      this.BACKGROUND_HEIGHT, 
      5);

    this.scene.tweens.add({
      targets: this.itemBackground,
      alpha: { from: 0.4, to: 1 },
      duration: 1000,
      ease: 'Linear',
      repeat: 0,
      yoyo: true,
      onUpdate: this.updateFillAlpha,
      onUpdateScope: this
    });

    this.itemBackground.setInteractive(new Phaser.Geom.Rectangle(x - 25, y - 25, 50, 50), Phaser.Geom.Rectangle.Contains)
      .on("pointerdown", () => this.useItem(scene, roomObject))
      .on("pointerover", () => {
        this.scene.tweens.add({
          targets: this.itemBackground,
          alpha: { from: 0.4, to: 1 },
          duration: 500,
          ease: 'Linear',
        });
      })
      .on("pointerout", () => {
        this.scene.tweens.add({
          targets: this.itemBackground,
          alpha: { from: 1, to: 0.4 },
          duration: 500,
          ease: 'Linear',
        });
      });

    this.itemSprite = new Phaser.GameObjects.Sprite(
      this.scene,
      this.X,
      this.Y,
      scene.sourceScene.getRoomObject(roomObject.ref).getThumbnailFromState(roomObject.currentState)
    ).setSize(this.BACKGROUND_WIDTH, this.BACKGROUND_HEIGHT);

    this.itemSprite.displayHeight = 30;
    this.itemSprite.scaleX = this.itemSprite.scaleY;

    this.add(this.itemBackground);
    this.add(this.itemSprite);

    const tooltipText = new Phaser.GameObjects.Text(
      scene,
      0,
      0,
      roomObject.name,
      {}
    ).setOrigin(0.5);

    const tooltipBackground = new Phaser.GameObjects.Rectangle(scene, 0, 0);
    tooltipBackground
      .setSize(tooltipText.width + 10, tooltipText.height + 10)
      .setFillStyle(0)
      .setOrigin(0.5);

    this.tooltip = new Phaser.GameObjects.Container(
      scene,
      this.X + 50,
      this.Y,
      [tooltipBackground, tooltipText]
    ).setVisible(false);
    
    this.add(this.tooltip);
  }

  private updateFillAlpha = (tween: Phaser.Tweens.Tween, target: Phaser.GameObjects.Graphics) => {
    target.clear();
    target.fillStyle(0xffffff, target.alpha);  // Use the current alpha value
    target.fillRoundedRect(
      this.X - this.BACKGROUND_WIDTH / 2, 
      this.Y - this.BACKGROUND_HEIGHT / 2, 
      this.BACKGROUND_WIDTH, 
      this.BACKGROUND_HEIGHT, 
      5);
  }

  private getXandYFromIndex = (scene: Phaser.Scene, index: number) => {
    const x = scene.renderer.width / 2 + 58 * index;
    const y = HEADER_HEIGHT / 2;
    return [x, y];
  };

  private useItem(scene: UIScene, object: IRoomObject) {
    // EventBridge.emit("ui.stopUsingItem", object);
    const env = (this.scene.game as PhaserGameClass | PreviewPhaserGame).env;
    EventBridge.emit(`${env}.setUsingItem`, object);
  }
}
