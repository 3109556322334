import GameScene from "@/phaser/scenes/GameScene";
import UIScene from "@/phaser/scenes/UIScene";

export default class PopupBackground extends Phaser.GameObjects.Container {
    private readonly greyBackground: Phaser.GameObjects.Rectangle;

    constructor(
        scene: UIScene,
        fillAlpha: number = 0.7
      ) {
        super(scene, 0, 0);

        this.greyBackground = new Phaser.GameObjects.Rectangle(
          scene,
          0,
          0,
          scene.renderer.width,
          scene.renderer.height,
          0x000000,
          fillAlpha
        );
    
        this.greyBackground.setOrigin(0,0);
        this.greyBackground.setInteractive();
        this.add(this.greyBackground);
    
        this.greyBackground.on(Phaser.Input.Events.POINTER_DOWN, function(pointer: Phaser.Input.Pointer) {
          pointer.event.stopPropagation();
        });

        if (scene.sourceScene instanceof GameScene) {
          const gameScene = scene.sourceScene as GameScene;
          this.greyBackground.on(Phaser.Input.Events.POINTER_MOVE, (pointer: Phaser.Input.Pointer) => gameScene.updateCursor(pointer));
        }
    }
}