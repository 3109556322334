import { Loading, Tooltip } from "@appkit4/react-components";
import { useNavigate } from "react-router-dom";
import styles from "./GameEditor.module.scss";
import { useState, useEffect, ReactElement } from "react";
import { useGameStore } from "@/store/game-store";
import { useQuery } from "react-query";
import { getGame } from "@/api/game";
import GameEditorSidebar from "@/components/game-editor-sidebar/GameEditorSidebar";
import { menuItems } from "@/constants";
import GameMission from "../game-mission/GameMission";
import Intro from "../game-customisation-template-pages/Intro";
import Puzzle from "../game-customisation-template-pages/Puzzle";
import TrainingQuestion from "../game-customisation-template-pages/TrainingQuestion";
import Outro from "../game-customisation-template-pages/Outro";
import Room from "../game-customisation-template-pages/Room";
import { useModal } from "../game-customisation-template-pages/UnsavedChangesContext";
import GameHeader from "../game-header/GameHeader";

const GameEditor = () => {
  const navigate = useNavigate();
  const modalContext = useModal();
  const game = useGameStore((state) => state.game);
  const name = useGameStore((state) => state.game.name);
  const status = useGameStore((state) => state.game.status);
  const rooms = useGameStore((state) => state.game.rooms);
  const { setGame } = useGameStore((state) => state);
  const [menuState, setMenuState] = useState(menuItems);

  const activeRooms = rooms.filter((room) => room.isActive && room.isVisible);

  const getNumberOfPuzzle = (activeRooms) => {
    return activeRooms.reduce((count, room) => count + (room.numberOfPuzzles || 0), 0);
  };

  function getQuestionCount(room) {
    let count = 0;
    room.objects?.forEach(obj => {
      obj.actions?.forEach(action => {
        count += action.questionnaire?.questions?.length || 0;
      });
    });
    return count;
  }

  function getTotalQuestionCount(activeRooms) {
    return activeRooms.reduce((total, room) => total + getQuestionCount(room), 0);
  }

  function getGameStats(activeRooms) {
    let totalPuzzleTime = 0;
    let totalQuestions = 0;

    activeRooms.forEach((room) => {
      totalPuzzleTime += room.puzzleTime || 0;
      totalQuestions += getQuestionCount(room);
    });

    return totalPuzzleTime + totalQuestions;
  }

  function getGameDuration(activeRooms) {
    return activeRooms.reduce(
      (sum, room) => sum + (room.countdown || 0),
      0
    );
  }

  const loadGame = (gameData: any) => {
    if (gameData == null) {
      return;
    }
    setGame(gameData);
  };

  const gameId = location.pathname.split("/").at(-2);
  const getGameQuery = useQuery({
    queryKey: ["getGame"],
    queryFn: () => getGame(gameId!),
    onSuccess: (res) => {
      loadGame(res.data.data);
    },
  });

  useEffect(() => {
    getGameQuery.refetch();
  }, []);

  // TODO: Replace this placeholder component with the actual Introduction component.
  const [bodyComponent, setBodyComponent] = useState<ReactElement | null>(
    <Intro />
  );
  const [selectedTopLevelIndex, setSelectedTopLevelIndex] = useState(0);
  const [selectedPuzzleIndex, setSelectedPuzzleIndex] = useState<number | null>(
    null
  );
  const [selectedTrainingQuestionIndex, setSelectedTrainingQuestionIndex] =
    useState<number | null>(null);


  const handleBackClick = () => {
    if (modalContext?.isChanged) {
      modalContext?.setConfirmedRoute('/');
      modalContext?.setIsHeaderItemClicked(true);
      modalContext?.showModal();
    } else {
      navigate('/');
    }
  }

  const handleDeleteQuestion = (
    roomIndex: number,
    puzzleIndex: number,
    questionIndex: number
  ) => {
    setMenuState((prevMenu) => {
      const newMenu = [...prevMenu];
      const questions =
        newMenu[roomIndex].children[puzzleIndex].content.trainingQuestions;
      console.log(questions, questions, questionIndex);
      questions.splice(questionIndex, 1);
      return newMenu;
    });
    setSelectedTrainingQuestionIndex(null); // Reset the selected training question index
  };

  // TODO: Replace the placeholder components with the actual page components.
  useEffect(() => {
    const setRoomComponent = (index) => {
      setBodyComponent(<Room roomIndex={index} />);
    };

    const setPuzzleComponent = (roomIndex, puzzleIndex) => {
      setBodyComponent(<Puzzle roomIndex={roomIndex} puzzleIndex={puzzleIndex} />);
      console.log('roomIndex', roomIndex);
      console.log('puzzleIndex', puzzleIndex);
    };

    if (selectedTopLevelIndex === 0) {
      setBodyComponent(<Intro />);
    } else if (selectedTopLevelIndex === 5) {
      setBodyComponent(<Outro />);
    } else if (selectedTopLevelIndex === 1) {
      setBodyComponent(<GameMission />);
    } else if ([2, 3, 4].includes(selectedTopLevelIndex) && selectedPuzzleIndex === null && selectedTrainingQuestionIndex === null) {
      console.log('selectedTopLevelIndex - 1', selectedTopLevelIndex - 1)
      setRoomComponent(selectedTopLevelIndex - 1);
    } else if ([2, 3, 4].includes(selectedTopLevelIndex) && selectedTrainingQuestionIndex === null) {
      console.log('selectedTopLevelIndex', selectedTopLevelIndex)
      console.log('selectedTrainingQuestionIndex', selectedTrainingQuestionIndex)
      setPuzzleComponent(selectedTopLevelIndex, selectedPuzzleIndex);
      console.log('tq index', selectedTrainingQuestionIndex)
    } else if (selectedTrainingQuestionIndex === 0) {
      console.log('tq index', selectedTrainingQuestionIndex)
      setBodyComponent(

        <TrainingQuestion
          onDelete={handleDeleteQuestion}
          roomIndex={selectedTopLevelIndex}
          puzzleIndex={selectedPuzzleIndex}
          questionIndex={selectedTrainingQuestionIndex}
          menuState={menuState}
        />
      );
    } else if (selectedTrainingQuestionIndex && selectedTrainingQuestionIndex > 0) {
      console.log('tq index', selectedTrainingQuestionIndex)
      setBodyComponent(
        <TrainingQuestion
          onDelete={handleDeleteQuestion}
          roomIndex={selectedTopLevelIndex}
          puzzleIndex={selectedPuzzleIndex}
          questionIndex={selectedTrainingQuestionIndex}
          menuState={menuState}
        />
      );
    }
  }, [
    selectedTopLevelIndex,
    selectedPuzzleIndex,
    selectedTrainingQuestionIndex,
    menuState,
  ]);

  return (
    <div className="ap-container">
      <div className="row">
        <div className={styles.gameHeaderContainer}>
          <GameHeader />
        </div>
        <div className="ap-my-spacing-5">
          <div className={styles.statusBar}>
            <div className={styles.statusBarBody}>
              <div className={styles.statusBarTitle}>
                <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
                  Overview
                </h1>
              </div>
              <div className={styles.statusBarItem}>
                <p className={styles.statusBarItemLabel}>
                  Overall experience
                </p>
                <div className={styles.statusBarContent}>
                  <div className={styles.statusBarContentItem}>
                    <span className="Appkit4-icon icon-time-outline" />
                    <p className={styles.statusBarInlineTextContent}>
                      {getGameDuration(activeRooms)} + 5 min onboarding
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.statusBarItem}>
                <p className={styles.statusBarItemLabel}>
                  Estimated time to complete
                  {getGameStats(activeRooms) > getGameDuration(rooms) ? (
                    <Tooltip
                      content="You might have added too many training questions, or the content is a bit too long and players may not finish before the countdown ends. Consider removing training questions if possible."
                      position="top"
                      trigger="hover"
                      className="w-auto max-w-xl"
                      style={{ maxWidth: "500px" }}
                    >
                      <span className={`Appkit4-icon icon-warning-fill ap-ml-spacing-2 ${styles.iconWarning}`}></span>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      content={
                        <>
                          Time expected for players to solve the puzzles and answer the
                          training questions you include vs the game's time limit.
                          <br />
                          No need to fill every minute; extra time allows a more relaxed
                          player experience.
                        </>
                      }
                      position="top"
                      trigger="hover"
                      className="w-auto max-w-xl"
                      style={{ maxWidth: "500px" }}
                    >
                      <span className="Appkit4-icon icon-information-outline ap-ml-spacing-2"></span>
                    </Tooltip>
                  )}
                </p>
                <div className={styles.statusBarContent}>
                  <div className={styles.statusBarContentItem}>
                    <p className={styles.statusBarInlineTextContent}>
                      {getGameStats(activeRooms)} out of {getGameDuration(rooms)}
                    </p>
                    <Loading
                      className={styles.loadingBar}
                      loadingType="linear"
                      indeterminate={false}
                      linearWidth={84}
                      stopPercent={
                        (getGameStats(activeRooms) /
                          parseInt(
                            getGameDuration(rooms)
                          )) *
                        100
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statusBarItem}>
                <p className={styles.statusBarItemLabel}>Included in game</p>
                <div className={styles.statusBarContent}>
                  <div className={styles.statusBarContentItem}>
                    <span className="Appkit4-icon icon-ask-question-outline" />
                    <p className={styles.statusBarInlineTextContent}>
                      {getTotalQuestionCount(activeRooms)} training questions
                    </p>
                  </div>
                  <div className={styles.statusBarContentItem}>
                    <span className="Appkit4-icon icon-door-opened-outline" />
                    <p className={styles.statusBarInlineTextContent}>
                      {activeRooms.length === 1
                        ? "1 room"
                        : activeRooms.length + " rooms"}
                    </p>
                  </div>
                  <div className={styles.statusBarContentItem}>
                    <span className="Appkit4-icon icon-gameroom-outline" />
                    <p className={styles.statusBarInlineTextContent}>
                      {getNumberOfPuzzle(activeRooms)} puzzles
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.sidebarContainer}>
            <GameEditorSidebar
              menuItems={menuState}
              setSelectedTopLevelIndex={setSelectedTopLevelIndex}
              setSelectedPuzzleIndex={setSelectedPuzzleIndex}
              setSelectedTrainingQuestionIndex={
                setSelectedTrainingQuestionIndex
              }
            />
          </div>
          <div className={`${styles.contentContainer}`}>
            <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
              {bodyComponent}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameEditor;
