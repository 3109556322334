import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";

const HAND_WIDTH = 8;
const HOUR_HAND_HEIGHT = 78;
const MINUTE_HAND_HEIGHT = 140;
const ANIMATION_DURATION = 500;
const FULL_ROTATION = 2 * Math.PI;
const NUMBER_OF_HOURS = 12;
const NUMBER_OF_MINUTES = 60;

export default class Clock extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly countryNameText: Phaser.GameObjects.Text;
  private readonly clockImage: Phaser.GameObjects.Image;
  private readonly hour: Phaser.GameObjects.Rectangle;
  private readonly minute: Phaser.GameObjects.Rectangle;
  private readonly globalX: number;
  private readonly globalY: number;
  private readonly circle: Phaser.GameObjects.Graphics;
  private readonly clockCenter: any;
  private readonly callback: () => void;
  private selectedHour: number;
  private selectedMinute: number;

  constructor(
    scene: UIScene,
    x: number, 
    y: number,
    cityName: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {}
  ) {
    super(scene, x, y);
    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    this.setName(cityName);

    this.clockImage = new Phaser.GameObjects.Image(scene, 0, 0, "clock");
    this.add(this.clockImage);

    this.clockCenter = this.clockImage.getCenter();

    this.countryNameText = this.initializeCityNameText(cityName);
    this.add(this.countryNameText);

    this.circle = this.initializeClockCircle();
    this.add(this.circle);

    this.hour = this.initializeHourHand();
    this.add(this.hour);

    this.minute = this.initializeMinuteHand();
    this.add(this.minute);

    this.setDraggableState(true);

    this.selectedHour = this.getSelectedHourByAngle();
    this.selectedMinute = this.getSelectedMinuteByAngle();
    
    const containerWorldX = this.x;
    const containerWorldY = this.y;
    const localX = this.clockCenter.x!;
    const localY = this.clockCenter.y!;
    this.globalX = containerWorldX + localX;
    this.globalY = containerWorldY + localY;
  }

  private initializeCityNameText(cityName: string): Phaser.GameObjects.Text {
    return new Phaser.GameObjects.Text(this.scene, this.clockCenter.x! + 4, this.clockCenter.y! + 60, cityName, {
        fontFamily: "Roboto-Regular",
        fontSize: "20px",
        color: "#ffffff",
        align: "center",
      })
      .setLineSpacing(8)
      .setOrigin(0.5);
  }

  private initializeClockCircle(): Phaser.GameObjects.Graphics {
    const circle = new Phaser.GameObjects.Graphics(this.scene);
    circle.fillStyle(0x000000, 1.0);
    circle.fillCircle(this.clockCenter.x! + 4, this.clockCenter.y!, 7);
    
    return circle;
  }

  private initializeHourHand(): Phaser.GameObjects.Rectangle {
    const initialHourRotation = 6;
    
    const hourHand = new Phaser.GameObjects.Rectangle(this.scene, this.clockCenter.x! + 4, this.clockCenter.y!, HAND_WIDTH, HOUR_HAND_HEIGHT, 0x000000, 1);
    hourHand.setOrigin(0.5, 1);
    hourHand.setInteractive();
    hourHand.setRotation(Math.PI / initialHourRotation);
    
    hourHand.on(Phaser.Input.Events.DRAG, this.onDragHour);
    hourHand.on(Phaser.Input.Events.DRAG_END, this.onDragEnd);

    return hourHand;
  }

  private initializeMinuteHand(): Phaser.GameObjects.Rectangle {
    const minuteHand = new Phaser.GameObjects.Rectangle(this.scene, this.clockCenter.x! + 4, this.clockCenter.y!, HAND_WIDTH, MINUTE_HAND_HEIGHT, 0x000000, 1);
    minuteHand.setOrigin(0.5, 1);
    minuteHand.setInteractive();
    
    minuteHand.on(Phaser.Input.Events.DRAG, this.onDragMinute);
    minuteHand.on(Phaser.Input.Events.DRAG_END, this.onDragEnd);

    return minuteHand;
  }

  private getSelectedHourByAngle(): number {
    return this.mapAngleToHour(this.hour.rotation);
  }

  private getSelectedMinuteByAngle(): number {
    return this.mapAngleToMinute(this.minute.rotation);
  }

  public getSelectedHour(): number  {
    return this.selectedHour;
  }

  public setSelectedHour(hour: number) {
    const currentHour = this.selectedHour;
    const targetRotation = (hour / NUMBER_OF_HOURS) * FULL_ROTATION;

    if (currentHour === hour) {
      this.hour.rotation = targetRotation;
      return;
    }
    
    this.selectedHour = hour;
    this.scene.tweens.add({
      targets: this.hour,
      rotation: { from: this.hour.rotation, to: targetRotation },
      duration: ANIMATION_DURATION,
      ease: Phaser.Math.Easing.Cubic.Out
    });
  }

  public getSelectedMinute(): number  {
    return this.selectedMinute;
  }

  public setSelectedMinute(minute: number) {
    const currentMinute = this.selectedMinute;
    const targetRotation = (minute / NUMBER_OF_MINUTES) * FULL_ROTATION;

    if (currentMinute === minute) {
      this.minute.rotation = targetRotation;
      return;
    }
    
    this.selectedMinute = minute;
    this.scene.tweens.add({
      targets: this.minute,
      rotation: { from: this.minute.rotation, to: targetRotation },
      duration: ANIMATION_DURATION,
      ease: Phaser.Math.Easing.Cubic.Out
    });
  }

  public setSelectedTime(hour: number, minute: number) {
    this.setSelectedHour(hour);
    this.setSelectedMinute(minute);
  }

  private onDragHour = (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Rectangle, dragX: number, dragY: number) => {
    const angle = this.getAngle(pointer);
    this.hour.rotation = angle;
    this.selectedHour = this.getSelectedHourByAngle();
  };

  private onDragMinute = (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Rectangle, dragX: number, dragY: number) => {
    const angle = this.getAngle(pointer);
    this.minute.rotation = angle;
    this.selectedMinute = this.getSelectedMinuteByAngle();
  };

  private onDragEnd = () => {
    this.callback.call(this);
  }

  private getAngle(pointer: Phaser.Input.Pointer): number {
    return Phaser.Math.Angle.Between(this.globalX, this.globalY, pointer.x, pointer.y) + Math.PI / 2;
  }

  private mapAngleToHour(angle: number): number {
    return this.mapAngleToRange(angle, NUMBER_OF_HOURS);
  }

  private mapAngleToMinute(angle: number): number {
    return this.mapAngleToRange(angle, NUMBER_OF_MINUTES);
  }

  private mapAngleToRange(angle: number, range: number): number {
    const normalizedAngle = (angle + FULL_ROTATION) % FULL_ROTATION;
    const number = (normalizedAngle / (FULL_ROTATION)) * range;

    return Math.round(number) % range;
  }

  public markAsDone() {
    const color = 0x21812D;
    this.hour.fillColor = color;
    this.minute.fillColor = color;
    this.circle.clear();
    this.circle.fillStyle(color, 1.0);
    this.circle.fillCircle(this.clockCenter.x! + 4, this.clockCenter.y!, 7);

    this.setDraggableState(false);
  }

  public closeModal() {
    this.scene.makeGameSceneInteractive();
    
    this.hour.off(Phaser.Input.Events.DRAG, this.onDragHour);
    this.hour.off(Phaser.Input.Events.DRAG_END, this.onDragEnd);
    
    this.minute.off(Phaser.Input.Events.DRAG, this.onDragMinute);
    this.minute.off(Phaser.Input.Events.DRAG_END, this.onDragEnd);

    this.setDraggableState(false);
    
    this.destroy();
  }

  private setDraggableState(isDraggable: boolean) {
    this.scene.input.setDraggable(this.hour, isDraggable);
    this.scene.input.setDraggable(this.minute, isDraggable);
  }
}
