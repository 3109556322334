import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import CloseButton from "../../ui/CloseButton";
import HintBar from "../../ui/HintBar";
import PreviewRoomObject from "../../RoomObject/PreviewRoomObject";
import EventBridge from "@/utils/EventBridge";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import GameScene from "@/phaser/scenes/GameScene";
import PopupBackground from "../../ui/PopupBackground";

export interface PuzzleStatePayload {}

export interface StringStatePayload extends PuzzleStatePayload {
  value: string;
}

export default abstract class PuzzleBase<
  T extends PuzzleStatePayload,
> extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;

  private readonly sourceObject: BaseRoomObject;
  private readonly callback: () => void;
  private readonly actionId: string;

  protected readonly hintBar: HintBar;
  protected readonly closeButton: CloseButton;
  protected readonly timerEvents: Phaser.Time.TimerEvent[] = [];
  protected incrementProgress: boolean = true;

  protected abstract removeEventHandlers(): void;
  protected abstract updatePuzzle(currentState: T): void;
  protected abstract isPuzzleStatePayloadValid(currentStatePayload): boolean;

  constructor(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    actionId: string,
    callback: () => void = () => {},
    hintMessage: string
  ) {
    super(scene, 0, 0);

    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;
    this.actionId = actionId;

    this.initializeBackground();

    this.hintBar = new HintBar(scene, hintMessage);
    this.add(this.hintBar);

    this.closeButton = new CloseButton(this.scene, () =>
      this.onCloseButtonClick()
    );
    this.add(this.closeButton);

    EventBridge.on("game.puzzleChanged", (currentStatePayload) =>
      this.updatePuzzleState(currentStatePayload)
    );
    EventBridge.on("ui.closeModal", () => this.onCloseButtonClick());
  }

  protected initializeBackground(): void {
    const popupBackground = new PopupBackground(this.scene);
    this.add(popupBackground);
  }

  protected updatePuzzleState(currentStatePayload): void {
    if (this.isPuzzleStatePayloadValid(currentStatePayload)) {
      this.updatePuzzle(currentStatePayload as T);
    }
  }

  protected emitPuzzleStateChanged(currentStatePayload: T) {
    if (this.scene.sourceScene instanceof GameScene) {
      const gameScene = this.scene.sourceScene as GameScene;

      if (gameScene.isNavigatorScene()) {
        EventBridge.emit("game.changePuzzleState", {
          puzzleId: "puzzleId",
          payload: currentStatePayload,
        });
      }
    } else {
      this.updatePuzzle(currentStatePayload);
    }
  }

  protected onCloseButtonClick() {
    this.closeModal();
  }

  protected closeModal(hideHintButton: boolean = true) {
    if (hideHintButton) {
      this.scene.hud.setHintButtonVisible(false);
    }

    this.resetEventHandlersAndScene();
    this.clearTimerEvents();
    this.destroy();
  }

  protected completePuzzle() {
    this.scene.hud.setHintButtonVisible(false);
    this.closeButton.setVisible(false);

    this.resetEventHandlersAndScene();

    const timerEvent = this.scene.time.delayedCall(2000, this.completeAction);
    this.timerEvents.push(timerEvent);
  }

  protected completeAction = (destroy: boolean = true) => {
    this.clearTimerEvents();

    if (this.scene.env === "preview") {
      const previewRoomObject = this.sourceObject as PreviewRoomObject;

      if (this.incrementProgress) {
        previewRoomObject.incrementProgress();
      }

      previewRoomObject.completeAction();
    } else {
      if (this.incrementProgress) {
        const gameScene = this.scene.sourceScene as GameScene;

        const payload = {
          sessionId: gameScene.session!.id,
          roomId: gameScene.session!.activeRoomId,
          roomObjectId: this.sourceObject._id,
          actionId: this.actionId,
        };

        EventBridge.emit("game.puzzleEnded", payload);
      }

      const gameRoomObject = this.sourceObject as GameRoomObject;
      const gameScene = this.scene.sourceScene as GameScene;

      gameRoomObject.completeAction(gameScene.user!.id!);
    }

    if (destroy) {
      this.destroy();
    }
  };

  private resetEventHandlersAndScene() {
    this.removeEventHandlers();

    EventBridge.remove("game.puzzleChanged");
    EventBridge.remove("ui.closeModal");

    this.scene.makeGameSceneInteractive();
  }

  private clearTimerEvents() {
    this.scene.time.removeEvent(this.timerEvents);
  }
}
